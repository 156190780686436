import { differenceInCalendarDays, format, parseJSON } from "date-fns";
import { useCallback, useEffect, useMemo } from "react";
import { LockIcon } from "@welcome-ui/icons";
import styled from "styled-components";
import { useCookie } from "react-use";

import { Link } from "../links";

import { useCurrentUser } from "@hire/hooks/useCurrentUser";
import { Button, Modal, Text } from "@otta/design";
import { CompanyJobSlotExperimentPhase } from "@hire/schema";
import { pushAnalyticsEvent } from "@otta/analytics";

const LockContainer = styled.div`
  width: 4rem;
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
`;

const CTAButton = styled(Button)`
  margin-top: 0.5rem;
  text-decoration: none;
`;

export function BasicDamocles(): React.ReactElement | null {
  const [user, { loading }] = useCurrentUser({
    variables: { autoEnrolJobSlots: true },
    fetchPolicy: "network-only",
  });
  const jobSlotData = user?.currentCompany?.jobSlotData;
  const [cookie, setCookie] = useCookie("_otta_basic_warning_dismissed");
  const activeFrom = jobSlotData?.activeFrom;

  const close = useCallback(() => {
    setCookie("true", { expires: 1 });
  }, [setCookie]);

  const [endDate, daysRemaining] = useMemo(() => {
    if (activeFrom) {
      const date = parseJSON(activeFrom);
      return [
        format(date, "MMMM do"),
        differenceInCalendarDays(date, new Date()),
      ];
    } else {
      return [null, null];
    }
  }, [activeFrom]);

  const shouldShowModal = !(
    !user?.currentCompany ||
    jobSlotData?.experimentPhase !== CompanyJobSlotExperimentPhase.Warning ||
    user.currentCompany.isPro ||
    daysRemaining === null ||
    daysRemaining < 1 ||
    jobSlotData?.paid ||
    !!cookie
  );

  useEffect(() => {
    if (!loading && shouldShowModal && daysRemaining && endDate) {
      pushAnalyticsEvent({
        eventName: "Company Recruiter Viewed Free Access Ending Modal",
        daysRemaining,
        endDate,
      });
    }
  }, [shouldShowModal, daysRemaining, endDate, loading]);

  if (loading) {
    // sorry but I need it for the tests
    return <span data-testid="loading" />;
  }

  if (!shouldShowModal) {
    return null;
  }

  return (
    <Modal
      open
      onOpenChange={open => {
        if (!open) {
          close();
        }
      }}
    >
      <Content data-testid="content">
        <LockContainer>
          <LockIcon />
        </LockContainer>
        <Text size={2} bold>
          Your free access ends in {daysRemaining} day
          {daysRemaining === 1 ? "" : "s"}
        </Text>
        <Text align="center">
          On {endDate}, your account will be
          <br /> removed from the Basic free plan.
        </Text>
        <CTAButton
          level="primary"
          as={Link}
          to="https://share.hsforms.com/1MWJGCmpGRC-V0tqOOhohhQ5gosb?utm_source=hire_app&utm_medium=pop_up&utm_campaign=upsell&utm_content=extend_access"
          newTab
          onClick={() => close()}
          data-analytics-id="free-access-ending-modal-extend-access"
        >
          Click here to extend access
        </CTAButton>
      </Content>
    </Modal>
  );
}
