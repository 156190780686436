import { useFormik } from "formik";
import { useCallback } from "react";
import styled, { css, down } from "@xstyled/styled-components";
import * as Yup from "yup";

import { palette, modularScale, pxToRem } from "@otta/design-tokens";
import {
  Button,
  Spacing,
  Text,
  Card as DefaultCard,
  Heading,
  List,
  Tipbox,
  ErrorText,
} from "@otta/design";
import { Textarea } from "@hire/components/input/Textarea";
import { CircularImg } from "@hire/pages/CompanySettings/Team/CircularImg";
import { pluraliser } from "@hire/util/strings";
import { ResponsiveCenteredContainer } from "@hire/components/containers/ResponsiveCenteredContainer";
import { mustNotContainUrl } from "@hire/validation/strings";

interface BroadcastFormProps {
  handleSave: (message: string) => void;
  profileImagePath: string | null;
  message?: string;
  numberOfCandidates?: number;
  companyName: string;
}

interface FormFields {
  message: string;
}
const Characters = styled(Text)<{ isHighlited: boolean }>`
  ${({ isHighlited }) =>
    isHighlited &&
    css`
      color: red-600;
    `}
`;

const StyledTipbox = styled(Tipbox)`
  ${down(
    "tablet",
    css`
      padding-right: 28;
    `
  )};
  background-color: white;
  border-radius: ${modularScale(1)};
  padding-top: 19;
  padding-bottom: 19;
  padding-left: xl;
  text-align: left;
`;

const ProfileImageWrapper = styled.div`
  margin-right: lg;
`;

const TipContainer = styled.div`
  display: flex;
  align-items: center;
  padding: lg;
  background-color: gray-50;
  border: ${pxToRem(1)} solid ${palette.grayscale.shade400};
  border-top: none;
  border-radius: 0 0 4 4;
`;

const StyledTextarea = styled(Textarea)`
  border-radius: 4 4 0 0;
  resize: vertical;
  border-bottom: none;
`;

const Card = styled(DefaultCard)`
  ${down(
    "tablet",
    css`
      padding-right: 28;
    `
  )};
  position: relative;
  overflow: hidden;
`;

const HeadingWrapper = styled.div`
  display: flex;
  gap: 10;
  align-items: center;
`;

const Container = styled(ResponsiveCenteredContainer)`
  max-width: ${pxToRem(1200)};
`;

const validationSchema = Yup.object({
  message: Yup.string()
    .min(100, "Enter at least 100 characters")
    .max(500, "Enter a max of 500 characters")
    .test(mustNotContainUrl)
    .required("Fill in this field")
    .label("Broadcast"),
});

export const BroadcastForm = ({
  message,
  handleSave,
  profileImagePath,
  numberOfCandidates,
  companyName,
}: BroadcastFormProps) => {
  const onSubmit = useCallback(
    (values: FormFields) => {
      return handleSave(values.message ?? "");
    },
    [handleSave]
  );

  const form = useFormik<FormFields>({
    initialValues: {
      message: message ?? "",
    },
    onSubmit,
    validationSchema,
  });

  return (
    <>
      <StyledTipbox level="information">
        <Spacing>
          <HeadingWrapper>
            <Heading size={1}>You&apos;re missing out on applications</Heading>
          </HeadingWrapper>
          <Text>
            {numberOfCandidates} candidates are available for broadcast. Let
            them know you&apos;re still hiring!
          </Text>
          <List type="bullet">
            <li>Candidates can&apos;t reply directly to your broadcast</li>
            <li>Instead, they&apos;ll submit an application for the role</li>
            <li> Enter up to 500 characters (no images or links)</li>
          </List>
        </Spacing>
      </StyledTipbox>
      <Container>
        <Card>
          <Spacing size={2}>
            <Text bold align="left">
              Broadcast to {numberOfCandidates}{" "}
              {pluraliser(numberOfCandidates ?? 0, "candidate")}
            </Text>
            <Text align="left">
              Subject:{" "}
              <strong>News from {companyName} about a job you saved</strong>
            </Text>
            <Text bold align="left">
              Your broadcast
            </Text>
            <form onSubmit={form.handleSubmit} data-testid="broadcast-form">
              <Spacing>
                <div>
                  <StyledTextarea
                    name="message"
                    id="message"
                    value={form.values.message}
                    data-testid="broadcast-message-textarea"
                    onChange={form.handleChange("message")}
                    placeholder="We're still hiring for this role! Here's some information about our mission, growth plans and company culture. Send in your application today!"
                    onBlur={form.handleBlur}
                  />
                  <TipContainer>
                    {profileImagePath && (
                      <ProfileImageWrapper>
                        <CircularImg
                          src={profileImagePath}
                          width="30px"
                          alt="Profile Image"
                        />
                      </ProfileImageWrapper>
                    )}
                    <Text size={-1}>
                      Your name and profile picture will appear with the message
                    </Text>
                  </TipContainer>
                  {form.touched.message && (
                    <ErrorText>{form.errors.message}</ErrorText>
                  )}
                </div>

                <Characters
                  align="right"
                  size={-1}
                  isHighlited={(form.values.message?.length ?? 0) > 500}
                >
                  Characters: {form.values.message?.length}/500
                </Characters>

                <Button type="submit" level="primary">
                  Preview your broadcast
                </Button>
              </Spacing>
            </form>
          </Spacing>
        </Card>
      </Container>
    </>
  );
};
