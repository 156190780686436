import styled from "styled-components";

import * as Banner from "@hire/components/Banner";
import { Text } from "@otta/design";
import { palette } from "@otta/design-tokens";

const Container = styled.div`
  padding: 10px;
  width: 100%;
  background-color: ${palette.brand.white};
`;

export function ProfileInReviewBanner() {
  return (
    <Container>
      <Banner.Info className="full-width-banner">
        <div>
          <Text bold>Your company profile is now in review</Text>
          <Text>
            We&apos;ll write your company profile before it&apos;s shown to
            candidates to help set you up for success. We&apos;ll email you
            within 1 working day when it&apos;s ready. Please note that we work
            a 4-day week, Monday to Thursday.
          </Text>
        </div>
      </Banner.Info>
    </Container>
  );
}
