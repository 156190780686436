import { Navigate, Outlet } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { CandidateAccount } from "./CandidateAccount";
import { NoCompany } from "./NoCompany";
import { RequiresEmailVerification } from "./RequiresVerification";
import { ArchivedCompany } from "./ArchivedCompany";
import { RequiresActivation } from "./RequiresActivation";
import { ProfileInReviewBanner } from "./ProfileInReviewBanner";

import { CompanyWorkflowStatus, CurrentUserDocument } from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { useRequiredParams } from "@hire/util/routing";
import { hireAppUser } from "@hire/util/user";
import { BasicDamocles } from "@hire/components/BasicDamocles";

type ActivatedRecruiterProps = {
  allowIncompleteCompanies?: boolean;
};

export function ActivatedRecruiter({
  allowIncompleteCompanies,
}: ActivatedRecruiterProps) {
  const { companySlug } = useRequiredParams(["companySlug"]);
  const { data, loading } = useQuery(CurrentUserDocument);

  const user = hireAppUser(data?.me);
  const company = user?.currentCompany;
  const paidJobSlots = company?.jobSlotData?.paid ?? 0;

  if (loading) {
    return <Loading />;
  }

  if (!data?.me) {
    return (
      <Navigate to={`/login/${encodeURIComponent(companySlug)}`} replace />
    );
  }

  if (
    data.me.__typename !== "CurrentCompanyRecruiter" &&
    data.me.__typename !== "CurrentAdmin"
  ) {
    return <CandidateAccount />;
  }

  if (!company) {
    return <NoCompany />;
  }

  const currentCompanySlug = company.urlSafeName.toLowerCase();

  if (currentCompanySlug !== companySlug) {
    return <Navigate to={`../${currentCompanySlug}`} replace />;
  }

  if (
    company.workflowStatus === CompanyWorkflowStatus.PreApproval &&
    paidJobSlots === 0
  ) {
    return <Navigate to={`../quiz`} replace />;
  }

  if (!user.verifiedEmail) {
    return <RequiresEmailVerification email={user.email} />;
  }

  if (company.workflowStatus === CompanyWorkflowStatus.Archived) {
    return <ArchivedCompany />;
  }

  if (
    !user.activated ||
    (!allowIncompleteCompanies &&
      company.workflowStatus !== CompanyWorkflowStatus.Completed &&
      company.workflowStatus !== CompanyWorkflowStatus.Changed &&
      paidJobSlots <= 0)
  ) {
    return <RequiresActivation companyName={company.name} />;
  }

  if (
    !allowIncompleteCompanies &&
    company.workflowStatus !== CompanyWorkflowStatus.Completed &&
    company.workflowStatus !== CompanyWorkflowStatus.Changed &&
    paidJobSlots > 0
  ) {
    return (
      <>
        <ProfileInReviewBanner />
        <Outlet />
      </>
    );
  }

  return (
    <>
      <BasicDamocles />
      <Outlet />
    </>
  );
}
