/* eslint-disable import/no-unused-modules */
import * as Yup from "yup";
import styled, { css, up } from "@xstyled/styled-components";

import { officeDaysSchema } from "../Range/schema";

import { allRemote } from "./NewSections/Location";

import {
  JobCategorizationDataQuery,
  LocationPreferenceInput,
  Location,
} from "@hire/schema";
import { alphabeticalSortByKey } from "@hire/util/collections";
import { modularScale } from "@otta/design-tokens";
import { List } from "@otta/design";

export const MarginWrapper = styled.div`
  margin-top: lg;
`;

export const StyledList = styled(List)`
  > li {
    line-height: 1.5;
    font-size: ${modularScale(-1)};
  }

  ${up(
    "tablet",
    css`
      > li {
        line-height: 1.3;
        font-size: ${modularScale()};
      }
    `
  )}
`;

export function formatAndSortOptions(data: { id: string; value: string }[]) {
  return data
    .slice()
    .map(option => ({
      label: option.value,
      value: option.id,
    }))
    .sort(alphabeticalSortByKey("label"));
}
export const formatOptions = (data: JobCategorizationDataQuery) => ({
  jobFunctionOptions: formatAndSortOptions(data.jobFunctions),
  timezoneOptions: data.timezones.map(tz => ({
    value: tz.id,
    label: tz.location,
  })),
  technologiesUsedOptions: data.technologiesUsed.map(t => ({
    label: t.value,
    value: t.id,
  })),
  languageRequirementOptions: data.languageRequirements
    .map(t => ({
      label: t.value,
      value: t.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label)),
});

// Will only show the technology section for:
// Engineering, Data, Design Functions, Other engineering
export const showTechnologiesUsedByJob = (
  functionId: string | number | undefined
): boolean => ["2", "6", "22", "45"].includes(functionId as string);

export const compileLocationPreferences = (
  locationPreferences: Location[]
): LocationPreferenceInput[] => {
  return locationPreferences.map(location => ({
    location,
  }));
};

export const mapSelectionToMutationInput = ({
  value,
  label,
}: {
  label: string;
  value: string;
}): { id: string; value: string } => ({
  value: label,
  id: value,
});

export function mapQueryResultToSelectStructure<Field extends string>(
  displayFieldName: Field
) {
  return (
    data: { id: string } & { [key in Field]: string }
  ): { label: string; value: string } => ({
    label: data[displayFieldName],
    value: data.id,
  });
}

export const validationSchema = Yup.object().shape({
  title: Yup.string().required("Enter a job title"),
  subtitle: Yup.string(),
  internship: Yup.boolean().required(),
  experienceRange: Yup.array()
    .of(Yup.number().required())
    .label("Experience range")
    .required()
    .test("length", val => val && val.length === 2),
  hideSalary: Yup.boolean().nullable(),
  salaryVisibleToIds: Yup.array().of(Yup.string().required()).nullable(),
  otherCompensation: Yup.string().nullable(),
  jobFunction: Yup.string().label("Job function").required("Select a function"),
  jobSubFunction: Yup.string()
    .label("Job sub function")
    .when("jobFunction", (jf, schema) => {
      return jf ? schema.required("Select a sub-function") : schema.nullable();
    }),
  technologiesUsed: Yup.array()
    .of(
      Yup.object()
        .shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
        .label("Technologies used")
    )
    .nullable(),
  requirements: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().nullable(),
        order: Yup.number().required(),
        desirable: Yup.boolean().nullable(),
      })
    )
    .label("Requirements")
    .test({
      message: "The job should have at least one must-have requirement",
      test: value => value?.some(r => r.value && !r.desirable) ?? false,
    })
    .test({
      message: "The same requirement has been entered more than once",
      test: (value, context) => {
        const requirementsValue = (value || [])?.map(({ value }) => value);
        const desirableRequirements:
          | {
              desirable?: boolean | null;
              value?: string | null;
              order: number;
            }[]
          | undefined = context.parent.desirableRequirements;
        const desirableRequirementsValue = (desirableRequirements || [])?.map(
          ({ value }) => value
        );

        const allRequirements = [
          ...requirementsValue,
          ...desirableRequirementsValue,
        ];

        return (
          allRequirements.filter(
            (item, index) => index !== allRequirements.indexOf(item)
          ).length === 0
        );
      },
    }),
  locationPreferences: Yup.array()
    .of(Yup.string())
    .label("Location preferences")
    .test({
      message: "Select where this job is based",
      test: context => Boolean(context && context.length > 0),
    }),
  involvesBullets: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().nullable(),
        order: Yup.number().required(),
        desirable: Yup.boolean().nullable(),
      })
    )
    .test({
      message: "Add the responsibilities",
      test: bs => !!bs?.some(b => !!b.value),
    })
    .label("Job involves"),
  applicationProcessBullets: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().nullable(),
        order: Yup.number().required(),
        desirable: Yup.boolean().nullable(),
      })
    )
    .label("Application process")
    .optional(),
  requiredOfficeDaysRange: Yup.object().when("locationPreferences", {
    is: (prefs: string[]) => !allRemote(prefs),
    then: () => officeDaysSchema({ required: true }),
    otherwise: () => officeDaysSchema({ required: false }),
  }),
  officeLocation: Yup.string().label("Office location").nullable(),
  locationRestrictions: Yup.string().label("Location restrictions").nullable(),
  locationInformation: Yup.string().label("Location information").nullable(),
  salaryRange: Yup.object().shape({
    minAmount: Yup.number()
      .typeError("Enter a valid minimum salary")
      .required("Enter a minimum salary")
      .min(15_000, "Enter a minimum salary greater than 15,000"),
    maxAmount: Yup.number()
      .typeError("Enter a valid maximum salary")
      .required("Enter a maximum salary")
      .min(15_000, "Enter a maximum salary greater than 15,000")
      .max(999_999, "Enter a salary lower than 1m")
      .min(
        Yup.ref("minAmount"),
        "Maximum must be either the same, or greater than minimum"
      ),
  }),
  oteRange: Yup.lazy(value =>
    value
      ? Yup.object().shape(
          {
            minAmount: Yup.number()
              .typeError("Enter a valid OTE minimum")
              .when("maxAmount", (maxAmount, schema) => {
                return maxAmount
                  ? schema.required("Enter a valid OTE minimum")
                  : schema.nullable();
              })
              .test({
                message:
                  "OTE minimum must be greater than the minimum base salary value",
                test: (value, parent) => {
                  return Boolean(
                    value &&
                      parent.from &&
                      value !== null &&
                      value > parent.from[1].value.salaryRange.minAmount
                  );
                },
              }),
            maxAmount: Yup.number()
              .typeError("Enter a valid OTE maximum")
              .when("minAmount", (minAmount, schema) => {
                return minAmount
                  ? schema.required("Enter a valid OTE maximum")
                  : schema.nullable();
              })
              .test({
                message:
                  "OTE maximum must be greater than the maximum base salary value",
                test: (value, parent) =>
                  Boolean(
                    value &&
                      parent.from &&
                      value !== null &&
                      value > parent.from[1].value.salaryRange.maxAmount
                  ),
              })
              .test({
                message: "OTE maximum must be greater than the OTE minimum",
                test: (value, parent) =>
                  Boolean(
                    value &&
                      value !== null &&
                      parent.from &&
                      parent.from[1].value.oteRange.minAmount &&
                      value > parent.from[1].value.oteRange.minAmount
                  ),
              }),
          },
          [["minAmount", "maxAmount"]]
        )
      : Yup.mixed()
  ),
});
