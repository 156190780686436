import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";

import { CandidateConversation } from "./pages/JobPage/Pipeline/Candidate/CandidateConversation";
import { CandidateProfile } from "./pages/JobPage/Pipeline/Candidate/CandidateProfile";
import { ImportedCandidateProfile } from "./pages/JobPage/Pipeline/Candidate/ImportedCandidateProfile";
import { HasAccess, HasJobAccess } from "./HasAccess";
import { Ottacue } from "./components/Ottacue";
import { Toast } from "./components/Toast";
import { MainLayout } from "./components/layout";
import { ActivatedRecruiter } from "./components/layout/ActivatedRecruiter";
import { CompanyDetails } from "./pages/CompanyProfile/CompanyDetails";
import { ATSOAuthCallback } from "./pages/ATSOAuthCallback";
import { Auth } from "./pages/Auth";
import { Login } from "./pages/Auth/Login";
import { Logout } from "./pages/Auth/Logout";
import { SelectCompany } from "./pages/Auth/SelectCompany";
import { Signup } from "./pages/Auth/Signup";
import { CompanyProfile } from "./pages/CompanyProfile";
import { CompanyBenefits } from "./pages/CompanyProfile/CompanyBenefits";
import { DiversityInclusion } from "./pages/CompanyProfile/DiversityInclusion";
import { Endorsements } from "./pages/CompanyProfile/Endorsements";
import { ExternalLinks } from "./pages/CompanyProfile/ExternalLinks";
import { InvestorsAndFunding } from "./pages/CompanyProfile/InvestorsAndFunding";
import { People } from "./pages/CompanyProfile/People";
import { VisaSponsorship } from "./pages/CompanyProfile/VisaSponsorship";
import { CompanySettings } from "./pages/CompanySettings";
import { ATSIntegration } from "./pages/CompanySettings/Integration";
import { SSO } from "./pages/CompanySettings/SSO";
import { Team } from "./pages/CompanySettings/Team";
import { Templates } from "./pages/CompanySettings/Templates";
import { AllTemplates } from "./pages/CompanySettings/Templates/AllTemplates";
import { CreateJob } from "./pages/CreateJob";
import { Dashboard } from "./pages/Dashboard";
import { DeleteMyAccount } from "./pages/DeleteMyAccount";
import { Analytics } from "./pages/JobPage/Analytics";
import { Broadcast } from "./pages/JobPage/Broadcast";
import { Confirmation } from "./pages/Checkout/Confirmation";
import { JobSlotConfirmation } from "./pages/Checkout/JobSlotConfirmation";
import { EditJob } from "./pages/JobPage/EditJob";
import { Job } from "./pages/JobPage/Job";
import { Pipeline } from "./pages/JobPage/Pipeline";
import { AddNewCandidate } from "./pages/JobPage/Pipeline/AddNewCandidate";
import { AddNewStage } from "./pages/JobPage/Pipeline/AddNewStage";
import { PipelineCandidate } from "./pages/JobPage/Pipeline/Candidate";
import { CandidateNotes } from "./pages/JobPage/Pipeline/Candidate/CandidateNotes";
import { SideDrawer } from "./pages/JobPage/Pipeline/components/SideDrawer";
import { Sourcing } from "./pages/JobPage/Sourcing";
import { SearchCandidates } from "./pages/JobPage/Sourcing/SearchCandidates";
import { SeenCandidates } from "./pages/JobPage/Sourcing/SeenCandidates";
import { ShortlistedCandidates } from "./pages/JobPage/Sourcing/ShortlistedCandidates";
import { MessageCandidate } from "./pages/JobPage/Sourcing/components/MessageCandidate";
import { SourcingPreferences } from "./pages/JobPage/Sourcing/components/SourcingPreferences";
import { JobsList } from "./pages/JobsList";
import { UserProfile } from "./pages/UserProfile";
import { Feature, JobFeature, MessageTemplateTypeEnum } from "./schema";
import { JobProvider } from "./pages/JobPage/JobProvider";
import { InboxCandidate } from "./pages/JobPage/Inbox/InboxCandidate";
import { Inbox } from "./pages/JobPage/Inbox";
import { ForgotPassword } from "./pages/Auth/ForgotPassword";
import { Onboarding } from "./pages/Auth/Onboarding";
import { MessagingRedirect, SafeRedirect } from "./Redirects";
import { HybridWorkingModal } from "./components/HybridWorkingModal";
import { VerifyEmail } from "./pages/VerifyEmail";
import { TemplateIndex } from "./pages/CompanySettings/Templates/Tabs";
import { JobIndex } from "./pages/JobPage/JobIndex";
import { JobPerformanceResults } from "./pages/Quiz/JobPerformance/Results";
import { analyticsClickListener } from "./util/analytics/analyticsClickListener";
import {
  QuizProvider,
  QuizLayout,
  NavQuizLayout,
} from "./components/layout/QuizLayout";
import { AreYouHiringInAnyOfTheseLocations } from "./pages/Quiz/AreYouHiringInAnyOfTheseLocations";
import { AreYouHiringInTheseCategories } from "./pages/Quiz/AreYouHiringInTheseCategories";
import { Unsupported } from "./pages/Quiz/Unsupported";
import { CoreOrPro } from "./pages/Quiz/CoreOrPro";
import { PricingDetails } from "./pages/Quiz/PricingDetails";
import * as QuizSignup from "./pages/Quiz/Signup";
import { Success } from "./pages/Quiz/Success";
import {
  Pricing,
  RecentlyCancelled,
  UnfreezeJob,
  UnfreezeYourRole,
  WhyBuyOtta,
} from "./pages/JobsList/JobSlots/Content";
import { AddAJob } from "./pages/JobsList/JobSlots/Content/AddAJob";
import { AddAJobPricing } from "./pages/JobsList/JobSlots/Content/AddAJobPricing";
import { AddJobAddSlot } from "./pages/JobsList/JobSlots/Content/AddJobAddSlot";
import * as QuizCompanyDetails from "./pages/Quiz/CompanyDetails";
import { ChooseJobSlots } from "./pages/JobsList/JobSlots/Content/ChooseJobSlots";
import { BookACall } from "./pages/BookACall";
import { JobPerformance } from "./pages/Quiz/JobPerformance";
import { Billing } from "./pages/Billing";
import { CandidateAccount } from "./components/layout/ActivatedRecruiter/CandidateAccount";
import { Callback } from "./pages/Callbacks";
import { FakeProfile } from "./pages/CompanySettings/FakeProfile";
import { useUserAnalytics } from "./hooks/useUserAnalytics";
import { SourcingUpsell } from "./pages/SourcingUpsell";

import { RebrandingModal } from "@otta/shared-components";

export default function App() {
  useEffect(() => {
    document.addEventListener("click", analyticsClickListener, {
      passive: true,
      capture: true,
    });
    return () => {
      document.removeEventListener("click", analyticsClickListener, {
        capture: true,
      });
    };
  }, []);

  useUserAnalytics();

  const pathName = useLocation().pathname;
  const inSpecialUrl =
    pathName &&
    (pathName.includes("/quiz") ||
      pathName.includes("/book-a-call") ||
      pathName.includes("/book-a-demo"));

  return (
    <>
      <Routes>
        <Route path="callback/*" element={<Callback />} />
        <Route path="logout" element={<Logout />} />
        <Route path="verify-email" element={<VerifyEmail />} />
        <Route
          path="book-a-call"
          element={
            <BookACall
              portalId="9176699"
              formId="b6677612-7fd3-4bf4-bee1-b2d50adb1c4e"
              chiliPiperRouterName="book-a-call"
            />
          }
        />
        <Route
          path="book-a-demo"
          element={
            <BookACall
              portalId="9176699"
              formId="24cf30d0-4c38-433f-9af7-1aee8128d71e"
              chiliPiperRouterName="request-a-demo"
            />
          }
        />
        <Route path="quiz" element={<QuizProvider />}>
          <Route element={<NavQuizLayout />}>
            <Route path="" element={<CoreOrPro />} />
          </Route>
          <Route element={<QuizLayout />}>
            <Route
              path="locations"
              element={<AreYouHiringInAnyOfTheseLocations />}
            />
            <Route
              path="categories"
              element={<AreYouHiringInTheseCategories />}
            />
            <Route
              path="company"
              element={<QuizCompanyDetails.CompanyDetails />}
            />
            <Route path="unsupported" element={<Unsupported />} />
            <Route path="signup" element={<QuizSignup.Signup />} />
          </Route>
          <Route path="pricing-details" element={<PricingDetails />} />
          <Route path="success" element={<Success />} />
          <Route path="job-performance">
            <Route index element={<JobPerformance />} />
            <Route path="results" element={<JobPerformanceResults />} />
          </Route>
        </Route>
        <Route path="/" element={<Auth />}>
          <Route index element={<SelectCompany />} />
          <Route path="login/:companySlug" element={<Login />} />
          <Route path="signup/:companySlug" element={<Signup />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="onboarding" element={<Onboarding />} />
          <Route path="candidate-account" element={<CandidateAccount />} />
        </Route>
        <Route path="oauth/:client/callback" element={<ATSOAuthCallback />} />

        <Route element={<MainLayout />}>
          <Route path="user" element={<UserProfile />} />
          <Route path="delete-my-account" element={<DeleteMyAccount />} />
          <Route
            path=":companySlug/checkout"
            element={
              <HasAccess feature={Feature.JobSlotCheckout}>
                <ActivatedRecruiter allowIncompleteCompanies />
              </HasAccess>
            }
          >
            <Route path="confirmation" element={<Confirmation />} />
            <Route
              path="job-slot-confirmation/:externalId"
              element={<JobSlotConfirmation />}
            />
          </Route>
          <Route path=":companySlug" element={<ActivatedRecruiter />}>
            <Route
              index
              element={
                <HasAccess feature={Feature.Home} redirect="jobs">
                  <Dashboard />
                </HasAccess>
              }
            />
            <Route
              path="sourcing"
              element={
                <HasAccess feature={Feature.SourcingUpsell}>
                  <SourcingUpsell />
                </HasAccess>
              }
            />
            <Route
              path="jobs"
              element={
                <HasAccess feature={Feature.ViewJobs}>
                  <Outlet />
                </HasAccess>
              }
            >
              <Route path="" element={<JobsList />}>
                <Route path="choose-job-slots" element={<ChooseJobSlots />} />
                <Route
                  path="unfreeze-job/:jobExternalId"
                  element={
                    <HasAccess feature={Feature.JobSlotCheckout}>
                      <UnfreezeJob />
                    </HasAccess>
                  }
                />
                <Route path="pricing/:jobExternalId" element={<Pricing />} />
                <Route path="why-otta" element={<WhyBuyOtta />} />
                <Route
                  path="unfreeze-your-role/:jobExternalId"
                  element={
                    <HasAccess feature={Feature.JobSlotCheckout}>
                      <UnfreezeYourRole />
                    </HasAccess>
                  }
                />
                <Route
                  path="recently-cancelled"
                  element={<RecentlyCancelled />}
                />
                <Route path="add-a-job" element={<AddAJob />} />
                <Route path="add-a-job-pricing" element={<AddAJobPricing />} />
                <Route path="add-job-add-slot" element={<AddJobAddSlot />} />
              </Route>

              <Route
                path="create"
                element={
                  <HasAccess feature={Feature.CreateJob}>
                    <CreateJob />
                  </HasAccess>
                }
              />
              <Route
                path=":jobId"
                element={
                  <JobProvider>
                    <Job />
                  </JobProvider>
                }
              >
                <Route index element={<JobIndex />} />
                <Route
                  path="analytics"
                  element={
                    <HasJobAccess
                      feature={JobFeature.Analytics}
                      noAccessRedirect=".."
                    >
                      <Analytics />
                    </HasJobAccess>
                  }
                />
                <Route
                  path="pipeline"
                  element={
                    <HasJobAccess feature={JobFeature.Pipeline}>
                      <Pipeline />
                    </HasJobAccess>
                  }
                >
                  <Route element={<SideDrawer size="large" />}>
                    <Route
                      path="candidate/:candidateId/profile/imported"
                      element={<ImportedCandidateProfile />}
                    />
                    <Route
                      path="candidate/:candidateId"
                      element={<PipelineCandidate />}
                    >
                      <Route
                        index
                        element={<Navigate to="profile" replace />}
                      />
                      <Route path="profile" element={<CandidateProfile />} />
                      <Route
                        path="conversation"
                        element={<CandidateConversation inPipeline />}
                      />
                      <Route path="notes" element={<CandidateNotes />} />
                    </Route>
                  </Route>
                  <Route element={<SideDrawer size="small" />}>
                    <Route path="add-new-stage" element={<AddNewStage />} />
                    <Route
                      path="add-new-candidate"
                      element={<AddNewCandidate />}
                    />
                  </Route>
                </Route>
                <Route
                  path="inbox"
                  element={
                    <HasJobAccess feature={JobFeature.Inbox}>
                      <Inbox />
                    </HasJobAccess>
                  }
                >
                  <Route
                    path="candidates/:candidateId"
                    element={<InboxCandidate />}
                  >
                    <Route path="profile" element={<CandidateProfile />} />
                    <Route
                      path="conversation"
                      element={<CandidateConversation />}
                    />
                    <Route
                      path="notes"
                      element={
                        <HasJobAccess feature={JobFeature.Pipeline}>
                          <CandidateNotes />
                        </HasJobAccess>
                      }
                    />
                  </Route>
                </Route>
                <Route
                  path="sourcing"
                  element={
                    <HasJobAccess
                      feature={JobFeature.Sourcing}
                      noAccessRedirect=".."
                    >
                      <Sourcing />
                    </HasJobAccess>
                  }
                >
                  <Route index element={<Navigate to="search" replace />} />
                  <Route path="search" element={<SearchCandidates />}>
                    <Route index element={<SourcingPreferences />} />
                    <Route
                      path=":candidateId/message"
                      element={<MessageCandidate />}
                    />
                  </Route>
                  <Route path="shortlist" element={<ShortlistedCandidates />}>
                    <Route
                      path=":candidateId/message"
                      element={<MessageCandidate />}
                    />
                  </Route>
                  <Route path="seen" element={<SeenCandidates />}>
                    <Route
                      path=":candidateId/message"
                      element={<MessageCandidate />}
                    />
                  </Route>
                </Route>
                <Route
                  path="edit"
                  element={
                    <HasJobAccess feature={JobFeature.Edit}>
                      <EditJob />
                    </HasJobAccess>
                  }
                />
                <Route
                  path="broadcast"
                  element={
                    <HasJobAccess feature={JobFeature.Broadcast}>
                      <Broadcast />
                    </HasJobAccess>
                  }
                />
              </Route>
            </Route>
            <Route
              path="company-profile"
              element={
                <HasAccess feature={Feature.CompanyProfile}>
                  <CompanyProfile />
                </HasAccess>
              }
            >
              <Route index element={<Navigate to="details" replace />} />
              <Route path="benefits" element={<CompanyBenefits />} />
              <Route path="diversity" element={<DiversityInclusion />} />
              <Route path="details" element={<CompanyDetails />} />
              <Route path="endorsements" element={<Endorsements />} />
              <Route path="links" element={<ExternalLinks />} />
              <Route path="funding" element={<InvestorsAndFunding />} />
              <Route path="people" element={<People />} />
              <Route path="visa-sponsorship" element={<VisaSponsorship />} />
            </Route>
            <Route
              path="settings"
              element={
                <HasAccess feature={Feature.Settings}>
                  <CompanySettings />
                </HasAccess>
              }
            >
              <Route index element={<Navigate to="billing" replace />} />
              <Route path="report-fake-profile" element={<FakeProfile />} />
              <Route path="ats" element={<ATSIntegration />} />
              <Route path="billing" element={<Billing />} />
              <Route
                path="sso"
                element={
                  <HasAccess feature={Feature.SsoSettings}>
                    <SSO />
                  </HasAccess>
                }
              />
              <Route
                path="team"
                element={
                  <HasAccess feature={Feature.TeamMembers}>
                    <Team />
                  </HasAccess>
                }
              />

              <Route
                path="templates"
                element={
                  <HasAccess feature={Feature.ViewMessageTemplates}>
                    <Templates />
                  </HasAccess>
                }
              >
                <Route index element={<TemplateIndex />} />
                <Route
                  path="sourcing"
                  element={
                    <HasAccess
                      feature={Feature.ViewSourcingTemplates}
                      redirect=".."
                    >
                      <AllTemplates
                        messageTemplateType={MessageTemplateTypeEnum.Sourcing}
                      />
                    </HasAccess>
                  }
                />
                <Route
                  path="application"
                  element={
                    <HasAccess
                      feature={Feature.ViewRejectionTemplates}
                      redirect=".."
                    >
                      <AllTemplates
                        messageTemplateType={MessageTemplateTypeEnum.Rejection}
                      />
                    </HasAccess>
                  }
                />
              </Route>
            </Route>
          </Route>
        </Route>
        {/* hire app 1 redirects */}
        <Route path="jobs" element={<SafeRedirect path="jobs" />} />

        <Route
          path="jobs/:externalId/pipeline"
          element={
            <SafeRedirect
              path={({ externalId }) => `jobs/${externalId}/pipeline`}
            />
          }
        />
        <Route
          path="jobs/:externalId/pipeline/candidate/:candidateId/conversation"
          element={
            <SafeRedirect
              path={({ externalId, candidateId }) =>
                `jobs/${externalId}/pipeline/candidate/${candidateId}/conversation`
              }
            />
          }
        />
        <Route
          path="jobs/:externalId/pipeline/candidate/:candidateId/profile"
          element={
            <SafeRedirect
              path={({ externalId, candidateId }) =>
                `jobs/${externalId}/pipeline/candidate/${candidateId}/profile`
              }
            />
          }
        />
        <Route
          path="search/:externalId"
          element={
            <SafeRedirect
              path={({ externalId }) => `jobs/${externalId}/sourcing/search`}
            />
          }
        />
        <Route
          path="search/:externalId/:candidateId/message"
          element={
            <SafeRedirect
              path={({ externalId, candidateId }) =>
                `jobs/${externalId}/sourcing/search/${candidateId}/message`
              }
            />
          }
        />
        <Route
          path="profile"
          element={<SafeRedirect path="company-profile" />}
        />
        <Route
          path="messaging/:conversationId"
          element={<MessagingRedirect />}
        />
        <Route path="search" element={<SafeRedirect path="jobs" />} />
        <Route
          path="year-on-otta/*"
          element={
            <SafeRedirect path={({ "*": splat }) => `year-on-otta/${splat}`} />
          }
        />
        <Route
          path="jobs/*"
          element={<SafeRedirect path={({ "*": splat }) => `jobs/${splat}`} />}
        />
        <Route
          path="company-profile/*"
          element={
            <SafeRedirect
              path={({ "*": splat }) => `company-profile/${splat}`}
            />
          }
        />
        <Route
          path="settings/*"
          element={
            <SafeRedirect path={({ "*": splat }) => `settings/${splat}`} />
          }
        />
        <Route path="*" element={<SafeRedirect />} />
      </Routes>
      <Toast />
      <Ottacue />
      <HybridWorkingModal />
      {!inSpecialUrl && (
        <RebrandingModal key={"wave2"} userType="Company Recruiter" />
      )}
    </>
  );
}
