import styled, { css, down } from "@xstyled/styled-components";
import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { Navigate } from "react-router-dom";
import { PaperplaneIcon, PencilIcon, CelebrateIcon } from "@welcome-ui/icons";

import Card from "./Card";

import { Button, Heading, Spacing, Text, responsive } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";
import { LogoHeader, useProgress } from "@hire/components/layout/QuizLayout";
import { Link } from "@hire/components/links";
import { CurrentUserDocument } from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { Icon } from "@otta/icons";
import { useExperiment } from "@otta/experiments/client";
import { Experiment } from "@hire/constants/experiments";

const PageWrapper = styled.div`
  display: flex;
  padding: 80 xxl;
  flex-direction: column;
  align-items: center;
  gap: 80;

  ${down(
    "tablet",
    css`
      padding: 40 lg;
      gap: 40;
    `
  )}
`;

const Main = styled.main`
  background-color: beige-200;
  min-height: 100vh;
`;

const CardContainer = styled.div`
  display: flex;
  max-width: ${pxToRem(1376)};
  justify-content: center;
  align-items: center;
  gap: 40;

  ${down(
    "tablet",
    css`
      flex-direction: column;
      gap: lg;
    `
  )}
`;

const StatusTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: lg;
  align-self: stretch;
`;

const CardTitle = styled(Heading)`
  ${responsive`font-size: ${responsive.modularScale({
    mobile: 1,
    desktop: 3,
  })};`}
`;

const CheckIcon = styled(Icon)`
  display: flex;
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};
  padding: sm 7 sm 8;
  justify-content: center;
  align-items: center;
  border-radius: ${pxToRem(32)};
  background-color: white;
`;

const TodoIcon = styled.div`
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};
  border-radius: ${pxToRem(32)};
  border: ${pxToRem(4)} solid ${palette.brand.green};
`;

export function Success() {
  useProgress(0);
  const { data, loading } = useQuery(CurrentUserDocument);

  const hasVerifiedEmail = useMemo(
    () =>
      data?.me?.__typename === "CurrentCompanyRecruiter" &&
      data?.me?.verifiedEmail,
    [data?.me]
  );

  const { variant } = useExperiment(Experiment.NewStarterJourney);
  const newStarterJourneyActive = variant === "variant";

  if (loading) {
    return <Loading />;
  }

  if (
    !data?.me ||
    data?.me?.__typename !== "CurrentCompanyRecruiter" ||
    !data.me.currentCompany
  ) {
    return <Navigate to="/quiz" />;
  }

  return (
    <Main>
      <LogoHeader />
      <PageWrapper>
        {newStarterJourneyActive ? (
          <Heading size={5}>You&apos;re all set to get started!</Heading>
        ) : (
          <Heading size={5}>
            You've successfully subscribed to Welcome to the Jungle!
          </Heading>
        )}

        {hasVerifiedEmail ? (
          <Spacing>
            <CardContainer>
              <Card data-completed={true}>
                <StatusTextContainer>
                  <CheckIcon icon="checkmark" size={2} />
                  <CardTitle>Email verified</CardTitle>
                  <Text>You're now able to sign in and add your roles.</Text>
                </StatusTextContainer>
                <PaperplaneIcon w={{ _: 60, tablet: 80 }} />
              </Card>
              {!newStarterJourneyActive && (
                <Card data-completed={false}>
                  <StatusTextContainer>
                    <TodoIcon />
                    <CardTitle>Review your company profile</CardTitle>
                    <Text>
                      We'll write this and email you within 1 working day when
                      it's ready. This is shown with every job.
                    </Text>
                  </StatusTextContainer>
                  <PencilIcon w={{ _: 60, tablet: 80 }} />
                </Card>
              )}
              <Card data-completed={false}>
                <StatusTextContainer>
                  <TodoIcon />
                  <CardTitle>Add your roles</CardTitle>
                  <Text>
                    We'll review your roles to ensure you're matched with the
                    right candidates. Your company profile is required before
                    your roles can be published.
                  </Text>
                </StatusTextContainer>
                <CelebrateIcon w={{ _: 60, tablet: 80 }} />
              </Card>
            </CardContainer>
            <Button
              level="primary"
              style={{ textDecoration: "none" }}
              as={Link}
              to={`/${data.me.currentCompany.urlSafeName}/jobs/create`}
            >
              Sign in and add your roles
            </Button>
          </Spacing>
        ) : (
          <CardContainer>
            <Card data-completed={false}>
              <StatusTextContainer>
                <TodoIcon />
                <CardTitle>Verify your email</CardTitle>
                <Text>
                  Click the link in the email we sent you to be able to sign in.
                </Text>
              </StatusTextContainer>
              <PaperplaneIcon w={{ _: 60, tablet: 80 }} />
            </Card>
            {!newStarterJourneyActive && (
              <Card data-completed={false}>
                <StatusTextContainer>
                  <TodoIcon />
                  <CardTitle>Review your company profile</CardTitle>
                  <Text>
                    We'll write this and email you within 1 working day when
                    it's ready. This is shown with every job.
                  </Text>
                </StatusTextContainer>
                <PencilIcon w={{ _: 60, tablet: 80 }} />
              </Card>
            )}
            <Card data-completed={false}>
              <StatusTextContainer>
                <TodoIcon />
                <CardTitle>Add your roles</CardTitle>
                <Text>
                  {newStarterJourneyActive
                    ? "Provide role details to start attracting the right candidates. Roles will be published once completed."
                    : "We'll review your roles to ensure you're matched with the right candidates. Your company profile is required before your roles can be published."}
                </Text>
              </StatusTextContainer>
              <CelebrateIcon w={{ _: 60, tablet: 80 }} />
            </Card>
          </CardContainer>
        )}
      </PageWrapper>
    </Main>
  );
}
