import styled from "@xstyled/styled-components";

import { Overline } from "@otta/design";

const PersonContainer = styled.div`
  display: flex;
  align-items: center;
  margin: md 0;
`;

const HeadshotContainer = styled.div`
  margin-right: md;
`;

const HeadshotWrapper = styled.div`
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
`;

const CircularImgWrapper = styled.div<ICircularImgWrapperProps>`
  width: ${({ width }) => (width ? width : "100%")};
  height: ${({ width }) => (width ? width : "100%")};
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
`;

const CircularImgInner = styled.img`
  display: inline;
  margin: 0 auto;
  width: 100%;
  object-fit: cover;
`;

export const authors = [
  {
    name: "Steph",
    title: "Company Specialist",
    image: "https://static.otta.com/images/headshots/Steph.webp",
  },
  {
    name: "Freddie",
    title: "Company Specialist",
    image: "https://static.otta.com/images/headshots/Freddie.webp",
  },
  {
    name: "Kirsty",
    title: "Company Specialist",
    image: "https://static.otta.com/images/headshots/Kirsty.webp",
  },
] as const;

const StyleOverline = styled(Overline)`
  color: black;
`;

export function Author({
  name,
}: {
  name: (typeof authors)[number]["name"];
}): React.ReactElement {
  const person = authors.find(person => person.name === name) ?? authors[0];

  return (
    <PersonContainer>
      {person.image && (
        <HeadshotContainer>
          <Headshot image={person.image} alt={`${person.name} headshot`} />
        </HeadshotContainer>
      )}
      <div>
        <StyleOverline align="left" size={-1} bold>
          {person.name}
        </StyleOverline>
        <StyleOverline align="left" size={-1}>
          {person.title}
        </StyleOverline>
      </div>
    </PersonContainer>
  );
}

function Headshot({
  image,
  alt,
  ...props
}: {
  image: string;
  alt: string;
}): React.ReactElement {
  return (
    <HeadshotWrapper {...props}>
      <CircularImg src={image} alt={alt} />
    </HeadshotWrapper>
  );
}

interface ICircularImgWrapperProps {
  width?: string;
}

interface ICircularImgProps {
  src: string;
  alt: string;
  width?: string;
}

function CircularImg({
  src,
  width,
  alt,
}: ICircularImgProps): React.ReactElement {
  return (
    <CircularImgWrapper width={width}>
      <CircularImgInner src={src} alt={alt} loading="lazy" />
    </CircularImgWrapper>
  );
}
