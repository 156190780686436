import styled from "@xstyled/styled-components";

import { DonutChart } from "./DonutChart";
import { MissingDataPlaceholder } from "./MissingDataPlaceholder";

import { palette, pxToRem } from "@otta/design-tokens";
import { Text } from "@otta/design";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: xl;
  padding: lg;
  border: 1px solid ${palette.grayscale.shade200};
  border-radius: 4;

  &[aria-disabled="true"] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ChartWrapper = styled.div`
  align-self: center;
`;

const Legend = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: sm;
`;

const LegendItem = styled.li<{ color: string }>`
  display: flex;
  align-items: center;
  gap: 3;

  &::before {
    content: "";
    width: ${pxToRem(12)};
    height: ${pxToRem(12)};
    border-radius: 50%;
    background-color: ${props => props.color};
  }
`;

const NoDataPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  gap: lg;
`;

type Item = {
  color: string;
  value: number;
  percent: string;
  name: string;
};

export const MessageOutcome = ({
  data = [],
  hasSourcing,
}: {
  data: Item[];
  hasSourcing: boolean;
}) => {
  const hasData = data.reduce((acc, item) => acc + item.value, 0) > 0;

  if (!hasData) {
    return (
      <NoDataPlaceholder>
        <Text bold>Message outcome</Text>
        <MissingDataPlaceholder size="small" />
      </NoDataPlaceholder>
    );
  }

  return (
    <Wrapper aria-disabled={!hasSourcing}>
      <Text bold>Message outcome</Text>
      <ChartWrapper>
        <DonutChart data={data} />
      </ChartWrapper>

      <Legend>
        {data.map(item => (
          <LegendItem key={item.name} color={item.color}>
            {hasSourcing ? (
              <Text size={-1}>
                {item.name}: {item.value} ({item.percent}%)
              </Text>
            ) : (
              <Text size={-1}>{item.name}</Text>
            )}
          </LegendItem>
        ))}
      </Legend>
    </Wrapper>
  );
};
