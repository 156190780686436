import { useFormik } from "formik";
import { useCallback, useMemo } from "react";
import * as Yup from "yup";

import { Founder, PeopleFormFields, PersonProgressing } from "../types";
import { transformIn, transformOut } from "../transformers";

import { FoundersSection } from "./FoundersSection";
import { ProgressionSection } from "./ProgressionSection";

import { CompanyInput, CompanyPeopleFragment } from "@hire/schema";
import { Spacing, InputField, Button } from "@otta/design";
import { MoreInfoFieldWrapper } from "@hire/components/form/MoreInfoFieldWrapper";

const validationSchema = Yup.object().shape({
  numberEmployees: Yup.number()
    .positive("Enter a value greater than 0")
    .required("Fill in this field"),
  percentageFemale: Yup.number()
    .min(0, "Enter a percentage value between 0 and 100, or leave it blank")
    .max(100, "Enter a percentage value between 0 and 100, or leave it blank")
    .optional(),
});

export const PeopleForm = ({
  data,
  onSave: handleSave,
}: {
  data: CompanyPeopleFragment;
  onSave: (input: CompanyInput) => void;
}) => {
  const initialValues = useMemo(() => transformIn(data), [data]);

  const handleSubmit = useCallback(
    (values: PeopleFormFields) => {
      return handleSave(transformOut(values, data));
    },
    [data, handleSave]
  );
  const form = useFormik<PeopleFormFields>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleUpdateFounders = (updatedFounders: Founder[]) => {
    form.setFieldValue("founders", updatedFounders);
  };

  const handleUpdatePeople = (updatedPeople: PersonProgressing[]) => {
    form.setFieldValue("peopleProgressing", updatedPeople);
  };
  return (
    <form onSubmit={form.handleSubmit}>
      <Spacing size={2}>
        <InputField
          value={form.values.numberEmployees}
          name="numberEmployees"
          label="Number of employees"
          type="number"
          onChange={form.handleChange("numberEmployees")}
          error={
            form.touched.numberEmployees
              ? form.errors.numberEmployees
              : undefined
          }
        />
        <MoreInfoFieldWrapper
          label="Percentage of women employees"
          fieldName="percentageFemale"
          guidance="Candidates appreciate transparency around gender diversity."
          optional
        >
          <InputField
            value={form.values.percentageFemale}
            name="percentageFemale"
            type="number"
            onChange={form.handleChange("percentageFemale")}
            error={
              form.touched.percentageFemale
                ? form.errors.percentageFemale
                : undefined
            }
          />
        </MoreInfoFieldWrapper>

        <FoundersSection
          founders={form.values.founders}
          onChange={handleUpdateFounders}
        />
        <ProgressionSection
          people={form.values.peopleProgressing}
          onChange={handleUpdatePeople}
        />

        <Button type="submit" level="primary" disabled={form.isSubmitting}>
          {form.isSubmitting ? "Saving..." : "Save changes"}
        </Button>
      </Spacing>
    </form>
  );
};
