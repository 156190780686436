import styled from "@xstyled/styled-components";
import { useLocation } from "react-router-dom";

import { FilterOptions } from "./graph-helpers";

import { Button } from "@otta/design";
import { pxToRem, palette } from "@otta/design-tokens";
import { pushAnalyticsEvent } from "@otta/analytics";
import { Icon } from "@otta/icons";

const ToggleContainer = styled.div`
  display: flex;
  gap: sm;
  height: ${pxToRem(40)};
  padding: xs;
  border: 1px solid ${palette.grayscale.shade200};
  border-radius: 4;
  background-color: white;

  &[aria-disabled="true"] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Toggle = styled(Button).attrs({ level: "tertiary" })`
  /* For the All filter */
  min-width: ${pxToRem(72)};
  padding: 6 lg;
  border: none;
  line-height: 100%;

  /* Trick to prevent the tabs to jump when we set the font-weight to bold, see https://github.com/WTTJ/otta/pull/858 */
  display: inline-flex;
  flex-direction: column;
  gap: xxs;
  &::after {
    display: block;
    content: attr(title);
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  &[aria-selected="true"] {
    background-color: beige-200;
    font-weight: 600;
  }

  &[aria-disabled="true"] {
    pointer-events: none;
    flex-direction: row;

    /* We remove the trick because we can't click on those element and we need the children to be aligned */
    &::after {
      content: none;
    }
  }
`;

export function FilterToggle({
  currentFilter,
  changeFilter,
  section,
  displayAll = true,
}: {
  section: string;
  currentFilter: FilterOptions;
  changeFilter: (filter: FilterOptions) => void;
  displayAll?: boolean;
}) {
  const { pathname } = useLocation();
  const selectFilter = (filter: FilterOptions) => {
    if (filter !== "all") {
      pushAnalyticsEvent({
        eventName: "Company Recruiter Clicked",
        page: pathname,
        section,
        filter,
      });
    }

    changeFilter(filter);
  };

  return (
    <ToggleContainer>
      {displayAll && (
        <Toggle
          onClick={() => selectFilter("all")}
          aria-selected={currentFilter === "all"}
          title="All"
        >
          All
        </Toggle>
      )}
      <Toggle
        onClick={() => selectFilter("genders")}
        aria-selected={currentFilter === "genders"}
        title="Gender"
      >
        Gender
      </Toggle>
      <Toggle
        onClick={() => selectFilter("ethnicities")}
        aria-selected={currentFilter === "ethnicities"}
        title="Ethnicity"
      >
        Ethnicity
      </Toggle>
      <Toggle
        onClick={() => selectFilter("experienceLevels")}
        aria-selected={currentFilter === "experienceLevels"}
        title="Seniority"
      >
        Seniority
      </Toggle>
    </ToggleContainer>
  );
}

export function UpsellFilterToggle({
  displayAll = true,
}: {
  displayAll?: boolean;
}) {
  return (
    <ToggleContainer aria-disabled>
      {displayAll && (
        <Toggle aria-disabled aria-selected>
          All
        </Toggle>
      )}
      <Toggle title="Gender" aria-disabled aria-selected={!displayAll}>
        <Icon icon="locked" /> Gender
      </Toggle>
      <Toggle title="Ethnicity" aria-disabled>
        <Icon icon="locked" /> Ethnicity
      </Toggle>
      <Toggle title="Seniority" aria-disabled>
        <Icon icon="locked" /> Seniority
      </Toggle>
    </ToggleContainer>
  );
}
