import styled from "@xstyled/styled-components";
import { useQuery } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";

import { palette, pxToRem } from "@otta/design-tokens";
import { Button, Modal, Paragraph, Spacing, Text } from "@otta/design";
import { Icon } from "@otta/icons";
import { CompaniesDetailsDocument } from "@hire/schema";
import { Author, authors } from "@hire/components/Author";
import { Loading } from "@otta/shared-components";
import { pushAnalyticsEvent } from "@otta/analytics";

const Logo = styled.img`
  width: ${pxToRem(100)};
  object-fit: contain;
  margin-bottom: lg;
  background-color: white;
`;

const ModalContent = styled.div`
  padding: lg;
`;

const ButtonContainer = styled.div`
  border-top: 1px solid ${palette.grayscale.shade200};
  padding: md;
  display: flex;

  gap: 1rem;
  justify-content: center;
`;

const ModalButton = styled(Button)`
  min-width: ${pxToRem(200)};
  gap: 0.5rem;
`;

export const OttasTakeModal = ({
  open,
  closeModal,
}: {
  open: boolean;
  closeModal: () => void;
}) => {
  useEffect(() => {
    pushAnalyticsEvent({
      eventName: "Company Recruiter Viewed Modal",
      modalName: "otta-take-example",
    });
  });

  const exampleCompanies = ["Wise", "Cazoo", "Too-Good-To-Go"];
  const people = useMemo(
    () =>
      authors
        .map(person => person.name)
        .sort(() => (Math.random() > 0.5 ? 1 : -1)),
    []
  );

  const [currentCompanyIndex, setCurrentCompanyIndex] = useState(0);

  const { data: companiesData, loading: companyDataLoading } = useQuery(
    CompaniesDetailsDocument,
    {
      variables: {
        urlSafeNames: exampleCompanies,
      },
    }
  );

  const companies = useMemo(() => {
    return companiesData?.companies || [];
  }, [companiesData]);

  const [currentPerson, setCurrentPerson] = useState(people[0]);

  const shuffle = () => {
    setCurrentCompanyIndex(currentCompanyIndex =>
      currentCompanyIndex === companies.length - 1 ? 0 : currentCompanyIndex + 1
    );

    setCurrentPerson(() =>
      people.indexOf(currentPerson) === 2
        ? people[0]
        : people[people.indexOf(currentPerson) + 1]
    );
  };

  const currentCompany = companies[currentCompanyIndex] ?? companies[0];

  return (
    <Modal open={open} onOpenChange={closeModal}>
      {companyDataLoading ? (
        <Loading />
      ) : (
        <>
          <ModalContent>
            {companies[currentCompanyIndex]?.logoPath && (
              <Logo
                src={currentCompany.logoPath ?? ""}
                alt={`${currentCompany.name} logo`}
              />
            )}
            <Text size={1} bold>
              Our take
            </Text>
            <Author name={currentPerson} />

            <Spacing size={1}>
              {currentCompany?.marketBullets.map((bullet, index) => (
                <Paragraph size={-1} key={index}>
                  {bullet.value}
                </Paragraph>
              ))}
            </Spacing>
          </ModalContent>
          <ButtonContainer>
            <ModalButton level="primary" onClick={shuffle}>
              <Icon icon="shuffle" />
              Shuffle example
            </ModalButton>

            <ModalButton level="secondary" onClick={closeModal}>
              Close
            </ModalButton>
          </ButtonContainer>
        </>
      )}
    </Modal>
  );
};
