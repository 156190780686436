import * as React from "react";
import type { SVGProps } from "react";
const SvgSourcing = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#212121"
      d="M6.5 8a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0ZM13 8a4 4 0 1 0-8 0 4 4 0 0 0 8 0ZM3.5 18.5a4 4 0 0 1 4-3.5h3.6a5.1 5.1 0 0 1 0-1.5H7.5c-3 0-5.5 2.5-5.5 5.6 0 .5.4.9 1 .9h12c.6 0 1-.4 1-1a5 5 0 0 1-2.2-.5H3.5Zm12.5-7a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm0 6.5a4 4 0 0 0 2.2-.7l2.5 2.5a.7.7 0 1 0 1-1l-2.4-2.6A4 4 0 1 0 16 18Z"
    />
  </svg>
);
export default SvgSourcing;
