import { Link } from "react-router-dom";
import { styled } from "@xstyled/styled-components";

import { Button, Card, Text } from "@otta/design";

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: lg;
  margin-bottom: xl;
`;

const Image = styled.img`
  object-fit: contain;
  height: 200;
`;

export function NewUserUpsell() {
  return (
    <StyledCard data-testid="new-user-upsell-modal">
      <Image
        src="https://storage.googleapis.com/otta-static/hire/new_user_upsell.jpg"
        alt="Graphic with charts"
      />
      <Text bold>Speak to a member of our team to optimise your profile</Text>
      <Text>
        Unlock your company profile's full potential and connect with our team
        to learn how to attract top talent, showcase your culture, and stand out
        to candidates.
      </Text>
      <Button
        level="primary"
        as={Link}
        to="/book-a-call?utm_source=hire_app&utm_medium=jobs&utm_campaign=upsell&utm_content=new_basic_user_cta"
        data-analytics-id="job-upsell-book-a-call-to-learn-more"
      >
        Book a call to learn more
      </Button>
    </StyledCard>
  );
}
