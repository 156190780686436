import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "@xstyled/styled-components";

import { SalaryNudgeBanner } from "./SalaryNudgeBanner";
import { JobBanner } from "./JobBanner";
import { CloseApplications } from "./EditJob/CloseApplications";
import { Tab } from "./Tabs/Tab";

import {
  ArchiveCompanyJobDocument,
  JobFeature,
  JobInfoDocument,
  JobSalaryQualityDocument,
  JobWorkflowStatus,
  SalaryQualityRating,
} from "@hire/schema";
import { palette, modularScale, pxToRem } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Button, Modal, Spacing, Text } from "@otta/design";
import { Loading } from "@otta/shared-components";
import { ReloadableError } from "@hire/components/ReloadableError";
import { Link } from "@hire/components/links/Link";
import { JobNotificationSubscription } from "@hire/components/JobNotificationSubscription";
import * as Banner from "@hire/components/Banner";
import { useJobAuthorizations } from "@hire/providers/authorization";
import { useRequiredParams } from "@hire/util/routing";
import { handleMutationError } from "@hire/errors";
import { setRecentlyViewedJob } from "@hire/hooks/useRecentlyViewedJobs";
import { useCurrentUser } from "@hire/hooks/useCurrentUser";
import { pushAnalyticsEvent } from "@otta/analytics";

const PageContainer = styled.div<{ salaryBanner: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  height: 100%;
`;

const Header = styled.div`
  background: ${palette.brand.white};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: lg lg 0 ${modularScale()};
  border-bottom: 1px solid ${palette.grayscale.shade200};
  z-index: 3;
  max-width: 100%;
`;

const Tabs = styled.div`
  display: flex;
  margin-top: xl;
  gap: 40;
  max-width: 100%;
  overflow-x: auto;
`;

const Row = styled.div`
  display: flex;
  gap: lg;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const SalaryWarning = styled.div`
  display: flex;
  gap: sm;
  align-items: center;
`;

const Dot = styled.div`
  width: ${pxToRem(8)};
  height: ${pxToRem(8)};
  background-color: orange-400;
  border-radius: 50%;
`;

const StyledButton = styled(Button)`
  text-decoration: none;
`;

const SalaryQualityWarning = ({ children }: { children: string }) => (
  <SalaryWarning>
    <Dot />
    <Text size={-1}>{children} salary - this can discourage candidates</Text>
  </SalaryWarning>
);

export const Job = () => {
  const { pathname } = useLocation();
  const { jobId } = useRequiredParams(["jobId"]);
  const [user] = useCurrentUser();
  const isProCompany = user?.currentCompany?.isPro;

  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => setRecentlyViewedJob(jobId), [jobId]);

  const { features, loading: featuresLoading } = useJobAuthorizations([
    JobFeature.Sourcing,
    JobFeature.Analytics,
    JobFeature.Edit,
    JobFeature.Broadcast,
    JobFeature.Inbox,
    JobFeature.Pipeline,
    JobFeature.Archive,
  ]);

  const { data, loading, error, refetch } = useQuery(JobInfoDocument, {
    variables: {
      jobId: jobId,
    },
  });

  const { data: salaryData } = useQuery(JobSalaryQualityDocument, {
    variables: {
      jobId: jobId,
    },
  });

  const job = data?.companyJob;
  const salaryQuality = salaryData?.companyJob?.salaryQuality;
  const lowSalary = salaryQuality === SalaryQualityRating.Low;
  const broadSalary = salaryQuality === SalaryQualityRating.Broad;
  const inReview = job?.workflowStatus === JobWorkflowStatus.QaInternal;
  const isCompletedByUser =
    job?.workflowStatus === JobWorkflowStatus.CompletedByUser;

  useEffect(() => {
    setShowBanner(job?.salaryRange === null);
  }, [job?.salaryRange]);

  const pipelineFeature = features.get(JobFeature.Pipeline);

  const [closeApplicationsModalOpen, setCloseApplicationsModalOpen] =
    useState(false);

  const canArchive = features.get(JobFeature.Archive);

  const navigate = useNavigate();

  const [archiveJob] = useMutation(ArchiveCompanyJobDocument, {
    variables: {
      id: jobId,
    },
    onError: handleMutationError,
    onCompleted: () => {
      navigate("../..");
    },
  });

  if (loading || featuresLoading) {
    return <Loading />;
  }

  if (error) {
    return <ReloadableError action={refetch} />;
  }

  if (!job) {
    return <Navigate to=".." replace />;
  }

  const optimiseJobLink = `https://support.welcometothejungle.com/en/articles/9796729-tips-for-writing-a-great-job-description`;
  const bookACallLink = `https://hire.welcometothejungle.com/book-a-call?utm_source=otta&utm_medium=not_pro&utm_campaign=page_analytics&utm_id=hire_app&utm_term=book_a_demo&utm_content=button`;

  const fireClickEvent = (name: string) => {
    pushAnalyticsEvent({
      eventName: "Company Recruiter Clicked",
      name,
      page: pathname,
    });
  };

  return (
    <>
      <PageContainer salaryBanner={showBanner}>
        <Header>
          <Spacing size={2}>
            <Row>
              <Row>
                <div>
                  <div>
                    <Spacing size={2}>
                      <Link
                        underline={false}
                        newTab
                        to={`${import.meta.env.VITE_SEARCH_APP_HOST}/jobs/${
                          job.externalId
                        }`}
                        style={{
                          display: "flex",
                          gap: pxToRem(8),
                          alignItems: "baseline",
                        }}
                      >
                        <Text size={2} bold>
                          {job.title}
                        </Text>
                        {job.subtitle && <Text size={0}>{job.subtitle}</Text>}
                        <Icon size={0} icon="external" />
                      </Link>
                      {inReview && (
                        <Banner.Info name="job-in-review">
                          <Banner.Content>
                            <Text bold>
                              This job is in review but can still be edited
                            </Text>
                            <Text>
                              We&apos;re reviewing this job before it&apos;s
                              shown to candidates to help set you up for
                              success. You&apos;ll be able to perform other
                              actions, like viewing job analytics, once the
                              review is complete. This can take up to 1 working
                              day.
                            </Text>
                          </Banner.Content>
                        </Banner.Info>
                      )}
                      {isCompletedByUser && (
                        <Banner.Info name="job-in-review">
                          <Banner.Content>
                            <Text bold>
                              This job is live but may still be under review
                            </Text>
                            <Text>
                              This job is now visible to candidates, but
                              we&apos;re performing additional checks to ensure
                              it meets our guidelines. You can continue editing
                              the job if needed, and all features will remain
                              available during this process.
                            </Text>
                          </Banner.Content>
                        </Banner.Info>
                      )}
                    </Spacing>
                    {(lowSalary || broadSalary) && (
                      <SalaryQualityWarning>
                        {lowSalary ? "Low" : "Broad"}
                      </SalaryQualityWarning>
                    )}
                  </div>
                  {!inReview && pipelineFeature?.granted && (
                    <div style={{ marginTop: modularScale(-2) }}>
                      <JobNotificationSubscription
                        jobId={job.id}
                        subscribed={job.subscribed}
                      />
                    </div>
                  )}
                </div>
              </Row>

              <div style={{ display: "flex", gap: "1rem" }}>
                {isProCompany ? (
                  <StyledButton
                    as={Link}
                    newTab
                    to={optimiseJobLink}
                    level="primary"
                    onClick={() => fireClickEvent("speak-to-your-csm")}
                  >
                    Optimise this job
                  </StyledButton>
                ) : (
                  <StyledButton
                    as={Link}
                    newTab
                    to={bookACallLink}
                    level="primary"
                    onClick={() => fireClickEvent("speak-to-product-expert")}
                  >
                    Speak to a product expert
                  </StyledButton>
                )}

                {canArchive?.granted === true && (
                  <Button
                    level="destructive"
                    onClick={() => {
                      if (canArchive?.message === "close applications") {
                        setCloseApplicationsModalOpen(true);
                      } else {
                        archiveJob();
                      }
                    }}
                  >
                    Close job
                  </Button>
                )}
              </div>
            </Row>

            <Row>
              <JobBanner
                isPaused={job.isPaused}
                jobExternalId={job.externalId}
              />
            </Row>
          </Spacing>
          <Tabs>
            <Tab
              to="analytics"
              feature={JobFeature.Analytics}
              granted={features.get(JobFeature.Analytics)?.granted}
              message={features.get(JobFeature.Analytics)?.message}
            />
            <Tab
              to="pipeline"
              feature={JobFeature.Pipeline}
              granted={features.get(JobFeature.Pipeline)?.granted}
              message={features.get(JobFeature.Pipeline)?.message}
            />
            <Tab
              to="inbox"
              feature={JobFeature.Inbox}
              granted={features.get(JobFeature.Inbox)?.granted}
              message={features.get(JobFeature.Inbox)?.message}
            />
            <Tab
              to="sourcing/search"
              feature={JobFeature.Sourcing}
              granted={features.get(JobFeature.Sourcing)?.granted}
              message={features.get(JobFeature.Sourcing)?.message}
            />
            <Tab
              to="edit"
              feature={JobFeature.Edit}
              granted={features.get(JobFeature.Edit)?.granted}
              message={features.get(JobFeature.Edit)?.message}
            />

            <Tab
              to="broadcast"
              feature={JobFeature.Broadcast}
              granted={features.get(JobFeature.Broadcast)?.granted}
              message={features.get(JobFeature.Broadcast)?.message}
            />
          </Tabs>
        </Header>

        {showBanner && (
          <SalaryNudgeBanner closeSalaryBanner={() => setShowBanner(false)} />
        )}
        <Outlet />
      </PageContainer>
      <Modal
        open={closeApplicationsModalOpen}
        onOpenChange={setCloseApplicationsModalOpen}
        dismissable={false}
        headerContent={
          <Text bold size={1}>
            Close job applications
          </Text>
        }
      >
        <div style={{ padding: modularScale() }}>
          <CloseApplications
            jobId={job.externalId}
            archive={archiveJob}
            onCancel={() => setCloseApplicationsModalOpen(false)}
          />
        </div>
      </Modal>
    </>
  );
};
