import { PeopleFormFields } from "./types";

import { CompanyInput, CompanyPeopleFragment } from "@hire/schema";

const getChangedCompanyFields = (
  newValues: CompanyInput,
  initialValues: CompanyPeopleFragment
) => {
  const changedFields = Object.entries(newValues).filter(([key, newValue]) => {
    if (key === "founders") {
      if (newValues.founders?.length !== initialValues.founders.length) {
        return true;
      }
      const anyChangedFounders = newValues.founders?.map((founder, index) => {
        const anyChangedFields = [
          founder.name !== initialValues.founders[index].name,
          founder.title !== initialValues.founders[index].title,
          founder.linkedinUrl !== initialValues.founders[index].linkedinUrl,
          founder.bio !== initialValues.founders[index].bio,
        ].some(fieldChanged => fieldChanged === true);

        return anyChangedFields;
      });
      return anyChangedFounders?.some(hasChanged => hasChanged === true);
    }

    if (key === "peopleProgressing") {
      if (
        newValues.peopleProgressing?.length !==
        initialValues.peopleProgressing.length
      ) {
        return true;
      }
      const anyChangedEmployees = newValues.peopleProgressing?.map(
        (person, index) => {
          const anyChangedFields = [
            person.name !== initialValues.peopleProgressing[index].name,
            person.linkedinUrl !==
              initialValues.peopleProgressing[index].linkedinUrl,
            person.bio !== initialValues.peopleProgressing[index].bio,
          ].some(fieldChanged => fieldChanged === true);

          return anyChangedFields;
        }
      );
      return anyChangedEmployees?.some(hasChanged => hasChanged === true);
    }

    const [, originalValue] = Object.entries(initialValues).filter(
      ([initialKey]) => {
        return initialKey === key;
      }
    )[0];

    return originalValue !== newValue;
  });
  return Object.fromEntries(changedFields);
};

export const transformIn = (data: CompanyPeopleFragment): PeopleFormFields => {
  return {
    numberEmployees: data.numberEmployees ?? undefined,
    percentageFemale: data.percentageFemale ?? undefined,
    founders: data.founders.map(founder => ({
      name: founder.name,
      title: founder.title,
      bio: founder.bio ?? undefined,
      linkedinUrl: founder.linkedinUrl ?? undefined,
    })),
    peopleProgressing: data.peopleProgressing.map(person => ({
      name: person.name,
      bio: person.bio ?? undefined,
      linkedinUrl: person.linkedinUrl ?? undefined,
    })),
  };
};

export const transformOut = (
  formValues: PeopleFormFields,
  initialValues: CompanyPeopleFragment
): CompanyInput => {
  const values: CompanyInput = {
    numberEmployees: formValues.numberEmployees ?? null,
    percentageFemale:
      typeof formValues.percentageFemale === "number"
        ? formValues.percentageFemale
        : null,
    founders: formValues.founders.map(founder => ({
      name: founder.name,
      title: founder.title,
      bio: founder.bio?.length ? founder.bio : null,
      linkedinUrl: founder.linkedinUrl?.length ? founder.linkedinUrl : null,
    })),
    peopleProgressing: formValues.peopleProgressing.map(person => ({
      name: person.name,
      bio: person.bio?.length ? person.bio : null,
      linkedinUrl: person.linkedinUrl?.length ? person.linkedinUrl : null,
    })),
  };

  return getChangedCompanyFields(values, initialValues);
};
