import styled, { down, css } from "@xstyled/styled-components";

import { useRequireLoginState } from "./useRequireLoginState";

import { Button, Heading, Text } from "@otta/design";
import { Link } from "@hire/components/links";
import { Loading } from "@otta/shared-components";
import { palette, pxToRem, modularScale } from "@otta/design-tokens";
import { Experiment } from "@hire/constants/experiments";
import { useExperiment } from "@otta/experiments/client";

const PageContainer = styled.div`
  padding: 80 0;
  display: flex;
  flex-direction: column;
  gap: 80;

  ${down(
    "tablet",
    css`
      gap: 40;
      padding: xl 0;
    `
  )}
`;

const ButtonLink = styled(Button)`
  text-decoration: none;
`;

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40;
  justify-content: center;

  ${down(
    "tablet",
    css`
      flex-direction: column;
      gap: xl;
      align-items: center;
    `
  )}
`;

const PlanCard = styled.div`
  background: ${palette.brand.white};
  border-radius: 4;
  min-height: 100%;
  padding: xl;
  width: ${pxToRem(465)};

  ${down(
    "tablet",
    css`
      width: 100%;
    `
  )}
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: lg;
  padding: lg;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: xl;
  height: 100%;
  justify-content: space-between;
  ${down(
    "tablet",
    css`
      gap: lg;
    `
  )}
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: xl;
`;

const StyledHeading = styled(Heading).attrs({ size: 6, align: "center" })`
  ${down(
    "tablet",
    css`
      font-size: ${pxToRem(28)};
    `
  )}
`;

const StyledCardHeading = styled(Heading).attrs({ size: 4 })`
  ${down(
    "tablet",
    css`
      font-size: ${pxToRem(23)};
    `
  )}
`;

const StyledText = styled(Text).attrs({ size: 2 })`
  ${down(
    "tablet",
    css`
      font-size: ${modularScale(0)};
    `
  )}
`;

export function CoreOrPro() {
  const { loading } = useRequireLoginState({
    state: "logged-out",
    redirect: "/quiz/pricing-details",
  });

  const { variant } = useExperiment(Experiment.NewStarterJourney);

  const postAJobLink =
    variant === "variant"
      ? "/quiz/pricing-details"
      : "/quiz/job-performance?utm_source=otta&utm_medium=quiz&utm_campaign=page_post_job&utm_id=hire_app&utm_term=post_job";

  return loading ? (
    <Loading />
  ) : (
    <PageContainer>
      <StyledHeading>Solutions to fit your hiring needs</StyledHeading>
      <Grid>
        <PlanCard>
          <Content>
            <CardHeader>
              <StyledCardHeading>Starter</StyledCardHeading>
              <StyledText>
                Pay-as-you-go, flexible hiring platform for inbound
                applications.
              </StyledText>
            </CardHeader>
            <ButtonLink
              data-analytics-id="post-job"
              level="primary"
              as={Link}
              to={postAJobLink}
            >
              Post a job today
            </ButtonLink>
          </Content>
        </PlanCard>
        <PlanCard>
          <Content>
            <CardHeader>
              <StyledCardHeading>Pro</StyledCardHeading>
              <StyledText>
                Powerful hiring solution with inbound applications, outbound
                sourcing and strategic support.
              </StyledText>
            </CardHeader>
            <ButtonLink
              data-analytics-id="request-demo"
              level="secondary"
              as={Link}
              to="/book-a-demo?utm_source=otta&utm_medium=quiz&utm_campaign=page_talk_to_sales&utm_id=hire_app&utm_term=book_demo&utm_content=link_1"
            >
              Book a demo
            </ButtonLink>
          </Content>
        </PlanCard>
      </Grid>
      <BottomSection>
        <StyledText bold align="center">
          Not sure? Speak to one of our product experts.
        </StyledText>
        <ButtonLink
          data-analytics-id="book-call"
          level="secondary"
          style={{ alignSelf: "center" }}
          as={Link}
          to="/book-a-call?utm_source=otta&utm_medium=quiz&utm_campaign=page_talk_to_sales&utm_id=hire_app&utm_term=book_a_call&utm_content=link_2"
        >
          Book a call
        </ButtonLink>
      </BottomSection>
    </PageContainer>
  );
}
