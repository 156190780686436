import styled from "@xstyled/styled-components";
import { format, parseJSON } from "date-fns";
import * as Dialog from "@radix-ui/react-dialog";
import { FormEvent, Fragment, useEffect, useRef, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import { Link, useParams } from "react-router-dom";
import { differenceInMonths } from "date-fns/esm";

import { Button, Input, Text } from "@otta/design";
import { modularScale, pxToRem } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import {
  AtsIntegrationDocument,
  Feature,
  JobInboundPerformanceCandidate,
  JobPerformanceAppliedCandidatesDocument,
} from "@hire/schema";
import { useRequiredParams } from "@hire/util/routing";
import { pushAnalyticsEvent } from "@otta/analytics";
import { useAuthorization } from "@hire/providers/authorization";

export const integrateATSReminderLocalStorageKey = "integrate-ats-reminder";

const APPLIED_VIA: Record<
  JobInboundPerformanceCandidate["appliedVia"],
  string
> = {
  otta: "Otta",
  company_website: "Company website",
};

const StyledButton = styled(Button)`
  position: relative;
  z-index: 2;
`;

const TooltipContent = styled(RadixTooltip.Content)`
  display: flex;
  flex-direction: column;
  gap: sm;
  max-width: ${pxToRem(340)};
  padding: sm;
  border-radius: 4;
  background-color: blue-200;
  font-size: ${modularScale(-1)};
  z-index: 50;
`;

const TooltipFooter = styled.div`
  display: flex;
  align-items: center;
  gap: sm;
`;

const TooltipButton = styled(Button).attrs({ level: "secondary" })`
  padding: sm lg;
  font-size: inherit;
`;

const TooltipLink = styled(Text)`
  font-size: inherit;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
`;

const TooltipArrow = styled(RadixTooltip.Arrow)`
  fill: blue-200;
`;

const Overlay = styled(Dialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  place-items: center;
  z-index: 100;
`;

const Content = styled(Dialog.Content)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: xl;
  width: ${pxToRem(800)};
  max-width: calc(100% - ${pxToRem(48)});
  max-height: ${pxToRem(640)};
  padding: xl;
  border-radius: 8;
  background-color: white;
  overflow: hidden;
  z-index: 100;
`;

const Title = styled(Text).attrs({ size: 2, bold: true })`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const CrossButton = styled(Dialog.Close)`
  cursor: pointer;
`;

const Form = styled.form`
  display: flex;
  gap: lg;
  align-items: flex-end;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  gap: sm;
  width: ${pxToRem(240)};
`;

const SearchButton = styled(Button).attrs({ level: "primary" })`
  padding: sm xl;
`;

const TableContainer = styled.div`
  overflow-y: auto;
  width: 100%;
`;

// Tables are not fun to style
const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  column-gap: xl;
  row-gap: lg;
  margin-right: -16;
  width: 100%;
`;

const CandidateLink = styled.a`
  color: inherit;
`;

const Subtext = styled(Text)`
  color: gray-600;
  margin-top: lg;
`;

const CloseButton = styled(Button).attrs({ level: "secondary" })`
  padding: sm xl;
  width: ${pxToRem(200)};
`;

const useCanIntegrateAts = () => {
  const { granted, loading: isLoadingAuthorization } = useAuthorization(
    Feature.AtsIntegration
  );

  const {
    data,
    error,
    loading: isLoadingAtsIntegrationQuery,
  } = useQuery(AtsIntegrationDocument);

  if (isLoadingAuthorization || isLoadingAtsIntegrationQuery || error)
    return null;

  return !granted && Boolean(data?.currentUser?.currentCompany?.ats);
};

const Trigger = ({
  hasFetchedData,
  fetchData,
  onClick,
}: {
  hasFetchedData: boolean;
  fetchData: () => void;
  onClick: () => void;
}) => {
  const { companySlug } = useParams();

  // Start fetching on hover to avoid loading state
  const handleMouseEnterTrigger = hasFetchedData
    ? undefined
    : () => fetchData();

  const handleClickTrigger = () => {
    if (!hasFetchedData) {
      fetchData();
    }
    onClick();
  };

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const canIntegrateAts = useCanIntegrateAts();
  useEffect(() => {
    if (!canIntegrateAts) return;

    // Show tooltip once a month
    const lastShown =
      localStorage.getItem(integrateATSReminderLocalStorageKey) ?? "";
    const lastShownDate = new Date(lastShown);
    if (lastShown && differenceInMonths(new Date(), lastShownDate) === 0)
      return;

    pushAnalyticsEvent({
      eventName: "Company Recruiter Viewed",
      pathname: window.location.pathname,
      name: "integrate-ats-prompt",
    });

    setIsTooltipOpen(true);
  }, [canIntegrateAts]);

  const handleClickRemindMeLater = () => {
    localStorage.setItem(
      integrateATSReminderLocalStorageKey,
      new Date().toISOString()
    );
    setIsTooltipOpen(false);
  };

  return (
    <RadixTooltip.Root open={isTooltipOpen}>
      <RadixTooltip.Trigger asChild>
        <StyledButton
          size="small"
          level="secondary"
          onClick={handleClickTrigger}
          onMouseEnter={handleMouseEnterTrigger}
          data-analytics-id="see-applicants"
        >
          See applicants
        </StyledButton>
      </RadixTooltip.Trigger>
      <RadixTooltip.Portal>
        <TooltipContent sideOffset={10} side="left">
          Integrate your ATS for better candidate attribution, so you don&apos;t
          need to manually check this list.
          <TooltipFooter>
            <TooltipButton
              as={Link}
              to={`/${companySlug}/settings/ats`}
              data-analytics-id="integrate-ats"
            >
              Integrate ATS
            </TooltipButton>
            <TooltipLink
              onClick={handleClickRemindMeLater}
              data-analytics-id="remind-me-later"
            >
              Remind me later
            </TooltipLink>
          </TooltipFooter>
          <TooltipArrow />
        </TooltipContent>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  );
};

export const CandidatesModal = ({
  totalCandidates,
}: {
  totalCandidates: number;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { jobId } = useRequiredParams(["jobId"]);
  const [fetch, { data, refetch, loading, called }] = useLazyQuery(
    JobPerformanceAppliedCandidatesDocument,
    {
      variables: { id: jobId },
    }
  );
  const candidates = (data?.companyJob?.performanceStatistics
    ?.inboundPerformance.totals.applied.candidates ??
    []) as JobInboundPerformanceCandidate[];

  const queryRef = useRef<HTMLInputElement>(null);

  const [hasSearched, setHasSearched] = useState(false);
  const handleSearch = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const query = queryRef.current?.value;
    await refetch({ id: jobId, name: query || null });
    setHasSearched(!!query);

    pushAnalyticsEvent({
      eventName: "Company Recruiter Clicked",
      pathname: window.location.pathname,
      name: "search-applicants",
    });
  };

  // Reset on close
  useEffect(() => {
    if (isOpen || !queryRef.current) return;

    if (queryRef.current.value !== "") {
      refetch({ id: jobId, name: null });
      queryRef.current.value = "";
    }

    setHasSearched(false);
  }, [isOpen, jobId, refetch]);

  if (totalCandidates === 0) return null;

  return (
    <>
      <Trigger
        hasFetchedData={called}
        fetchData={fetch}
        onClick={() => setIsOpen(true)}
      />
      <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
        <Dialog.Portal>
          <Overlay />
          <Content>
            <Title>
              {hasSearched && !loading && `${candidates.length} of `}
              {totalCandidates} applicants
              <CrossButton aria-label="close" asChild>
                <Icon icon="close" size={2} />
              </CrossButton>
            </Title>

            {totalCandidates > 0 && (
              <Form onSubmit={handleSearch}>
                <Label>
                  <Text size={0} bold>
                    Candidate name
                  </Text>
                  <Input type="text" name="query" ref={queryRef} />
                </Label>

                <SearchButton disabled={loading} type="submit">
                  Search {loading && "..."}
                </SearchButton>
              </Form>
            )}

            <TableContainer>
              <Table>
                <Text bold>Candidate name</Text>
                <Text bold>Seen job at</Text>
                <Text bold>Current job</Text>
                <Text bold>Applied via</Text>
                {candidates.map(({ id, seenJobAt, candidate, appliedVia }) => (
                  <Fragment key={id}>
                    <div data-cs-mask>
                      {candidate.linkedinUrl ? (
                        <CandidateLink
                          href={candidate.linkedinUrl}
                          target="_blank"
                          rel="noreferrer noopener"
                          data-cs-mask
                        >
                          {`${candidate.firstName} ${candidate.lastName}`}
                        </CandidateLink>
                      ) : (
                        `${candidate.firstName} ${candidate.lastName}`
                      )}
                    </div>
                    <div>{format(parseJSON(seenJobAt), "E dd MMM, H:mm")}</div>
                    <div>
                      {candidate.workExperiences[0]
                        ? `${candidate.workExperiences[0].title}, ${candidate.workExperiences[0].companyName}`
                        : "N/A"}
                    </div>
                    <div>{APPLIED_VIA[appliedVia] ?? "N/A"}</div>
                  </Fragment>
                ))}
              </Table>
              {totalCandidates > 100 && candidates.length === 100 && (
                <Subtext>
                  This list is limited to the latest 100 applicants. You can
                  search across all applicants.
                </Subtext>
              )}

              {hasSearched && candidates.length === 0 && (
                <Subtext>There are no applicants with that name.</Subtext>
              )}
            </TableContainer>

            <CloseButton level="secondary" onClick={() => setIsOpen(false)}>
              Close
            </CloseButton>
          </Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};
