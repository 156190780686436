/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * The `Date` scalar type represents a date. The Date appears in a JSON
   * response as an ISO8601 formatted string, without a time component.
   */
  Date: { input: any; output: any; }
  /**
   * The `DateTime` scalar type represents a date and time in the UTC
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string, including UTC timezone ("Z"). The parsed date and time string will
   * be converted to UTC if there is an offset.
   */
  DateTime: { input: any; output: any; }
  /**
   * The `Decimal` scalar type represents signed double-precision fractional
   * values parsed by the `Decimal` library. The Decimal appears in a JSON
   * response as a string to preserve precision.
   */
  Decimal: { input: any; output: any; }
  /**
   * The `Naive DateTime` scalar type represents a naive date and time without
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string.
   */
  NaiveDateTime: { input: any; output: any; }
  /** Represents an uploaded file. */
  Upload: { input: any; output: any; }
};

export type AccessGrant = {
  __typename?: 'AccessGrant';
  feature: Feature;
  granted: Scalars['Boolean']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type AcquisitionChannel = {
  __typename?: 'AcquisitionChannel';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type AllJobsStatistics = {
  __typename?: 'AllJobsStatistics';
  averageMessageAcceptanceRate?: Maybe<Scalars['Decimal']['output']>;
  averageMessageNoResponseRate?: Maybe<Scalars['Decimal']['output']>;
  averageMessageRejectionRate?: Maybe<Scalars['Decimal']['output']>;
  totalNumberCandidatesMessaged?: Maybe<Scalars['Int']['output']>;
  totalNumberCandidatesShortlisted?: Maybe<Scalars['Int']['output']>;
};

export type AnswerQuestionData = {
  __typename?: 'AnswerQuestionData';
  atsId?: Maybe<Scalars['String']['output']>;
  question: Scalars['String']['output'];
};

export type ApplicationQuestionResponseInput = {
  booleanResponse?: InputMaybe<Scalars['Boolean']['input']>;
  dateResponse?: InputMaybe<Scalars['Date']['input']>;
  decimalResponse?: InputMaybe<Scalars['Decimal']['input']>;
  fileResponse?: InputMaybe<FileResponseInput>;
  multipleChoiceResponse?: InputMaybe<Array<InputMaybe<SingleChoiceResponseInput>>>;
  singleChoiceResponse?: InputMaybe<SingleChoiceResponseInput>;
  stringResponse?: InputMaybe<Scalars['String']['input']>;
  urlResponse?: InputMaybe<Scalars['String']['input']>;
};

export type AtsAnswerData = GreenhouseAnswerData | LeverAnswerData | OttaAnswerData | WorkableAnswerData;

export enum AtsDateFormat {
  Date = 'DATE',
  DateTime = 'DATE_TIME',
  Time = 'TIME'
}

export enum AtsFileFormat {
  Doc = 'DOC',
  Docx = 'DOCX',
  Jpeg = 'JPEG',
  Pdf = 'PDF',
  Png = 'PNG',
  Rtf = 'RTF',
  Txt = 'TXT',
  Xlsx = 'XLSX',
  Zip = 'ZIP'
}

export type AtsInformation = {
  __typename?: 'AtsInformation';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
};

export enum AtsIntegrationAuthType {
  ApiKey = 'API_KEY',
  Oauth = 'OAUTH'
}

export type AtsQuestionAnswerFileValueInput = {
  name: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type AtsQuestionAnswerInput = {
  questionId: Scalars['ID']['input'];
  sectionId: Scalars['ID']['input'];
  value: Array<AtsQuestionAnswerValueInput>;
};

export type AtsQuestionAnswerValueInput = {
  booleanValue?: InputMaybe<Scalars['Boolean']['input']>;
  decimalValue?: InputMaybe<Scalars['Decimal']['input']>;
  fileValue?: InputMaybe<AtsQuestionAnswerFileValueInput>;
  idValue?: InputMaybe<Scalars['ID']['input']>;
  stringValue?: InputMaybe<Scalars['String']['input']>;
  uploadValue?: InputMaybe<Scalars['Upload']['input']>;
};

export type AtsQuestionData = GreenhouseQuestionData | LeverQuestionData | OttaQuestionData | WorkableQuestionData;

export type AtsQuestionV1 = AtsInformation | BooleanAtsQuestion | ConsentAtsQuestion | DateTimeAtsQuestion | FileAtsQuestion | NumberAtsQuestion | SelectAtsQuestion | TextAtsQuestion;

export type AtsQuestionsV1 = {
  __typename?: 'AtsQuestionsV1';
  id: Scalars['ID']['output'];
  sections: Array<AtsSectionV1>;
};

export type AtsSectionV1 = {
  __typename?: 'AtsSectionV1';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  localId: Scalars['ID']['output'];
  questions: Array<AtsQuestionV1>;
};

export enum AtsTextFormat {
  Email = 'EMAIL',
  Long = 'LONG',
  Phone = 'PHONE',
  Short = 'SHORT',
  Url = 'URL'
}

export type AuthStrategy = {
  __typename?: 'AuthStrategy';
  loginUrl: Scalars['String']['output'];
  signupUrl: Scalars['String']['output'];
  type: AuthStrategyType;
};

export enum AuthStrategyType {
  Password = 'PASSWORD',
  Saml = 'SAML'
}

export type BooleanAnswer = {
  __typename?: 'BooleanAnswer';
  questionData: AnswerQuestionData;
  value: Scalars['Boolean']['output'];
};

export type BooleanAnswerInput = {
  questionData: QuestionDataInput;
  value: Scalars['Boolean']['input'];
};

export type BooleanAtsQuestion = {
  __typename?: 'BooleanAtsQuestion';
  answer?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  localId: Scalars['ID']['output'];
  required: Scalars['Boolean']['output'];
};

export type BooleanQuestion = {
  __typename?: 'BooleanQuestion';
  atsId?: Maybe<Scalars['String']['output']>;
  question: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
};

export type BooleanResponse = {
  __typename?: 'BooleanResponse';
  value: Scalars['Boolean']['output'];
};

export type BooleanValue = {
  __typename?: 'BooleanValue';
  value: Scalars['Boolean']['output'];
};

export type Boost = {
  __typename?: 'Boost';
  type: ProductType;
  values: Array<BoostValues>;
};

export type BoostValues = {
  __typename?: 'BoostValues';
  basicViews: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
  isPro: Scalars['Boolean']['output'];
  proViews: Scalars['Int']['output'];
};

export enum BrandAssetGroup {
  CompanyProfileBenefits = 'COMPANY_PROFILE_BENEFITS',
  CompanyProfileDiversityAndInclusion = 'COMPANY_PROFILE_DIVERSITY_AND_INCLUSION',
  CompanyProfileFounders = 'COMPANY_PROFILE_FOUNDERS',
  CompanyProfileGallery = 'COMPANY_PROFILE_GALLERY',
  CompanyProfileHeader = 'COMPANY_PROFILE_HEADER',
  CompanyProfileOffice = 'COMPANY_PROFILE_OFFICE',
  CompanyProfilePeopleProgressing = 'COMPANY_PROFILE_PEOPLE_PROGRESSING',
  CompanySpotlightBenefit = 'COMPANY_SPOTLIGHT_BENEFIT',
  CompanySpotlightOffice = 'COMPANY_SPOTLIGHT_OFFICE',
  TeamCardHeader = 'TEAM_CARD_HEADER',
  TeamMemberAvatar = 'TEAM_MEMBER_AVATAR',
  TeamMemberPrompt = 'TEAM_MEMBER_PROMPT'
}

export type BrandAssetGroupData = {
  __typename?: 'BrandAssetGroupData';
  id: Scalars['ID']['output'];
  name: BrandAssetGroup;
};

export enum BrandAssetOrientation {
  Landscape = 'LANDSCAPE',
  Portrait = 'PORTRAIT',
  Square = 'SQUARE'
}

export type BrandAssetPlacement = {
  __typename?: 'BrandAssetPlacement';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  options?: Maybe<Scalars['String']['output']>;
  orientation: BrandAssetOrientation;
  placementId: Scalars['ID']['output'];
  source?: Maybe<Scalars['String']['output']>;
  sourceSet?: Maybe<Scalars['String']['output']>;
  width: BrandAssetWidth;
};


export type BrandAssetPlacementSourceArgs = {
  containerWidth: Scalars['Int']['input'];
};


export type BrandAssetPlacementSourceSetArgs = {
  containerWidth: Scalars['Int']['input'];
};

export enum BrandAssetType {
  Image = 'IMAGE',
  Vimeo = 'VIMEO'
}

export enum BrandAssetWidth {
  Full = 'FULL',
  OneHalf = 'ONE_HALF',
  OneThird = 'ONE_THIRD',
  TwoThirds = 'TWO_THIRDS'
}

export type Bundle = {
  __typename?: 'Bundle';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  productVariants: Array<Maybe<ProductVariant>>;
};

export type Candidate = {
  __typename?: 'Candidate';
  activated: Scalars['Boolean']['output'];
  atsProfileUrl?: Maybe<Scalars['String']['output']>;
  averageResponseTime?: Maybe<Scalars['Int']['output']>;
  companyQualitiesPreferences: Array<CompanyQualitiesPreference>;
  companySizePreferences: Array<CompanySizePreference>;
  companySubQualitiesPreferences: Array<CompanySubQualitiesPreference>;
  contextQuestionResponses: Array<CandidateContextQuestionResponse>;
  currentCompany?: Maybe<Company>;
  currentTitle?: Maybe<Scalars['String']['output']>;
  discardedJobs: Array<Job>;
  discardedSubFunctions: Array<JobSubFunction>;
  diverse: Scalars['Boolean']['output'];
  diversityMatch: Scalars['Boolean']['output'];
  educationExperiences: Array<UserEducationExperience>;
  email: Scalars['String']['output'];
  ethnicities: Array<Ethnicity>;
  externalId: Scalars['ID']['output'];
  firstName: Scalars['String']['output'];
  followedCompanies: Array<FollowedCompany>;
  genders: Array<Gender>;
  hasAccess: Array<AccessGrant>;
  hasConfirmedLocation?: Maybe<Scalars['Boolean']['output']>;
  hasSetPreferences?: Maybe<Scalars['Boolean']['output']>;
  hasSubmittedSalary: Scalars['Boolean']['output'];
  hiddenCompanies: Array<HiddenCompany>;
  id: Scalars['ID']['output'];
  insertedAt: Scalars['NaiveDateTime']['output'];
  jobApplications: Array<JobApplication>;
  jobEmailNotificationsFrequency: NotificationFrequency;
  jobExperiencePreference?: Maybe<JobExperiencePreference>;
  /** @deprecated Renamed to job_experience_preference */
  jobExperiencePreferences?: Maybe<UserJobExperiencePreferences>;
  jobFunctionPreferences: Array<JobFunctionPreference>;
  /** @deprecated Unused - see job reaction statistics */
  jobInteractionStatistics: JobInteractionStatistics;
  jobNotificationUnsubscription: Scalars['Boolean']['output'];
  jobPreferenceSuggestions?: Maybe<Array<JobPreferenceSuggestion>>;
  jobReactionStatistics: JobReactionStatistics;
  jobRecommendations: Array<JobRecommendation>;
  jobSubFunctionPreferences: Array<JobSubFunctionPreference>;
  jobsProcessed?: Maybe<Scalars['Int']['output']>;
  languageRequirementPreferences: Array<LanguageRequirementPreference>;
  lastActive: Scalars['NaiveDateTime']['output'];
  lastName: Scalars['String']['output'];
  lastUnconfirmedJobApplication?: Maybe<Scalars['String']['output']>;
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  locationPreferences: Array<LocationPreference>;
  marketingConsent: Scalars['Boolean']['output'];
  messageRequestsSentFromCompany: Array<CandidateMessageRequest>;
  /** @deprecated Use min_salary_requirement instead */
  minSalaryPreference?: Maybe<Scalars['Int']['output']>;
  minSalaryRequirement?: Maybe<Money>;
  mostRecentJobInterview?: Maybe<CandidateJobInterview>;
  numReferrals?: Maybe<Scalars['Int']['output']>;
  numUnreadShortlistedJobs: Scalars['Int']['output'];
  permissions: Array<PermissionObject>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profileCompletion: Array<ProfileCompletionComponent>;
  profileImagePath?: Maybe<Scalars['String']['output']>;
  profileInterests?: Maybe<Scalars['String']['output']>;
  projects: Array<UserProject>;
  pronoun?: Maybe<Pronoun>;
  receivedMessageRequests: Array<CandidateMessageRequest>;
  region?: Maybe<Scalars['String']['output']>;
  retained: Scalars['Boolean']['output'];
  role: RoleType;
  savedJobsAtCompany: Array<PublicJob>;
  savedSimilarCompanies: Array<PublicCompany>;
  searchStage?: Maybe<Scalars['String']['output']>;
  sectorTagPreferences: Array<SectorTagPreference>;
  shortlisted: Scalars['Boolean']['output'];
  shortlistedJobs: Array<Job>;
  showProfileToCompanies: Scalars['Boolean']['output'];
  showRequestAdvice?: Maybe<Scalars['Boolean']['output']>;
  signUpReasonResponses: Array<SignUpReasonResponse>;
  /** @deprecated Use region instead */
  signupRegion?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use subregion instead */
  signupSubregion?: Maybe<Scalars['String']['output']>;
  spotlightContent?: Maybe<SpotlightContent>;
  subregion?: Maybe<Scalars['String']['output']>;
  technologiesUsedPreferences: Array<TechnologyUsedPreference>;
  termsAndConditionsConsentedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  timezone?: Maybe<Timezone>;
  /** @deprecated Deprecated */
  topCandidate: Scalars['Boolean']['output'];
  totalDiscardedJobs?: Maybe<Scalars['Int']['output']>;
  totalJobApplicationClicks: Scalars['Int']['output'];
  totalJobApplicationConfirms: Scalars['Int']['output'];
  totalJobReactions: Scalars['Int']['output'];
  totalRemainingJobs: Scalars['Int']['output'];
  verifiedEmail: Scalars['Boolean']['output'];
  visaRequirementCountries: Array<VisaRequirementCountry>;
  websiteLinks: Array<WebsiteLink>;
  workExperiences: Array<UserWorkExperience>;
};


export type CandidateAtsProfileUrlArgs = {
  jobId: Scalars['ID']['input'];
};


export type CandidateDiscardedJobsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CandidateDiversityMatchArgs = {
  filters?: InputMaybe<PersonalisedCandidateFilters>;
};


export type CandidateHasAccessArgs = {
  features: Array<Feature>;
};


export type CandidateJobApplicationsArgs = {
  applied?: InputMaybe<Scalars['Boolean']['input']>;
  appliedAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  appliedAtLt?: InputMaybe<Scalars['DateTime']['input']>;
  externalOrSentToAts?: InputMaybe<Scalars['Boolean']['input']>;
  externalResponded?: InputMaybe<Array<ExternalResponded>>;
  internal?: InputMaybe<Scalars['Boolean']['input']>;
  orderByLeastResponseRateDatapoints?: InputMaybe<Scalars['Boolean']['input']>;
  stillApplied?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CandidateJobInteractionStatisticsArgs = {
  since: Scalars['NaiveDateTime']['input'];
};


export type CandidateJobRecommendationsArgs = {
  ignoreSubfunctions?: InputMaybe<Scalars['Boolean']['input']>;
  preferredSectorId?: InputMaybe<Scalars['ID']['input']>;
  themeId?: InputMaybe<ThemeId>;
};


export type CandidateJobsProcessedArgs = {
  since: Scalars['DateTime']['input'];
};


export type CandidateProfileImagePathArgs = {
  prefix?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CandidateShortlistedArgs = {
  jobId: Scalars['ID']['input'];
};


export type CandidateSpotlightContentArgs = {
  companyIds: Array<Scalars['String']['input']>;
};


export type CandidateTopCandidateArgs = {
  jobId: Scalars['ID']['input'];
};


export type CandidateTotalRemainingJobsArgs = {
  ignoreSubfunctions?: InputMaybe<Scalars['Boolean']['input']>;
  sectorTagIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type CandidateContextQuestion = {
  __typename?: 'CandidateContextQuestion';
  id: Scalars['ID']['output'];
  placeholder: Scalars['String']['output'];
  question: Scalars['String']['output'];
  response?: Maybe<CandidateContextQuestionResponse>;
};

export type CandidateContextQuestionResponse = {
  __typename?: 'CandidateContextQuestionResponse';
  featuredAnswer: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  question: CandidateContextQuestion;
  value: Scalars['String']['output'];
};

export type CandidateConversation = {
  __typename?: 'CandidateConversation';
  id: Scalars['ID']['output'];
  jobApplication?: Maybe<JobApplication>;
  latestMessage?: Maybe<CandidateConversationMessage>;
  messageRequest?: Maybe<CandidateMessageRequest>;
  messages: Array<CandidateConversationMessage>;
  qualified?: Maybe<Scalars['Boolean']['output']>;
  read: Scalars['Boolean']['output'];
};

export type CandidateConversationMessage = {
  __typename?: 'CandidateConversationMessage';
  candidateConversationId: Scalars['ID']['output'];
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  insertedAt: Scalars['NaiveDateTime']['output'];
  read: Scalars['Boolean']['output'];
  sender: User;
};

export type CandidateJobInterview = {
  __typename?: 'CandidateJobInterview';
  companySectorTags: Array<SectorTag>;
  jobSubFunction: JobSubFunction;
};

export type CandidateMessageRequest = {
  __typename?: 'CandidateMessageRequest';
  accepted?: Maybe<Scalars['Boolean']['output']>;
  candidate: Candidate;
  companyRecruiter: User;
  conversation?: Maybe<CandidateConversation>;
  id: Scalars['ID']['output'];
  insertedAt: Scalars['NaiveDateTime']['output'];
  job: Job;
  lastActionAt: Scalars['NaiveDateTime']['output'];
  latestConversationMessage?: Maybe<CandidateConversationMessage>;
};

export type CandidatePipelineItem = {
  __typename?: 'CandidatePipelineItem';
  candidate?: Maybe<PipelineCandidate>;
  candidateId?: Maybe<Scalars['ID']['output']>;
  candidatePipelineItemNote?: Maybe<CandidatePipelineItemNote>;
  conversation?: Maybe<CompanyConversation>;
  id: Scalars['ID']['output'];
  importedCandidate?: Maybe<ImportedCandidate>;
  importedCandidateId?: Maybe<Scalars['ID']['output']>;
  jobApplication?: Maybe<JobApplication>;
  latestMessage?: Maybe<ConversationMessage>;
  messageRequest?: Maybe<CandidateMessageRequest>;
  movedToStageAt: Scalars['NaiveDateTime']['output'];
  stageId: Scalars['ID']['output'];
  stageName: Scalars['String']['output'];
  stageType: StageType;
};

export type CandidatePipelineItemNote = {
  __typename?: 'CandidatePipelineItemNote';
  content: Scalars['String']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  insertedAt: Scalars['NaiveDateTime']['output'];
  lastUpdatedBy?: Maybe<User>;
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type CandidatePipelineStage = {
  __typename?: 'CandidatePipelineStage';
  candidatePipelineItems?: Maybe<Array<CandidatePipelineItem>>;
  deletedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  position: Scalars['String']['output'];
  stageType: StageType;
};

export type CandidatePipelineStagePartial = {
  __typename?: 'CandidatePipelineStagePartial';
  currentCandidateInStage: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  stageType: StageType;
};

/** The possible options for candidates related to a job */
export enum CandidateStatus {
  /** The candidate has applied to the job */
  Applied = 'APPLIED',
  /** The candidate has either applied to the job or been sourced through Otta */
  AppliedOrSourced = 'APPLIED_OR_SOURCED'
}

export type Catalogue = {
  __typename?: 'Catalogue';
  id: Scalars['ID']['output'];
  product?: Maybe<CatalogueProduct>;
};


export type CatalogueProductArgs = {
  id: CatalogueProductId;
};

export type CatalogueProduct = {
  __typename?: 'CatalogueProduct';
  id: CatalogueProductId;
  quote: CatalogueProductQuote;
};


export type CatalogueProductQuoteArgs = {
  quantity: Scalars['Int']['input'];
};

export enum CatalogueProductId {
  JobSlotMonthly = 'JOB_SLOT_MONTHLY',
  JobSlotYearly = 'JOB_SLOT_YEARLY'
}

export type CatalogueProductLineItem = {
  __typename?: 'CatalogueProductLineItem';
  id: Scalars['ID']['output'];
  netSubtotal: Scalars['Decimal']['output'];
  productId: CatalogueProductId;
  quantity: Scalars['Int']['output'];
};

export type CatalogueProductQuote = {
  __typename?: 'CatalogueProductQuote';
  currency: Currency;
  id: Scalars['ID']['output'];
  lineItems: Array<CatalogueProductQuoteItem>;
  netTotal: Scalars['Decimal']['output'];
};

export type CatalogueProductQuoteItem = CatalogueProductLineItem | CatalogueProductVolumeDiscount;

export type CatalogueProductVolumeDiscount = {
  __typename?: 'CatalogueProductVolumeDiscount';
  id: Scalars['ID']['output'];
  netSubtotal: Scalars['Decimal']['output'];
  productId: CatalogueProductId;
};

export type Choice = {
  __typename?: 'Choice';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ChoiceInput = {
  label: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type CommonUser = {
  __typename?: 'CommonUser';
  email: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  notification?: Maybe<Notification>;
  /** Gets opt-in information for the currently logged in user */
  optedIn?: Maybe<Scalars['Boolean']['output']>;
  termsAndConditionsConsentedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
};

export type Company = {
  __typename?: 'Company';
  acquisitionChannels: Array<AcquisitionChannel>;
  activeSubscriptions: Array<CompanySubscription>;
  additionalInfoBullets: Array<CompanyAdditionalInfoBullet>;
  /** @deprecated Use list_jobs instead */
  allJobs?: Maybe<Array<Job>>;
  allJobsStatistics: AllJobsStatistics;
  angelListUrl?: Maybe<Scalars['String']['output']>;
  articles: Array<CompanyArticle>;
  ats?: Maybe<CompanyAts>;
  atsApiKeys: Array<CompanyAtsApiKey>;
  atsAuthorizeUrl?: Maybe<Scalars['String']['output']>;
  atsIntegrationAuthType?: Maybe<AtsIntegrationAuthType>;
  atsWebhooks: Array<CompanyAtsWebhook>;
  authStrategies: Array<AuthStrategy>;
  bcorp: Scalars['Boolean']['output'];
  brandAssetGroups: Array<CompanyBrandAssetGroup>;
  /** @deprecated Use brand_asset_groups */
  brandAssetLinks: Array<CompanyBrandAssetLink>;
  /** @deprecated Use brand_asset_links */
  brandAssets: Array<CompanyBrandAsset>;
  changes: Array<CompanyChange>;
  companiesHouseName?: Maybe<Scalars['String']['output']>;
  companyJobOffers?: Maybe<Array<Maybe<JobOffers>>>;
  companyRecruiters: Array<CompanyRecruiter>;
  createdAt: Scalars['NaiveDateTime']['output'];
  crunchbaseUrl?: Maybe<Scalars['String']['output']>;
  currentCustomerPlans?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  dealroomId?: Maybe<Scalars['String']['output']>;
  diversityAuthorFullName?: Maybe<Scalars['String']['output']>;
  diversityAuthorProfileImagePath?: Maybe<Scalars['String']['output']>;
  diversityAuthorTitle?: Maybe<Scalars['String']['output']>;
  diversityInitiatives: Array<CompanyDiversityInitiative>;
  domainName?: Maybe<Scalars['String']['output']>;
  ebStatistics?: Maybe<EbStatistics>;
  employeeSurveys: Array<CompanyEmployeeSurvey>;
  externalId: Scalars['String']['output'];
  faviconPath?: Maybe<Scalars['String']['output']>;
  /** @deprecated Gone */
  featuredRecruiter?: Maybe<PublicCompanyRecruiter>;
  /** @deprecated Field no longer used */
  feedbackPercentile?: Maybe<Scalars['Decimal']['output']>;
  founders: Array<CompanyFounder>;
  fundingRounds: Array<CompanyFundingRound>;
  glassdoorRating?: Maybe<Scalars['Decimal']['output']>;
  glassdoorUrl?: Maybe<Scalars['String']['output']>;
  hasAtsIntegration: Scalars['Boolean']['output'];
  hasCompletedAtsIntegration: Scalars['Boolean']['output'];
  hqAddress?: Maybe<Scalars['String']['output']>;
  hubspotId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  integratedWithDealroom: Scalars['Boolean']['output'];
  investors: Array<Investor>;
  isCurrentPayingCustomer: Scalars['Boolean']['output'];
  isEligibleForOttaPro: Scalars['Boolean']['output'];
  isPro: Scalars['Boolean']['output'];
  jobApplications: Array<JobApplication>;
  jobLocations: Array<Location>;
  jobSlotData?: Maybe<CompanyJobSlotData>;
  /** @deprecated Unused */
  jobsWithFeedback: Array<Job>;
  jobsWithSalaryStats: JobsWithSalaryStats;
  lastWorkflowStatusChangeBy?: Maybe<User>;
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  listJobs: Array<Job>;
  liveJobs: Array<Job>;
  logoPath?: Maybe<Scalars['String']['output']>;
  marketBullets: Array<CompanyMarketBullet>;
  maxDaysInOffice?: Maybe<Scalars['Int']['output']>;
  minDaysInOffice?: Maybe<Scalars['Int']['output']>;
  mission?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  numberEmployeeSurveys?: Maybe<Scalars['Int']['output']>;
  numberEmployees?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Unused */
  numberHistoricalJobs: Scalars['Int']['output'];
  numberLiveJobs: Scalars['Int']['output'];
  numberLiveJobsWithSalary: Scalars['Int']['output'];
  numberOfEndorsements: Scalars['Int']['output'];
  numberOfUnactionedApplications: Scalars['Int']['output'];
  numberOfUnreadMessages: Scalars['Int']['output'];
  offices?: Maybe<Array<Maybe<CompanyOffice>>>;
  oldestLiveJobWithoutPublicSalary?: Maybe<Job>;
  onlyOnOtta: Scalars['Boolean']['output'];
  otherBenefits: Array<CompanyOtherBenefit>;
  ottaCertified: Scalars['Boolean']['output'];
  parsedHqAddress?: Maybe<Scalars['String']['output']>;
  paused?: Maybe<Scalars['Boolean']['output']>;
  peopleProgressing: Array<CompanyPersonProgressing>;
  percentageFemale?: Maybe<Scalars['Int']['output']>;
  photos: Array<Photo>;
  primaryColour?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use is_pro instead */
  proTier: Scalars['Boolean']['output'];
  products: Array<Product>;
  profileTypeName?: Maybe<ProfileType>;
  receivesApplicationsWithinOtta: Scalars['Boolean']['output'];
  recruiters: Array<CommonUser>;
  regionalBenefits: Array<CompanyRegionalBenefits>;
  responseCountByCategory: Array<CompanyEmployeeSurveyResponseCountForCategory>;
  responseRate?: Maybe<Scalars['Decimal']['output']>;
  rocketListEntries: Array<RocketListEntry>;
  sales: Array<CompanySale>;
  samlData?: Maybe<CompanySamlData>;
  scraperType?: Maybe<CompanyScraperType>;
  scraperUrl?: Maybe<Scalars['String']['output']>;
  sectorTags: Array<SectorTag>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  size?: Maybe<CompanySize>;
  statistics: CompanyStatistics;
  teams: Array<CompanyTeam>;
  totalFunding?: Maybe<Money>;
  totalJobReactions?: Maybe<Scalars['Int']['output']>;
  totalJobs: Scalars['Int']['output'];
  trackingCode?: Maybe<Scalars['String']['output']>;
  trackingCodeName?: Maybe<Scalars['String']['output']>;
  trustpilotRating?: Maybe<Scalars['Decimal']['output']>;
  trustpilotUrl?: Maybe<Scalars['String']['output']>;
  urlSafeName: Scalars['String']['output'];
  values: Array<CompanyValue>;
  visaSponsorshipCountries: Array<VisaSponsorshipCountry>;
  wasPro: Scalars['Boolean']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
  workflowStatus: CompanyWorkflowStatus;
  yearEmployeeGrowthPercentage?: Maybe<Scalars['Int']['output']>;
};


export type CompanyActiveSubscriptionsArgs = {
  catalogueProductIds: Array<CatalogueProductId>;
};


export type CompanyAllJobsArgs = {
  groupWorkflowStatus?: InputMaybe<GroupWorkflowStatus>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  workflowStatus?: InputMaybe<JobWorkflowStatus>;
};


export type CompanyAllJobsStatisticsArgs = {
  groupWorkflowStatus?: InputMaybe<GroupWorkflowStatus>;
  timePeriod?: InputMaybe<Scalars['DateTime']['input']>;
  workflowStatus?: InputMaybe<JobWorkflowStatus>;
};


export type CompanyBrandAssetGroupsArgs = {
  groups: Array<BrandAssetGroup>;
  type?: InputMaybe<BrandAssetType>;
};


export type CompanyBrandAssetLinksArgs = {
  group?: InputMaybe<BrandAssetGroup>;
  type?: InputMaybe<BrandAssetType>;
};


export type CompanyBrandAssetsArgs = {
  group?: InputMaybe<BrandAssetGroup>;
  type?: InputMaybe<BrandAssetType>;
};


export type CompanyDiversityAuthorProfileImagePathArgs = {
  prefix?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CompanyFaviconPathArgs = {
  prefix?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CompanyFundingRoundsArgs = {
  currency?: InputMaybe<Currency>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyInvestorsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  onlyTop?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CompanyJobApplicationsArgs = {
  applied?: InputMaybe<Scalars['Boolean']['input']>;
  appliedAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  appliedAtLt?: InputMaybe<Scalars['DateTime']['input']>;
  externalOrSentToAts?: InputMaybe<Scalars['Boolean']['input']>;
  externalResponded?: InputMaybe<Array<ExternalResponded>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyJobSlotDataArgs = {
  autoEnrol?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CompanyListJobsArgs = {
  acceptingApplications?: InputMaybe<Scalars['Boolean']['input']>;
  isPaused?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locations?: InputMaybe<Array<Location>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<Scalars['String']['input']>>;
  workflowStatuses?: InputMaybe<Array<JobWorkflowStatus>>;
};


export type CompanyLiveJobsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyLogoPathArgs = {
  prefix?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CompanySalesArgs = {
  onlyActive: Scalars['Boolean']['input'];
};


export type CompanyTotalFundingArgs = {
  currency?: InputMaybe<Currency>;
};


export type CompanyTotalJobsArgs = {
  isPaused?: InputMaybe<Scalars['Boolean']['input']>;
  locations?: InputMaybe<Array<Location>>;
  workflowStatuses?: InputMaybe<Array<JobWorkflowStatus>>;
};

export type CompanyAcquisitionChannelInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyAdditionalInfoBullet = {
  __typename?: 'CompanyAdditionalInfoBullet';
  id: Scalars['ID']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type CompanyArticle = {
  __typename?: 'CompanyArticle';
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type CompanyArticleInput = {
  title?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** The ATSs with which we integrate */
export enum CompanyAts {
  Ashby = 'ASHBY',
  Greenhouse = 'GREENHOUSE',
  Lever = 'LEVER',
  Workable = 'WORKABLE'
}

export type CompanyAtsApiKey = {
  __typename?: 'CompanyAtsApiKey';
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  type: CompanyAtsApiKeyTypeEnum;
};

/** The possible options for the api key type */
export enum CompanyAtsApiKeyTypeEnum {
  Ashby = 'ASHBY',
  GreenhouseHarvest = 'GREENHOUSE_HARVEST',
  GreenhouseJobBoard = 'GREENHOUSE_JOB_BOARD'
}

export type CompanyAtsWebhook = {
  __typename?: 'CompanyAtsWebhook';
  ats: CompanyAts;
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  lastRequestedAt?: Maybe<Scalars['DateTime']['output']>;
  requestedTopic?: Maybe<Scalars['String']['output']>;
  secretKey?: Maybe<Scalars['String']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
};

export type CompanyBenefit = {
  __typename?: 'CompanyBenefit';
  id: Scalars['ID']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type CompanyBrandAsset = {
  __typename?: 'CompanyBrandAsset';
  alt?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  links: Array<CompanyBrandAssetLink>;
  longTitle?: Maybe<Scalars['String']['output']>;
  shortTitle?: Maybe<Scalars['String']['output']>;
  source: Scalars['String']['output'];
  /** @deprecated Use placements */
  sourceSet?: Maybe<Scalars['String']['output']>;
  type: BrandAssetType;
};


export type CompanyBrandAssetSourceSetArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type CompanyBrandAssetGroup = {
  __typename?: 'CompanyBrandAssetGroup';
  group: BrandAssetGroupData;
  id: Scalars['ID']['output'];
  links: Array<CompanyBrandAssetLink>;
};

export type CompanyBrandAssetInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  longTitle?: InputMaybe<Scalars['String']['input']>;
  shortTitle?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<BrandAssetType>;
};

export type CompanyBrandAssetLink = {
  __typename?: 'CompanyBrandAssetLink';
  brandAssetGroup?: Maybe<BrandAssetGroupData>;
  companyBrandAsset: CompanyBrandAsset;
  companyOffice?: Maybe<CompanyOffice>;
  companyTeam?: Maybe<CompanyTeam>;
  companyTeamMember?: Maybe<CompanyTeamMember>;
  desktopPlacement?: Maybe<BrandAssetPlacement>;
  id: Scalars['ID']['output'];
  job?: Maybe<Job>;
  mobilePlacement?: Maybe<BrandAssetPlacement>;
  /** @deprecated Use placements */
  options?: Maybe<Scalars['String']['output']>;
  priority: Scalars['Int']['output'];
  region?: Maybe<Region>;
};

export type CompanyBrandAssetLinkInput = {
  brandAssetGroupName?: InputMaybe<Scalars['ID']['input']>;
  companyBrandAssetId?: InputMaybe<Scalars['ID']['input']>;
  companyOfficeId?: InputMaybe<Scalars['ID']['input']>;
  companyTeamId?: InputMaybe<Scalars['ID']['input']>;
  companyTeamMemberId?: InputMaybe<Scalars['ID']['input']>;
  desktopPlacementId?: InputMaybe<Scalars['ID']['input']>;
  jobId?: InputMaybe<Scalars['ID']['input']>;
  mobilePlacementId?: InputMaybe<Scalars['ID']['input']>;
  options?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  regionId?: InputMaybe<Scalars['ID']['input']>;
};

export type CompanyChange = {
  __typename?: 'CompanyChange';
  dismissedAt?: Maybe<Scalars['DateTime']['output']>;
  fieldName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  insertedAt: Scalars['NaiveDateTime']['output'];
  newValue?: Maybe<CompanyChangeValue>;
  oldValue?: Maybe<CompanyChangeValue>;
  user?: Maybe<User>;
};

export type CompanyChangeValue = BooleanValue | DatetimeValue | DecimalValue | IntegerValue | StringListValue | StringValue;

export type CompanyConversation = {
  __typename?: 'CompanyConversation';
  appliedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  archived: Scalars['Boolean']['output'];
  closed: Scalars['Boolean']['output'];
  conversationId?: Maybe<Scalars['ID']['output']>;
  favourited: Scalars['Boolean']['output'];
  hasAcceptedRequest?: Maybe<Scalars['Boolean']['output']>;
  job: Job;
  jobApplication?: Maybe<JobApplication>;
  jobApplicationId?: Maybe<Scalars['ID']['output']>;
  latestMessage?: Maybe<ConversationMessage>;
  messages: Array<ConversationMessage>;
  recipient: User;
  sourced: Scalars['Boolean']['output'];
  sourcedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
};

/** Customer portal session */
export type CompanyCustomerPortalSession = {
  __typename?: 'CompanyCustomerPortalSession';
  url: Scalars['String']['output'];
};

export type CompanyDiversityInitiative = {
  __typename?: 'CompanyDiversityInitiative';
  id: Scalars['ID']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  value: Scalars['String']['output'];
};

export type CompanyDiversityInitiativeInput = {
  order?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['String']['input'];
};

export type CompanyEmployeeSurvey = {
  __typename?: 'CompanyEmployeeSurvey';
  company: Company;
  id: Scalars['ID']['output'];
  isApproved?: Maybe<Scalars['Boolean']['output']>;
  jobFunction: JobFunction;
  responses: Array<CompanyEmployeeSurveyCategoryResponse>;
  submittedAt?: Maybe<Scalars['Date']['output']>;
};

export type CompanyEmployeeSurveyCategoryResponse = {
  __typename?: 'CompanyEmployeeSurveyCategoryResponse';
  category: CompanySubQuality;
  content?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type CompanyEmployeeSurveyCategoryResponseInput = {
  categoryId: Scalars['ID']['input'];
  content?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyEmployeeSurveyResponseCountForCategory = {
  __typename?: 'CompanyEmployeeSurveyResponseCountForCategory';
  categoryName: Scalars['String']['output'];
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
};

export type CompanyFounder = {
  __typename?: 'CompanyFounder';
  bio?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Gender>;
  id: Scalars['ID']['output'];
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type CompanyFounderInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyFundingRound = {
  __typename?: 'CompanyFundingRound';
  funding: FundingAmount;
  id: Scalars['ID']['output'];
  month: Scalars['Int']['output'];
  round?: Maybe<Scalars['String']['output']>;
  year: Scalars['Int']['output'];
};

export type CompanyFundingRoundInput = {
  funding?: InputMaybe<FundingAmountInput>;
  month?: InputMaybe<Scalars['Int']['input']>;
  round?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyInput = {
  acquisitionChannels?: InputMaybe<Array<CompanyAcquisitionChannelInput>>;
  angelListUrl?: InputMaybe<Scalars['String']['input']>;
  articles?: InputMaybe<Array<CompanyArticleInput>>;
  bcorp?: InputMaybe<Scalars['Boolean']['input']>;
  companiesHouseName?: InputMaybe<Scalars['String']['input']>;
  crunchbaseUrl?: InputMaybe<Scalars['String']['input']>;
  dealroomId?: InputMaybe<Scalars['String']['input']>;
  diversityAuthorFullName?: InputMaybe<Scalars['String']['input']>;
  diversityAuthorProfileImagePath?: InputMaybe<Scalars['String']['input']>;
  diversityAuthorTitle?: InputMaybe<Scalars['String']['input']>;
  diversityInitiatives?: InputMaybe<Array<CompanyDiversityInitiativeInput>>;
  faviconPath?: InputMaybe<Scalars['String']['input']>;
  founders?: InputMaybe<Array<CompanyFounderInput>>;
  fundingRounds?: InputMaybe<Array<CompanyFundingRoundInput>>;
  glassdoorRating?: InputMaybe<Scalars['Decimal']['input']>;
  glassdoorUrl?: InputMaybe<Scalars['String']['input']>;
  hqAddress?: InputMaybe<Scalars['String']['input']>;
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  investors?: InputMaybe<Array<InvestorInput>>;
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  logoPath?: InputMaybe<Scalars['String']['input']>;
  maxDaysInOffice?: InputMaybe<Scalars['Int']['input']>;
  minDaysInOffice?: InputMaybe<Scalars['Int']['input']>;
  mission?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  numberEmployees?: InputMaybe<Scalars['Int']['input']>;
  otherBenefits?: InputMaybe<Array<CompanyOtherBenefitInput>>;
  parsedHqAddress?: InputMaybe<Scalars['String']['input']>;
  paused?: InputMaybe<Scalars['Boolean']['input']>;
  peopleProgressing?: InputMaybe<Array<CompanyPersonProgressingInput>>;
  percentageFemale?: InputMaybe<Scalars['Int']['input']>;
  photos?: InputMaybe<Array<PhotoInput>>;
  primaryColour?: InputMaybe<Scalars['String']['input']>;
  scraperType?: InputMaybe<CompanyScraperType>;
  scraperUrl?: InputMaybe<Scalars['String']['input']>;
  sectorTags?: InputMaybe<Array<SectorTagInput>>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  totalFunding?: InputMaybe<MoneyInput>;
  trackingCode?: InputMaybe<Scalars['String']['input']>;
  trackingCodeName?: InputMaybe<Scalars['String']['input']>;
  trustpilotRating?: InputMaybe<Scalars['Decimal']['input']>;
  trustpilotUrl?: InputMaybe<Scalars['String']['input']>;
  values?: InputMaybe<Array<CompanyValueInput>>;
  visaSponsorshipCountries?: InputMaybe<Array<VisaSponsorshipCountryInput>>;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
  yearEmployeeGrowthPercentage?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyJobApplicationInput = {
  accepted: Scalars['Boolean']['input'];
  message: Scalars['String']['input'];
};

export type CompanyJobInput = {
  applicationProcessBullets?: InputMaybe<Array<JobApplicationProcessBulletInput>>;
  companyTeamId?: InputMaybe<Scalars['ID']['input']>;
  editedTitle?: InputMaybe<Scalars['String']['input']>;
  functionId?: InputMaybe<Scalars['ID']['input']>;
  hideSalary?: InputMaybe<Scalars['Boolean']['input']>;
  internship?: InputMaybe<Scalars['Boolean']['input']>;
  involvesBullets?: InputMaybe<Array<JobInvolvesBulletInput>>;
  languageRequirements?: InputMaybe<Array<JobLanguageRequirementInput>>;
  locationInformation?: InputMaybe<Scalars['String']['input']>;
  locationPreferences?: InputMaybe<Array<LocationPreferenceInput>>;
  locationRestrictions?: InputMaybe<Scalars['String']['input']>;
  maxDaysInOffice?: InputMaybe<Scalars['Int']['input']>;
  maxYearsExperienceRequired?: InputMaybe<Scalars['Int']['input']>;
  minDaysInOffice?: InputMaybe<Scalars['Int']['input']>;
  minYearsExperienceRequired?: InputMaybe<Scalars['Int']['input']>;
  officeLocation?: InputMaybe<Scalars['String']['input']>;
  oteSalaryRange?: InputMaybe<MoneyRangeInput>;
  otherCompensationInformation?: InputMaybe<Scalars['String']['input']>;
  requirements?: InputMaybe<Array<JobRequirementInput>>;
  salaryRange?: InputMaybe<MoneyRangeInput>;
  subFunctionId?: InputMaybe<Scalars['ID']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  technologiesUsed?: InputMaybe<Array<JobTechnologyUsedInput>>;
};

export type CompanyJobSlotData = {
  __typename?: 'CompanyJobSlotData';
  activeFrom: Scalars['DateTime']['output'];
  available: Scalars['Int']['output'];
  cohort: Scalars['String']['output'];
  experimentPhase: CompanyJobSlotExperimentPhase;
  free: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  paid: Scalars['Int']['output'];
  required: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum CompanyJobSlotExperimentPhase {
  Active = 'ACTIVE',
  Warning = 'WARNING'
}

export type CompanyMarketBullet = {
  __typename?: 'CompanyMarketBullet';
  id: Scalars['ID']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type CompanyOffice = {
  __typename?: 'CompanyOffice';
  address: Scalars['String']['output'];
  brandAssetLinks: Array<CompanyBrandAssetLink>;
  id: Scalars['ID']['output'];
  isHq: Scalars['Boolean']['output'];
  /** @deprecated Use map_image_urls instead */
  mapImageUrl: Scalars['String']['output'];
  mapImageUrls: MapImageUrls;
  parsedAddress?: Maybe<Scalars['String']['output']>;
};


export type CompanyOfficeBrandAssetLinksArgs = {
  group: BrandAssetGroup;
  type?: InputMaybe<BrandAssetType>;
};

export type CompanyOfficeInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  isHq?: InputMaybe<Scalars['Boolean']['input']>;
  parsedAddress?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyOtherBenefit = {
  __typename?: 'CompanyOtherBenefit';
  id: Scalars['ID']['output'];
  location?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CompanyOtherBenefitInput = {
  location?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyPersonProgressing = {
  __typename?: 'CompanyPersonProgressing';
  bio?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type CompanyPersonProgressingInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyQualitiesPreference = {
  __typename?: 'CompanyQualitiesPreference';
  companyQualityId: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type CompanyQualitiesPreferenceInput = {
  companyQualityId: Scalars['ID']['input'];
};

export type CompanyQuality = {
  __typename?: 'CompanyQuality';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type CompanyRecruiter = {
  __typename?: 'CompanyRecruiter';
  email: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastActive?: Maybe<Scalars['NaiveDateTime']['output']>;
  lastName: Scalars['String']['output'];
  notification?: Maybe<Notification>;
  /** Gets opt-in information for the currently logged in user */
  optedIn?: Maybe<Scalars['Boolean']['output']>;
  profileImagePath?: Maybe<Scalars['String']['output']>;
  role: CompanyRoleType;
  termsAndConditionsConsentedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  /** @deprecated Deprecated in favour of hasAccess on Job */
  viewSalaryForJob: Scalars['Boolean']['output'];
};


export type CompanyRecruiterProfileImagePathArgs = {
  prefix?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CompanyRecruiterViewSalaryForJobArgs = {
  jobId: Scalars['ID']['input'];
};

export type CompanyRegionalBenefits = {
  __typename?: 'CompanyRegionalBenefits';
  benefits: Array<CompanyBenefit>;
  brandAssetLinks: Array<CompanyBrandAssetLink>;
  /** @deprecated Use brand_asset_links */
  brandAssets: Array<CompanyBrandAsset>;
  id: Scalars['ID']['output'];
  region?: Maybe<Region>;
};


export type CompanyRegionalBenefitsBrandAssetLinksArgs = {
  group?: InputMaybe<BrandAssetGroup>;
  type?: InputMaybe<BrandAssetType>;
};


export type CompanyRegionalBenefitsBrandAssetsArgs = {
  group: BrandAssetGroup;
  type: BrandAssetType;
};

/** The possible options for the role of a user */
export enum CompanyRoleType {
  /** The company recruiter has admin access */
  Admin = 'ADMIN',
  /** The company recruiter only has member access */
  Member = 'MEMBER'
}

export type CompanySale = {
  __typename?: 'CompanySale';
  bundle: Bundle;
  id: Scalars['ID']['output'];
  saleProductVariants: Array<CompanySaleProductVariant>;
  type: CompanySaleType;
  updatedBy: User;
};

export type CompanySaleInput = {
  bundleId: Scalars['ID']['input'];
  products?: InputMaybe<Array<CompanySaleProductInput>>;
  regions?: InputMaybe<Array<Scalars['ID']['input']>>;
  type: CompanySaleType;
  validFrom?: InputMaybe<Scalars['NaiveDateTime']['input']>;
  validUntil?: InputMaybe<Scalars['NaiveDateTime']['input']>;
};

export type CompanySaleProductInput = {
  productVariantId: Scalars['ID']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
  validFrom?: InputMaybe<Scalars['NaiveDateTime']['input']>;
  validUntil?: InputMaybe<Scalars['NaiveDateTime']['input']>;
};

export type CompanySaleProductVariant = {
  __typename?: 'CompanySaleProductVariant';
  id: Scalars['ID']['output'];
  productVariant: ProductVariant;
  quantity?: Maybe<Scalars['Int']['output']>;
  updatedBy: User;
  validFrom: Scalars['NaiveDateTime']['output'];
  validUntil?: Maybe<Scalars['NaiveDateTime']['output']>;
};

export enum CompanySaleTerminationType {
  Cancel = 'CANCEL',
  Delete = 'DELETE'
}

export enum CompanySaleType {
  Demo = 'DEMO',
  Purchase = 'PURCHASE',
  Trial = 'TRIAL'
}

export type CompanySamlData = {
  __typename?: 'CompanySamlData';
  consumeUrl: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  enforced: Scalars['Boolean']['output'];
  entityId: Scalars['String']['output'];
  hasIdpCerts: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  idpEntityId?: Maybe<Scalars['String']['output']>;
  idpLoginUrl?: Maybe<Scalars['String']['output']>;
  idpMetadata?: Maybe<Scalars['String']['output']>;
  loginUrl: Scalars['String']['output'];
  metadataUrl: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

export type CompanySamlDataInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  enforced?: InputMaybe<Scalars['Boolean']['input']>;
  idpMetadata?: InputMaybe<Scalars['String']['input']>;
};

/** Whether a company's jobs are scraped or internal */
export enum CompanyScraperType {
  Manual = 'MANUAL',
  Scraped = 'SCRAPED'
}

export type CompanySize = {
  __typename?: 'CompanySize';
  id: Scalars['ID']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type CompanySizePreference = {
  __typename?: 'CompanySizePreference';
  companySize: CompanySize;
  companySizeId: Scalars['ID']['output'];
};

export type CompanySizePreferenceInput = {
  companySizeId: Scalars['ID']['input'];
};

export type CompanyStatistics = {
  __typename?: 'CompanyStatistics';
  id: Scalars['ID']['output'];
  jobs: Array<JobWithStatistics>;
};

export type CompanySubQualitiesPreference = {
  __typename?: 'CompanySubQualitiesPreference';
  companySubQualityId: Scalars['ID']['output'];
};

export type CompanySubQuality = {
  __typename?: 'CompanySubQuality';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  placeholderText: Scalars['String']['output'];
};

export type CompanySubscription = {
  __typename?: 'CompanySubscription';
  amendmentQuote: CompanySubscriptionAmendmentQuote;
  cancelAt?: Maybe<Scalars['DateTime']['output']>;
  catalogueProductId: CatalogueProductId;
  id: Scalars['ID']['output'];
  price: CompanySubscriptionPrice;
  quantity: Scalars['Int']['output'];
};


export type CompanySubscriptionAmendmentQuoteArgs = {
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanySubscriptionAmendmentQuote = {
  __typename?: 'CompanySubscriptionAmendmentQuote';
  id: Scalars['ID']['output'];
  nextChargeDate?: Maybe<Scalars['Date']['output']>;
  nextChargePrice?: Maybe<CompanySubscriptionPrice>;
  priceIncrease?: Maybe<CompanySubscriptionPrice>;
  proRataAdjustment: CompanySubscriptionPrice;
};

export type CompanySubscriptionCheckoutSession = {
  __typename?: 'CompanySubscriptionCheckoutSession';
  url: Scalars['String']['output'];
};

export enum CompanySubscriptionPortalAction {
  PaymentMethodUpdate = 'PAYMENT_METHOD_UPDATE',
  SubscriptionCancel = 'SUBSCRIPTION_CANCEL',
  SubscriptionUpdate = 'SUBSCRIPTION_UPDATE'
}

export type CompanySubscriptionPrice = {
  __typename?: 'CompanySubscriptionPrice';
  currency: Currency;
  id: Scalars['ID']['output'];
  net: Scalars['Decimal']['output'];
};

export type CompanyTeam = {
  __typename?: 'CompanyTeam';
  brandAssetLinks: Array<CompanyBrandAssetLink>;
  /** @deprecated Use brand_asset_links */
  brandAssets: Array<CompanyBrandAsset>;
  id: Scalars['ID']['output'];
  members: Array<CompanyTeamMember>;
  mission?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  size?: Maybe<Scalars['Int']['output']>;
};


export type CompanyTeamBrandAssetLinksArgs = {
  group: BrandAssetGroup;
  type?: InputMaybe<BrandAssetType>;
};


export type CompanyTeamBrandAssetsArgs = {
  group: BrandAssetGroup;
  type: BrandAssetType;
};

export type CompanyTeamInput = {
  members?: InputMaybe<Array<CompanyTeamMemberInput>>;
  mission?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyTeamMember = {
  __typename?: 'CompanyTeamMember';
  brandAssetLinks: Array<CompanyBrandAssetLink>;
  /** @deprecated Use brand_asset_links */
  brandAssets: Array<CompanyBrandAsset>;
  id: Scalars['ID']['output'];
  jobTitle: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  prompts?: Maybe<Array<CompanyTeamMemberPrompt>>;
};


export type CompanyTeamMemberBrandAssetLinksArgs = {
  group: BrandAssetGroup;
  type: BrandAssetType;
};


export type CompanyTeamMemberBrandAssetsArgs = {
  group: BrandAssetGroup;
  type: BrandAssetType;
};

export type CompanyTeamMemberInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  jobTitle: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  prompts?: InputMaybe<Array<CompanyTeamMemberPromptInput>>;
};

export type CompanyTeamMemberPrompt = {
  __typename?: 'CompanyTeamMemberPrompt';
  answer: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  question: Scalars['String']['output'];
};

export type CompanyTeamMemberPromptInput = {
  answer: Scalars['String']['input'];
  question: Scalars['String']['input'];
};

export type CompanyValue = {
  __typename?: 'CompanyValue';
  id: Scalars['ID']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CompanyValueInput = {
  order?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** The workflow status of a company */
export enum CompanyWorkflowStatus {
  /** A company that is a good fit for the platform */
  Approved = 'APPROVED',
  /** A company which is not to be shown or completed. */
  Archived = 'ARCHIVED',
  /** A completed company which needs reviewing. */
  Changed = 'CHANGED',
  /** A completed company which can be shown on the platform. */
  Completed = 'COMPLETED',
  /** A completed company by a company recruiter which can be shown on the platform. */
  CompletedByUser = 'COMPLETED_BY_USER',
  /** A company that has requested access to the platform. */
  PreApproval = 'PRE_APPROVAL',
  /** A company that is a may be a good fit for the platform but are having a sales call */
  PushedToPro = 'PUSHED_TO_PRO',
  /** A company ready to be QA'd internally. */
  QaInternal = 'QA_INTERNAL',
  /** A company ready to be completed. */
  Queued = 'QUEUED'
}

export type ConsentAtsQuestion = {
  __typename?: 'ConsentAtsQuestion';
  answer?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  localId: Scalars['ID']['output'];
  required: Scalars['Boolean']['output'];
};

export type Conversation = {
  __typename?: 'Conversation';
  closed: Scalars['Boolean']['output'];
  hasAcceptedRequest?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  job: Job;
  jobApplication?: Maybe<JobApplication>;
  latestMessage: ConversationMessage;
  messages: Array<ConversationMessage>;
  sourced: Scalars['Boolean']['output'];
};

export type ConversationMessage = {
  __typename?: 'ConversationMessage';
  candidateConversationId: Scalars['ID']['output'];
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  insertedAt: Scalars['NaiveDateTime']['output'];
  read: Scalars['Boolean']['output'];
  sender: User;
};

/** The possible options for a message stage */
export enum ConversationStage {
  /** Messages between a recruiter and a candidate who has sent an application. */
  Applied = 'APPLIED',
  /** Messages between a recruiter and a candidate who has been moved to the qualified stage, either from sourced or applied. */
  Qualified = 'QUALIFIED',
  /** Messages between a recruiter and a sourced candidate. */
  Sourced = 'SOURCED'
}

export enum CreationMethod {
  Admin = 'ADMIN',
  Ats = 'ATS',
  Candidate = 'CANDIDATE',
  CompanyRecruiter = 'COMPANY_RECRUITER'
}

/** Supported currencies */
export enum Currency {
  Cad = 'CAD',
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD'
}

export type CurrentAdmin = CurrentUser & {
  __typename?: 'CurrentAdmin';
  activated: Scalars['Boolean']['output'];
  companyConversations: Array<CompanyConversation>;
  conversations: Array<Conversation>;
  currentCompany?: Maybe<Company>;
  email: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  hasUnactionedMessages: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isFirstLogin?: Maybe<Scalars['Boolean']['output']>;
  jobsProcessed?: Maybe<Scalars['Int']['output']>;
  lastActive?: Maybe<Scalars['NaiveDateTime']['output']>;
  lastName: Scalars['String']['output'];
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  notification?: Maybe<Notification>;
  numUnreadShortlistedJobs?: Maybe<Scalars['Int']['output']>;
  /** Gets opt-in information for the currently logged in user */
  optedIn?: Maybe<Scalars['Boolean']['output']>;
  permissions: Array<Scalars['String']['output']>;
  profileImagePath?: Maybe<Scalars['String']['output']>;
  profileStatus?: Maybe<ProfileStatus>;
  role: CompanyRoleType;
  searchStage?: Maybe<Scalars['String']['output']>;
  showProfileToCompanies?: Maybe<Scalars['Boolean']['output']>;
  termsAndConditionsConsentedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  themedBatchCount: Scalars['Int']['output'];
  topSector?: Maybe<SectorTag>;
  verifiedEmail: Scalars['Boolean']['output'];
  /** @deprecated Deprecated in favour of hasAccess on Job */
  viewSalaryForJob: Scalars['Boolean']['output'];
  workExperiences: Array<UserWorkExperience>;
};


export type CurrentAdminCompanyConversationsArgs = {
  allMessages?: InputMaybe<Scalars['Boolean']['input']>;
  applied?: InputMaybe<Scalars['Boolean']['input']>;
  favourites?: InputMaybe<Scalars['Boolean']['input']>;
  jobId?: InputMaybe<Scalars['ID']['input']>;
  limit: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  offset: Scalars['Int']['input'];
  onlyArchivedMessages?: InputMaybe<Scalars['Boolean']['input']>;
  onlyAwaitingResponse?: InputMaybe<Scalars['Boolean']['input']>;
  sourced?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CurrentAdminJobsProcessedArgs = {
  since: Scalars['DateTime']['input'];
};


export type CurrentAdminProfileImagePathArgs = {
  prefix?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CurrentAdminThemedBatchCountArgs = {
  preferredSectorId?: InputMaybe<Scalars['ID']['input']>;
  themeId: ThemeId;
};


export type CurrentAdminViewSalaryForJobArgs = {
  jobId: Scalars['ID']['input'];
};

export type CurrentCandidate = CurrentUser & {
  __typename?: 'CurrentCandidate';
  conversations: Array<Conversation>;
  email: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  hasUnactionedMessages: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  notification?: Maybe<Notification>;
  numUnreadShortlistedJobs?: Maybe<Scalars['Int']['output']>;
  /** Gets opt-in information for the currently logged in user */
  optedIn?: Maybe<Scalars['Boolean']['output']>;
  profileStatus?: Maybe<ProfileStatus>;
  searchStage?: Maybe<Scalars['String']['output']>;
  showProfileToCompanies?: Maybe<Scalars['Boolean']['output']>;
  termsAndConditionsConsentedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  themedBatchCount: Scalars['Int']['output'];
  topSector?: Maybe<SectorTag>;
  workExperiences: Array<UserWorkExperience>;
};


export type CurrentCandidateThemedBatchCountArgs = {
  preferredSectorId?: InputMaybe<Scalars['ID']['input']>;
  themeId: ThemeId;
};

export type CurrentCompanyRecruiter = CurrentUser & {
  __typename?: 'CurrentCompanyRecruiter';
  activated: Scalars['Boolean']['output'];
  companyConversations: Array<CompanyConversation>;
  currentCompany?: Maybe<Company>;
  email: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isFirstLogin?: Maybe<Scalars['Boolean']['output']>;
  lastActive?: Maybe<Scalars['NaiveDateTime']['output']>;
  lastName: Scalars['String']['output'];
  notification?: Maybe<Notification>;
  /** Gets opt-in information for the currently logged in user */
  optedIn?: Maybe<Scalars['Boolean']['output']>;
  profileImagePath?: Maybe<Scalars['String']['output']>;
  role: CompanyRoleType;
  termsAndConditionsConsentedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  verifiedEmail: Scalars['Boolean']['output'];
  /** @deprecated Deprecated in favour of hasAccess on Job */
  viewSalaryForJob: Scalars['Boolean']['output'];
};


export type CurrentCompanyRecruiterCompanyConversationsArgs = {
  allMessages?: InputMaybe<Scalars['Boolean']['input']>;
  applied?: InputMaybe<Scalars['Boolean']['input']>;
  favourites?: InputMaybe<Scalars['Boolean']['input']>;
  jobId?: InputMaybe<Scalars['ID']['input']>;
  limit: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  offset: Scalars['Int']['input'];
  onlyArchivedMessages?: InputMaybe<Scalars['Boolean']['input']>;
  onlyAwaitingResponse?: InputMaybe<Scalars['Boolean']['input']>;
  sourced?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CurrentCompanyRecruiterProfileImagePathArgs = {
  prefix?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CurrentCompanyRecruiterViewSalaryForJobArgs = {
  jobId: Scalars['ID']['input'];
};

export type CurrentExternalResearcher = CurrentUser & {
  __typename?: 'CurrentExternalResearcher';
  email: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  notification?: Maybe<Notification>;
  /** Gets opt-in information for the currently logged in user */
  optedIn?: Maybe<Scalars['Boolean']['output']>;
  termsAndConditionsConsentedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
};

export type CurrentInternalOperator = CurrentUser & {
  __typename?: 'CurrentInternalOperator';
  email: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  jobsProcessed?: Maybe<Scalars['Int']['output']>;
  lastName: Scalars['String']['output'];
  notification?: Maybe<Notification>;
  /** Gets opt-in information for the currently logged in user */
  optedIn?: Maybe<Scalars['Boolean']['output']>;
  permissions: Array<Scalars['String']['output']>;
  termsAndConditionsConsentedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
};


export type CurrentInternalOperatorJobsProcessedArgs = {
  since: Scalars['DateTime']['input'];
};

export type CurrentUser = {
  email: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  notification?: Maybe<Notification>;
  /** Gets opt-in information for the currently logged in user */
  optedIn?: Maybe<Scalars['Boolean']['output']>;
  termsAndConditionsConsentedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
};

export type DateAnswer = {
  __typename?: 'DateAnswer';
  questionData: AnswerQuestionData;
  value: Scalars['Date']['output'];
};

export type DateAnswerInput = {
  questionData: QuestionDataInput;
  value: Scalars['Date']['input'];
};

export type DateQuestion = {
  __typename?: 'DateQuestion';
  atsId?: Maybe<Scalars['String']['output']>;
  question: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
};

export type DateResponse = {
  __typename?: 'DateResponse';
  date: Scalars['Date']['output'];
};

export type DateTimeAtsQuestion = {
  __typename?: 'DateTimeAtsQuestion';
  answer?: Maybe<Scalars['String']['output']>;
  default?: Maybe<Scalars['String']['output']>;
  format: AtsDateFormat;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  localId: Scalars['ID']['output'];
  maximumAnswers: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
};

export type DatetimeValue = {
  __typename?: 'DatetimeValue';
  value: Scalars['DateTime']['output'];
};

export type DecimalAnswer = {
  __typename?: 'DecimalAnswer';
  questionData: AnswerQuestionData;
  value: Scalars['Decimal']['output'];
};

export type DecimalAnswerInput = {
  questionData: QuestionDataInput;
  value: Scalars['Decimal']['input'];
};

export type DecimalQuestion = {
  __typename?: 'DecimalQuestion';
  atsId?: Maybe<Scalars['String']['output']>;
  question: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
};

export type DecimalResponse = {
  __typename?: 'DecimalResponse';
  value: Scalars['Decimal']['output'];
};

export type DecimalValue = {
  __typename?: 'DecimalValue';
  value: Scalars['Decimal']['output'];
};

/** The types of device platform the mobile app is available on */
export enum DevicePlatform {
  Android = 'ANDROID',
  Ios = 'IOS'
}

export type DislikedSectorPreferenceSuggestion = JobPreferenceSuggestion & {
  __typename?: 'DislikedSectorPreferenceSuggestion';
  id: Scalars['ID']['output'];
  sectorTag: SectorTag;
};

export type DislikedTechnologyPreferenceSuggestion = JobPreferenceSuggestion & {
  __typename?: 'DislikedTechnologyPreferenceSuggestion';
  id: Scalars['ID']['output'];
  technology: JobTechnologyUsed;
};

/** Employer branding statistics */
export type EbStatistics = {
  __typename?: 'EbStatistics';
  id: Scalars['ID']['output'];
  peopleBreakdown: PeopleBreakdownStatistics;
  updatedAt: Scalars['NaiveDateTime']['output'];
};

export type EbSubsetStatistic = {
  __typename?: 'EbSubsetStatistic';
  id: Scalars['ID']['output'];
  percent: Scalars['Decimal']['output'];
  total: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type EducationExperienceDescription = {
  __typename?: 'EducationExperienceDescription';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type EducationExperienceDescriptionInput = {
  value: Scalars['String']['input'];
};

export type Ethnicity = {
  __typename?: 'Ethnicity';
  ethnicity: EthnicityEnum;
  id: Scalars['ID']['output'];
};

/** The possible options for ethnicity */
export enum EthnicityEnum {
  /** @deprecated Use east_asian, south_asian, or southeast_asian */
  Asian = 'ASIAN',
  /** Black or African American */
  Black = 'BLACK',
  EastAsian = 'EAST_ASIAN',
  Hispanic = 'HISPANIC',
  IndigenousPeoples = 'INDIGENOUS_PEOPLES',
  MiddleEastern = 'MIDDLE_EASTERN',
  /** Multiracial / Multiethnic */
  Mixed = 'MIXED',
  NativeHawaiian = 'NATIVE_HAWAIIAN',
  Other = 'OTHER',
  PreferNotToSay = 'PREFER_NOT_TO_SAY',
  SoutheastAsian = 'SOUTHEAST_ASIAN',
  SouthAsian = 'SOUTH_ASIAN',
  White = 'WHITE'
}

export type EthnicityInput = {
  ethnicity: EthnicityEnum;
};

export type ExistingFileAnswerInput = {
  name: Scalars['String']['input'];
  questionData: QuestionDataInput;
  url: Scalars['String']['input'];
};

/** The possible options for an experience level */
export enum ExperienceLevel {
  /** Entry level experience */
  EntryLevel = 'ENTRY_LEVEL',
  /** Five to eight years experience level */
  FiveToEightYears = 'FIVE_TO_EIGHT_YEARS',
  /** Internship experience level */
  Internship = 'INTERNSHIP',
  /** Nine plus years experience level */
  NinePlusYears = 'NINE_PLUS_YEARS',
  /** One to two years experience level */
  OneToTwoYears = 'ONE_TO_TWO_YEARS',
  /** Three to four years experience level */
  ThreeToFourYears = 'THREE_TO_FOUR_YEARS'
}

export type ExperienceRange = {
  maxYearsExperienceRequired: Scalars['Int']['input'];
  minYearsExperienceRequired: Scalars['Int']['input'];
};

/** Options for external responded */
export enum ExternalResponded {
  No = 'NO',
  Unanswered = 'UNANSWERED',
  Yes = 'YES'
}

/** Available features */
export enum Feature {
  AtsIntegration = 'ATS_INTEGRATION',
  Boost = 'BOOST',
  Certified = 'CERTIFIED',
  ChangeCompany = 'CHANGE_COMPANY',
  CompanyProfile = 'COMPANY_PROFILE',
  CreateJob = 'CREATE_JOB',
  CreateMessageTemplate = 'CREATE_MESSAGE_TEMPLATE',
  DeleteMessageTemplate = 'DELETE_MESSAGE_TEMPLATE',
  EditTeamMembers = 'EDIT_TEAM_MEMBERS',
  ExportCandidateToAts = 'EXPORT_CANDIDATE_TO_ATS',
  HideSalaryFromCandidates = 'HIDE_SALARY_FROM_CANDIDATES',
  HideSalaryFromColleagues = 'HIDE_SALARY_FROM_COLLEAGUES',
  Home = 'HOME',
  JobSlots = 'JOB_SLOTS',
  JobSlotCheckout = 'JOB_SLOT_CHECKOUT',
  Scraped = 'SCRAPED',
  Settings = 'SETTINGS',
  SourcingUpsell = 'SOURCING_UPSELL',
  SsoSettings = 'SSO_SETTINGS',
  TeamMembers = 'TEAM_MEMBERS',
  UpdateMessageTemplate = 'UPDATE_MESSAGE_TEMPLATE',
  UserProfile = 'USER_PROFILE',
  ViewJobs = 'VIEW_JOBS',
  ViewMessageTemplates = 'VIEW_MESSAGE_TEMPLATES',
  ViewRejectionTemplates = 'VIEW_REJECTION_TEMPLATES',
  ViewSourcingTemplates = 'VIEW_SOURCING_TEMPLATES'
}

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  name: Scalars['String']['output'];
  variants: Array<Scalars['String']['output']>;
};

export type File = {
  __typename?: 'File';
  path?: Maybe<Scalars['String']['output']>;
};

export type FileAnswer = {
  __typename?: 'FileAnswer';
  contentType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  questionData: AnswerQuestionData;
  url: Scalars['String']['output'];
};

export type FileAtsQuestion = {
  __typename?: 'FileAtsQuestion';
  allowedFormats: Array<AtsFileFormat>;
  answer?: Maybe<FileAtsQuestionAnswer>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  localId: Scalars['ID']['output'];
  maximumAnswers: Scalars['Int']['output'];
  maximumBytes: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
};

export type FileAtsQuestionAnswer = {
  __typename?: 'FileAtsQuestionAnswer';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type FileQuestion = {
  __typename?: 'FileQuestion';
  atsId?: Maybe<Scalars['String']['output']>;
  question: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
};

export type FileResponse = {
  __typename?: 'FileResponse';
  contentType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type FileResponseInput = {
  file: Scalars['Upload']['input'];
  name: Scalars['String']['input'];
};

export type FollowedCompany = {
  __typename?: 'FollowedCompany';
  company: Company;
  id: Scalars['ID']['output'];
};

export type FollowedCompanyInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type FundingAmount = {
  __typename?: 'FundingAmount';
  amount: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
};

export type FundingAmountInput = {
  amount: Scalars['Int']['input'];
  currency: Scalars['String']['input'];
};

/** The possible options for gender */
export enum Gender {
  /** Female */
  Female = 'FEMALE',
  /** Male */
  Male = 'MALE',
  /** Non binary */
  NonBinary = 'NON_BINARY',
  /**
   * Other
   * @deprecated Previously used to describe non_binary
   */
  Other = 'OTHER',
  /** Prefer not to say */
  PreferNotToSay = 'PREFER_NOT_TO_SAY',
  /** Transgender */
  Transgender = 'TRANSGENDER'
}

export type GreenhouseAnswer = FileAnswer | MultiSelectAnswer | SingleSelectAnswer | TextAnswer | TextAreaAnswer;

export type GreenhouseAnswerData = {
  __typename?: 'GreenhouseAnswerData';
  answers: Array<GreenhouseAnswer>;
  demographicAnswers: Array<GreenhouseDemographicAnswer>;
};

export type GreenhouseAnswerInput = {
  existingFileAnswer?: InputMaybe<ExistingFileAnswerInput>;
  fileAnswer?: InputMaybe<NewFileAnswerInput>;
  multiSelectAnswer?: InputMaybe<MultiSelectAnswerInput>;
  singleSelectAnswer?: InputMaybe<SingleSelectAnswerInput>;
  textAnswer?: InputMaybe<TextAnswerInput>;
  textAreaAnswer?: InputMaybe<TextAreaAnswerInput>;
};

export type GreenhouseApplicationQuestion = FileQuestion | MultiSelectQuestion | SingleSelectQuestion | TextAreaQuestion | TextQuestion;

export type GreenhouseDataInput = {
  answers: Array<GreenhouseAnswerInput>;
  demographicAnswers: Array<GreenhouseDemographicAnswerInput>;
};

export type GreenhouseDemographicAnswer = GreenhouseDemographicMultiSelectAnswer | GreenhouseDemographicSingleSelectAnswer;

export type GreenhouseDemographicAnswerInput = {
  multiSelectAnswer?: InputMaybe<GreenhouseDemographicMultiSelectAnswerInput>;
  singleSelectAnswer?: InputMaybe<GreenhouseDemographicSingleSelectAnswerInput>;
};

export type GreenhouseDemographicChoice = {
  __typename?: 'GreenhouseDemographicChoice';
  label: Scalars['String']['output'];
  type?: Maybe<GreenhouseDemographicChoiceType>;
  value: Scalars['String']['output'];
};

export type GreenhouseDemographicChoiceInput = {
  label: Scalars['String']['input'];
  type?: InputMaybe<GreenhouseDemographicChoiceType>;
  value: Scalars['String']['input'];
};

export enum GreenhouseDemographicChoiceType {
  DeclineToAnswer = 'DECLINE_TO_ANSWER',
  FreeForm = 'FREE_FORM'
}

export type GreenhouseDemographicMultiSelectAnswer = {
  __typename?: 'GreenhouseDemographicMultiSelectAnswer';
  choices: Array<GreenhouseDemographicChoice>;
  questionData: AnswerQuestionData;
};

export type GreenhouseDemographicMultiSelectAnswerInput = {
  choices: Array<GreenhouseDemographicChoiceInput>;
  questionData: QuestionDataInput;
};

export type GreenhouseDemographicMultiSelectQuestion = {
  __typename?: 'GreenhouseDemographicMultiSelectQuestion';
  atsId: Scalars['String']['output'];
  choices: Array<GreenhouseDemographicChoice>;
  question: Scalars['String']['output'];
  required?: Maybe<Scalars['Boolean']['output']>;
};

export type GreenhouseDemographicQuestion = GreenhouseDemographicMultiSelectQuestion | GreenhouseDemographicSingleSelectQuestion;

export type GreenhouseDemographicQuestionSet = {
  __typename?: 'GreenhouseDemographicQuestionSet';
  description: Scalars['String']['output'];
  header: Scalars['String']['output'];
  questions: Array<GreenhouseDemographicQuestion>;
};

export type GreenhouseDemographicSingleSelectAnswer = {
  __typename?: 'GreenhouseDemographicSingleSelectAnswer';
  choice: GreenhouseDemographicChoice;
  questionData: AnswerQuestionData;
};

export type GreenhouseDemographicSingleSelectAnswerInput = {
  choice: GreenhouseDemographicChoiceInput;
  questionData: QuestionDataInput;
};

export type GreenhouseDemographicSingleSelectQuestion = {
  __typename?: 'GreenhouseDemographicSingleSelectQuestion';
  atsId: Scalars['String']['output'];
  choices: Array<GreenhouseDemographicChoice>;
  question: Scalars['String']['output'];
  required?: Maybe<Scalars['Boolean']['output']>;
};

export type GreenhouseQuestionData = {
  __typename?: 'GreenhouseQuestionData';
  demographicQuestionSet?: Maybe<GreenhouseDemographicQuestionSet>;
  questions: Array<GreenhouseApplicationQuestion>;
};

/** The grouped workflow status of company jobs */
export enum GroupWorkflowStatus {
  /** Jobs which are either live or dead. */
  All = 'ALL',
  /** Jobs which are no longer active. */
  Dead = 'DEAD',
  /** Jobs which are completed and active. */
  Live = 'LIVE',
  /** Jobs which are dead but have been seen in the past 3 months */
  RecentDead = 'RECENT_DEAD'
}

export type HiddenCompany = {
  __typename?: 'HiddenCompany';
  id: Scalars['ID']['output'];
  logoPath?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};


export type HiddenCompanyLogoPathArgs = {
  prefix?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HiddenCompanyInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

/** Target category for an image upload */
export enum ImageCategory {
  CandidateCompanyLogo = 'CANDIDATE_COMPANY_LOGO',
  Favicon = 'FAVICON',
  Logo = 'LOGO',
  Photo = 'PHOTO'
}

export type ImportedCandidate = {
  __typename?: 'ImportedCandidate';
  attachmentUrl?: Maybe<Scalars['String']['output']>;
  currentEmployer?: Maybe<Scalars['String']['output']>;
  emailAddress: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  importingCompanyId: Scalars['ID']['output'];
  importingCompanyRecruiterId: Scalars['ID']['output'];
  importingRecruiterName: Scalars['String']['output'];
  insertedAt: Scalars['NaiveDateTime']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  portfolioUrl?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  sourcedUrl?: Maybe<Scalars['String']['output']>;
};

export type ImportedCandidateInput = {
  attachmentUrl?: InputMaybe<Scalars['String']['input']>;
  currentEmployer?: InputMaybe<Scalars['String']['input']>;
  emailAddress: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  jobId: Scalars['ID']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  pipelineStageId: Scalars['ID']['input'];
  portfolioUrl?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  sourcedUrl?: InputMaybe<Scalars['String']['input']>;
};

export type IntegerValue = {
  __typename?: 'IntegerValue';
  value: Scalars['Int']['output'];
};

/** Input options for interview feedback received */
export enum InterviewFeedbackReceived {
  No = 'NO',
  NoResponseYet = 'NO_RESPONSE_YET',
  Yes = 'YES'
}

export type Investor = {
  __typename?: 'Investor';
  id: Scalars['ID']['output'];
  topInvestor?: Maybe<Scalars['Boolean']['output']>;
  value: Scalars['String']['output'];
};

export type InvestorInput = {
  topInvestor?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type InvestorPreferenceInput = {
  id: Scalars['ID']['input'];
  preference?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Job = {
  __typename?: 'Job';
  acceptsInternalApplications: Scalars['Boolean']['output'];
  activelyHiring: Scalars['Boolean']['output'];
  actualOriginalUrl?: Maybe<Scalars['String']['output']>;
  applicationProcessBullets: Array<JobApplicationProcessBullet>;
  applicationQuestions: Array<JobApplicationQuestion>;
  applied?: Maybe<Scalars['Boolean']['output']>;
  appliedDateTime?: Maybe<Scalars['DateTime']['output']>;
  atsQuestions: AtsQuestionsV1;
  broadcasts: Array<JobBroadcast>;
  /** @deprecated Deprecated in favour of hasAccess */
  canBroadcast: Scalars['Boolean']['output'];
  canChangeSalary: Scalars['Boolean']['output'];
  candidates: Array<Candidate>;
  changes: Array<JobChange>;
  company: Company;
  daysSincePosted: Scalars['Int']['output'];
  disapprovalReason?: Maybe<Scalars['String']['output']>;
  editedTitle?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  firstLiveAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  function?: Maybe<JobFunction>;
  /** Check if current user can access the given features */
  hasAccess: Array<JobAccessGrant>;
  hideSalary: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  insertedAt: Scalars['NaiveDateTime']['output'];
  internship?: Maybe<Scalars['Boolean']['output']>;
  involvesBullets: Array<JobInvolvesBullet>;
  isEligibleForOttaPro: Scalars['Boolean']['output'];
  isPaused: Scalars['Boolean']['output'];
  isPro: Scalars['Boolean']['output'];
  languageRequirements: Array<JobLanguageRequirement>;
  lastSeen?: Maybe<Scalars['DateTime']['output']>;
  lastWorkflowStatusChangeBy?: Maybe<User>;
  latestReaction?: Maybe<JobReaction>;
  live: Scalars['Boolean']['output'];
  locationInformation?: Maybe<Scalars['String']['output']>;
  locationPreferences: Array<LocationPreference>;
  locationRestrictions?: Maybe<Scalars['String']['output']>;
  lockedForProcessingAt?: Maybe<Scalars['DateTime']['output']>;
  maxDaysInOffice?: Maybe<Scalars['Int']['output']>;
  maxYearsExperienceRequired?: Maybe<Scalars['Int']['output']>;
  meetsMinSalaryPreference: Scalars['Boolean']['output'];
  /** @deprecated Use message_response_statistics field instead */
  messageAcceptanceRate?: Maybe<Scalars['Decimal']['output']>;
  /** @deprecated Use message_response_statistics field instead */
  messageNoResponseRate?: Maybe<Scalars['Decimal']['output']>;
  /** @deprecated Use message_response_statistics field instead */
  messageRejectionRate?: Maybe<Scalars['Decimal']['output']>;
  messageResponseRateStatistics: JobMessageResponseRateStatistics;
  minDaysInOffice?: Maybe<Scalars['Int']['output']>;
  minYearsExperienceRequired?: Maybe<Scalars['Int']['output']>;
  numberBroadcastRecipients: Scalars['Int']['output'];
  numberBroadcastableCandidates: Scalars['Int']['output'];
  numberCandidatesApplied?: Maybe<Scalars['Int']['output']>;
  numberCandidatesDiscarded?: Maybe<Scalars['Int']['output']>;
  numberCandidatesMessaged?: Maybe<Scalars['Int']['output']>;
  numberCandidatesQualified?: Maybe<Scalars['Int']['output']>;
  numberCandidatesReplied?: Maybe<Scalars['Int']['output']>;
  numberCandidatesShortlisted?: Maybe<Scalars['Int']['output']>;
  numberUnreadMessages?: Maybe<Scalars['Int']['output']>;
  officeLocation?: Maybe<Scalars['String']['output']>;
  originalUrl?: Maybe<Scalars['String']['output']>;
  oteSalaryRange?: Maybe<MoneyRange>;
  otherCompensationInformation?: Maybe<Scalars['String']['output']>;
  performanceStatistics?: Maybe<JobPerformanceStatistics>;
  personalisedCandidates: PersonalisedCandidatesResult;
  potentialCandidates?: Maybe<Scalars['Int']['output']>;
  previewUrl?: Maybe<Scalars['String']['output']>;
  questionData: JobQuestionData;
  remoteOnly: Scalars['Boolean']['output'];
  requirements: Array<JobRequirement>;
  salaryQuality?: Maybe<SalaryQualityRating>;
  salaryRange?: Maybe<MoneyRange>;
  scrapedExperienceLevel?: Maybe<Scalars['String']['output']>;
  scrapedExperienceText?: Maybe<Scalars['String']['output']>;
  scrapedLocation?: Maybe<Scalars['String']['output']>;
  subFunction?: Maybe<JobSubFunction>;
  subscribed: Scalars['Boolean']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  team?: Maybe<CompanyTeam>;
  teamMembers: Array<JobCompanyTeamMember>;
  technologiesUsed: Array<JobTechnologyUsed>;
  title: Scalars['String']['output'];
  /** Check if a specified user can access the given features */
  userHasAccess: Array<JobAccessGrant>;
  /** @deprecated Deprecated in favour of user_job_status_info */
  userJobStatus: UserJobStatus;
  userJobStatusInfo: UserJobStatusInfo;
  /** @deprecated Deprecated in favour of user_job_status_info */
  userJobStatusUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  validUntil: Scalars['NaiveDateTime']['output'];
  workflowNotes?: Maybe<Scalars['String']['output']>;
  workflowStatus: JobWorkflowStatus;
};


export type JobCandidatesArgs = {
  candidateStatus?: InputMaybe<CandidateStatus>;
  status?: InputMaybe<Scalars['String']['input']>;
};


export type JobHasAccessArgs = {
  features: Array<JobFeature>;
};


export type JobMessageAcceptanceRateArgs = {
  timePeriod?: InputMaybe<Scalars['DateTime']['input']>;
};


export type JobMessageNoResponseRateArgs = {
  timePeriod?: InputMaybe<Scalars['DateTime']['input']>;
};


export type JobMessageRejectionRateArgs = {
  timePeriod?: InputMaybe<Scalars['DateTime']['input']>;
};


export type JobMessageResponseRateStatisticsArgs = {
  timePeriod?: InputMaybe<Scalars['DateTime']['input']>;
};


export type JobNumberCandidatesAppliedArgs = {
  excludeQualified?: InputMaybe<Scalars['Boolean']['input']>;
  excludeRejected?: InputMaybe<Scalars['Boolean']['input']>;
  new?: InputMaybe<Scalars['Boolean']['input']>;
};


export type JobNumberCandidatesMessagedArgs = {
  excludeQualified?: InputMaybe<Scalars['Boolean']['input']>;
  excludeRejected?: InputMaybe<Scalars['Boolean']['input']>;
  timePeriod?: InputMaybe<Scalars['DateTime']['input']>;
};


export type JobNumberCandidatesRepliedArgs = {
  excludeQualified?: InputMaybe<Scalars['Boolean']['input']>;
  excludeRejected?: InputMaybe<Scalars['Boolean']['input']>;
  timePeriod?: InputMaybe<Scalars['DateTime']['input']>;
};


export type JobNumberCandidatesShortlistedArgs = {
  includeInactiveCandidates?: InputMaybe<Scalars['Boolean']['input']>;
  timePeriod?: InputMaybe<Scalars['DateTime']['input']>;
};


export type JobNumberUnreadMessagesArgs = {
  conversationStage?: InputMaybe<ConversationStage>;
  excludeQualified?: InputMaybe<Scalars['Boolean']['input']>;
};


export type JobPersonalisedCandidatesArgs = {
  preferences?: InputMaybe<PersonalisedCandidatesPreferences>;
};


export type JobUserHasAccessArgs = {
  features: Array<JobFeature>;
  userId: Scalars['ID']['input'];
};

export type JobAccessGrant = {
  __typename?: 'JobAccessGrant';
  feature: JobFeature;
  granted: Scalars['Boolean']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type JobApplication = {
  __typename?: 'JobApplication';
  accepted?: Maybe<Scalars['Boolean']['output']>;
  applied: Scalars['Boolean']['output'];
  appliedAt?: Maybe<Scalars['DateTime']['output']>;
  atsQuestions: AtsQuestionsV1;
  candidate: Candidate;
  clicked: Scalars['Boolean']['output'];
  conversation?: Maybe<CandidateConversation>;
  data?: Maybe<JobApplicationData>;
  externalId: Scalars['ID']['output'];
  externalResponded?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  internal: Scalars['Boolean']['output'];
  job: Job;
  lastActionAt: Scalars['NaiveDateTime']['output'];
  questionResponses: Array<JobApplicationQuestionResponse>;
};

export type JobApplicationData = {
  __typename?: 'JobApplicationData';
  data: AtsAnswerData;
  id: Scalars['ID']['output'];
};

export type JobApplicationDataInput = {
  greenhouseData?: InputMaybe<GreenhouseDataInput>;
  leverData?: InputMaybe<LeverDataInput>;
  ottaData?: InputMaybe<OttaDataInput>;
  workableData?: InputMaybe<WorkableDataInput>;
};

export type JobApplicationInput = {
  applied?: InputMaybe<Scalars['Boolean']['input']>;
  clicked?: InputMaybe<Scalars['Boolean']['input']>;
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  internal?: InputMaybe<Scalars['Boolean']['input']>;
  lastConfirmedAt?: InputMaybe<Scalars['NaiveDateTime']['input']>;
};

export type JobApplicationProcessBullet = {
  __typename?: 'JobApplicationProcessBullet';
  id: Scalars['ID']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  value: Scalars['String']['output'];
};

export type JobApplicationProcessBulletInput = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['String']['input'];
};

export type JobApplicationQuestion = {
  __typename?: 'JobApplicationQuestion';
  atsId: Scalars['String']['output'];
  choices?: Maybe<Array<JobApplicationQuestionChoice>>;
  id: Scalars['ID']['output'];
  job: Job;
  prompt?: Maybe<Scalars['String']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  type: JobApplicationQuestionType;
  value: Scalars['String']['output'];
};

export type JobApplicationQuestionChoice = {
  __typename?: 'JobApplicationQuestionChoice';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type JobApplicationQuestionResponse = {
  __typename?: 'JobApplicationQuestionResponse';
  data: QuestionResponseData;
  id: Scalars['ID']['output'];
  question: Scalars['String']['output'];
};

export enum JobApplicationQuestionType {
  Boolean = 'BOOLEAN',
  Checkboxes = 'CHECKBOXES',
  Date = 'DATE',
  Dropdown = 'DROPDOWN',
  File = 'FILE',
  MultipleChoice = 'MULTIPLE_CHOICE',
  Numeric = 'NUMERIC',
  Text = 'TEXT',
  TextArea = 'TEXT_AREA',
  University = 'UNIVERSITY',
  Url = 'URL'
}

export type JobBroadcast = {
  __typename?: 'JobBroadcast';
  companyRecruiter: User;
  id: Scalars['ID']['output'];
  insertedAt: Scalars['NaiveDateTime']['output'];
  message: Scalars['String']['output'];
  numberBroadcastedTo: Scalars['Int']['output'];
};

export type JobCandidatePoolNotifiedCandidatesStatistic = {
  __typename?: 'JobCandidatePoolNotifiedCandidatesStatistic';
  id: Scalars['ID']['output'];
  lastWeekTotal: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type JobCandidatePoolStatistics = {
  __typename?: 'JobCandidatePoolStatistics';
  ethnicities?: Maybe<Array<JobCandidatePoolSubsetStatistic>>;
  experienceLevels?: Maybe<Array<JobCandidatePoolSubsetStatistic>>;
  genders?: Maybe<Array<JobCandidatePoolSubsetStatistic>>;
  id: Scalars['ID']['output'];
  notifiedCandidates: JobCandidatePoolNotifiedCandidatesStatistic;
  total: Scalars['Int']['output'];
};

export type JobCandidatePoolSubsetStatistic = {
  __typename?: 'JobCandidatePoolSubsetStatistic';
  id: Scalars['ID']['output'];
  percent: Scalars['Decimal']['output'];
  total: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type JobChange = {
  __typename?: 'JobChange';
  changedBy: JobChanger;
  dismissedAt?: Maybe<Scalars['DateTime']['output']>;
  fieldName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  insertedAt: Scalars['NaiveDateTime']['output'];
  newValue?: Maybe<JobChangeValue>;
  oldValue?: Maybe<JobChangeValue>;
  user?: Maybe<User>;
};

export type JobChangeValue = BooleanValue | DatetimeValue | DecimalValue | IntegerValue | StringListValue | StringValue;

export enum JobChanger {
  CronArchiveJobs = 'CRON_ARCHIVE_JOBS',
  Gpt = 'GPT',
  Heuristics = 'HEURISTICS',
  MachineLearning = 'MACHINE_LEARNING',
  Scraper = 'SCRAPER',
  User = 'USER'
}

export type JobCompanyTeamMember = {
  __typename?: 'JobCompanyTeamMember';
  id: Scalars['ID']['output'];
  member: CompanyTeamMember;
  role: TeamMemberRole;
};

export type JobCompanyTeamMembersInput = {
  companyTeamMemberId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  role: TeamMemberRole;
};

export type JobExperiencePreference = {
  __typename?: 'JobExperiencePreference';
  entryLevel: Scalars['Boolean']['output'];
  fiveToEightYears: Scalars['Boolean']['output'];
  internship: Scalars['Boolean']['output'];
  ninePlusYears: Scalars['Boolean']['output'];
  oneToTwoYears: Scalars['Boolean']['output'];
  showManagementRoles: Scalars['Boolean']['output'];
  threeToFourYears: Scalars['Boolean']['output'];
};

export type JobExperiencePreferenceInput = {
  entryLevel?: InputMaybe<Scalars['Boolean']['input']>;
  fiveToEightYears?: InputMaybe<Scalars['Boolean']['input']>;
  internship?: InputMaybe<Scalars['Boolean']['input']>;
  ninePlusYears?: InputMaybe<Scalars['Boolean']['input']>;
  oneToTwoYears?: InputMaybe<Scalars['Boolean']['input']>;
  showManagementRoles?: InputMaybe<Scalars['Boolean']['input']>;
  threeToFourYears?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Available features on job */
export enum JobFeature {
  Analytics = 'ANALYTICS',
  Archive = 'ARCHIVE',
  Broadcast = 'BROADCAST',
  Edit = 'EDIT',
  Inbox = 'INBOX',
  Pipeline = 'PIPELINE',
  ProAnalytics = 'PRO_ANALYTICS',
  Sourcing = 'SOURCING',
  ViewSalary = 'VIEW_SALARY'
}

export type JobFunction = {
  __typename?: 'JobFunction';
  id: Scalars['ID']['output'];
  subFunctions: Array<JobSubFunction>;
  value: Scalars['String']['output'];
};

export type JobFunctionPreference = {
  __typename?: 'JobFunctionPreference';
  function: JobFunction;
  functionId: Scalars['ID']['output'];
};

export type JobFunctionPreferenceInput = {
  functionId: Scalars['ID']['input'];
};

export type JobInboundPerformanceCandidate = {
  __typename?: 'JobInboundPerformanceCandidate';
  appliedVia: Scalars['String']['output'];
  candidate: Candidate;
  id: Scalars['ID']['output'];
  seenJobAt: Scalars['NaiveDateTime']['output'];
};

export type JobInboundPerformanceStatistics = {
  __typename?: 'JobInboundPerformanceStatistics';
  ethnicities?: Maybe<JobInboundPerformanceSubsetStatistics>;
  experienceLevels?: Maybe<JobInboundPerformanceSubsetStatistics>;
  genders?: Maybe<JobInboundPerformanceSubsetStatistics>;
  id: Scalars['ID']['output'];
  totals: JobInboundPerformanceTotalStatistics;
  viewTime: JobInboundPerformanceViewTimeStatistic;
};

export type JobInboundPerformanceSubsetStatistic = {
  __typename?: 'JobInboundPerformanceSubsetStatistic';
  id: Scalars['ID']['output'];
  percent: Scalars['Decimal']['output'];
  total: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type JobInboundPerformanceSubsetStatistics = {
  __typename?: 'JobInboundPerformanceSubsetStatistics';
  applied: Array<JobInboundPerformanceSubsetStatistic>;
  clickedApply: Array<JobInboundPerformanceSubsetStatistic>;
  id: Scalars['ID']['output'];
  saved: Array<JobInboundPerformanceSubsetStatistic>;
  viewed: Array<JobInboundPerformanceSubsetStatistic>;
};

export type JobInboundPerformanceTotalStatistic = {
  __typename?: 'JobInboundPerformanceTotalStatistic';
  benchmark?: Maybe<Scalars['Decimal']['output']>;
  candidates?: Maybe<Array<Maybe<JobInboundPerformanceCandidate>>>;
  conversion?: Maybe<Scalars['Decimal']['output']>;
  id: Scalars['ID']['output'];
  isConversionPositive?: Maybe<Scalars['Boolean']['output']>;
  total: Scalars['Int']['output'];
};


export type JobInboundPerformanceTotalStatisticCandidatesArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type JobInboundPerformanceTotalStatistics = {
  __typename?: 'JobInboundPerformanceTotalStatistics';
  applied: JobInboundPerformanceTotalStatistic;
  clickedApply: JobInboundPerformanceTotalStatistic;
  id: Scalars['ID']['output'];
  saved: JobInboundPerformanceTotalStatistic;
  viewed: JobInboundPerformanceTotalStatistic;
};

export type JobInboundPerformanceViewTimeStatistic = {
  __typename?: 'JobInboundPerformanceViewTimeStatistic';
  averageTime: Scalars['Decimal']['output'];
  benchmark?: Maybe<Scalars['Decimal']['output']>;
  id: Scalars['ID']['output'];
  isAverageTimePositive: Scalars['Boolean']['output'];
};

export type JobInput = {
  applicationProcessBullets?: InputMaybe<Array<JobApplicationProcessBulletInput>>;
  approved?: InputMaybe<Scalars['Boolean']['input']>;
  companyTeamId?: InputMaybe<Scalars['ID']['input']>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  disapprovalReason?: InputMaybe<Scalars['String']['input']>;
  editedTitle?: InputMaybe<Scalars['String']['input']>;
  hideSalary?: InputMaybe<Scalars['Boolean']['input']>;
  internship?: InputMaybe<Scalars['Boolean']['input']>;
  involvesBullets?: InputMaybe<Array<JobInvolvesBulletInput>>;
  jobCompanyTeamMembers?: InputMaybe<Array<JobCompanyTeamMembersInput>>;
  languageRequirements?: InputMaybe<Array<JobLanguageRequirementInput>>;
  lastSeen?: InputMaybe<Scalars['DateTime']['input']>;
  locationInformation?: InputMaybe<Scalars['String']['input']>;
  locationPreferences?: InputMaybe<Array<LocationPreferenceInput>>;
  locationRestrictions?: InputMaybe<Scalars['String']['input']>;
  maxDaysInOffice?: InputMaybe<Scalars['Int']['input']>;
  maxYearsExperienceRequired?: InputMaybe<Scalars['Int']['input']>;
  minDaysInOffice?: InputMaybe<Scalars['Int']['input']>;
  minYearsExperienceRequired?: InputMaybe<Scalars['Int']['input']>;
  officeLocation?: InputMaybe<Scalars['String']['input']>;
  originalUrl?: InputMaybe<Scalars['String']['input']>;
  oteSalaryRange?: InputMaybe<MoneyRangeInput>;
  otherCompensationInformation?: InputMaybe<Scalars['String']['input']>;
  requirements?: InputMaybe<Array<JobRequirementInput>>;
  salaryRange?: InputMaybe<MoneyRangeInput>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  technologiesUsed?: InputMaybe<Array<JobTechnologyUsedInput>>;
  workflowNotes?: InputMaybe<Scalars['String']['input']>;
};

export type JobInteractionStatistics = {
  __typename?: 'JobInteractionStatistics';
  numberJobsApplied: Scalars['Int']['output'];
  numberJobsSaved: Scalars['Int']['output'];
  numberJobsSeen: Scalars['Int']['output'];
};

export type JobInterview = {
  __typename?: 'JobInterview';
  candidateFeedback?: Maybe<JobInterviewCandidateFeedback>;
  feedbackReceived?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  job: Job;
  user: User;
};

export type JobInterviewCandidateFeedback = {
  __typename?: 'JobInterviewCandidateFeedback';
  id: Scalars['ID']['output'];
  netPromoterScore?: Maybe<Scalars['Int']['output']>;
  privateMessage?: Maybe<Scalars['String']['output']>;
};

export type JobInterviewCandidateFeedbackInput = {
  netPromoterScore?: InputMaybe<Scalars['Int']['input']>;
  privateMessage?: InputMaybe<Scalars['String']['input']>;
};

export type JobInvolvesBullet = {
  __typename?: 'JobInvolvesBullet';
  id: Scalars['ID']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  value: Scalars['String']['output'];
};

export type JobInvolvesBulletInput = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['String']['input'];
};

export type JobLanguageRequirement = {
  __typename?: 'JobLanguageRequirement';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type JobLanguageRequirementInput = {
  id: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};

export type JobMessageResponseRateStatistics = {
  __typename?: 'JobMessageResponseRateStatistics';
  acceptanceRate?: Maybe<Scalars['Decimal']['output']>;
  noResponseRate?: Maybe<Scalars['Decimal']['output']>;
  rejectionRate?: Maybe<Scalars['Decimal']['output']>;
};

export type JobOffer = {
  __typename?: 'JobOffer';
  accepted?: Maybe<Scalars['Boolean']['output']>;
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  creationMethod?: Maybe<CreationMethod>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  job: Job;
  pendingUntil?: Maybe<Scalars['DateTime']['output']>;
  sentHiredEmail: Scalars['Boolean']['output'];
  status?: Maybe<JobOfferStatus>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  user: User;
};

export type JobOfferInput = {
  accepted?: InputMaybe<Scalars['Boolean']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum JobOfferStatus {
  AdminAdded = 'ADMIN_ADDED',
  AdminRemoved = 'ADMIN_REMOVED',
  CandidateAdded = 'CANDIDATE_ADDED',
  CandidateRemoved = 'CANDIDATE_REMOVED',
  Contested = 'CONTESTED',
  RecruiterAdded = 'RECRUITER_ADDED',
  RecruiterRemoved = 'RECRUITER_REMOVED'
}

export type JobOffers = {
  __typename?: 'JobOffers';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  company: Company;
  id: Scalars['ID']['output'];
  insertedAt: Scalars['DateTime']['output'];
  job: Job;
  status?: Maybe<JobOfferStatus>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  user: User;
};

export type JobPerformanceStatistics = {
  __typename?: 'JobPerformanceStatistics';
  candidatePool: JobCandidatePoolStatistics;
  id: Scalars['ID']['output'];
  inboundPerformance: JobInboundPerformanceStatistics;
  refreshedAt: Scalars['NaiveDateTime']['output'];
  sourcingPerformance?: Maybe<JobSourcingPerformanceStatistics>;
};

export type JobPipeline = {
  __typename?: 'JobPipeline';
  candidatePipelineStages?: Maybe<Array<CandidatePipelineStage>>;
  jobId: Scalars['ID']['output'];
};

export type JobPreferenceSuggestion = {
  id: Scalars['ID']['output'];
};

export type JobProblem = {
  __typename?: 'JobProblem';
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type JobQuestionData = {
  __typename?: 'JobQuestionData';
  data: AtsQuestionData;
};

export type JobReaction = {
  __typename?: 'JobReaction';
  direction: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  job: Job;
  reason?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['NaiveDateTime']['output'];
  user: User;
};

export type JobReactionStatistics = {
  __typename?: 'JobReactionStatistics';
  numberJobsSaved: Scalars['Int']['output'];
  numberJobsSeen: Scalars['Int']['output'];
};

export type JobRecommendation = {
  __typename?: 'JobRecommendation';
  id: Scalars['ID']['output'];
  job: Job;
};

export type JobRequirement = {
  __typename?: 'JobRequirement';
  desirable: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  value: Scalars['String']['output'];
};

export type JobRequirementInput = {
  desirable?: InputMaybe<Scalars['Boolean']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['ID']['input'];
};

export type JobSourcingPerformanceMessageOutcomeStatistic = {
  __typename?: 'JobSourcingPerformanceMessageOutcomeStatistic';
  id: Scalars['ID']['output'];
  percent: Scalars['Decimal']['output'];
  total: Scalars['Int']['output'];
};

export type JobSourcingPerformanceMessageOutcomeStatistics = {
  __typename?: 'JobSourcingPerformanceMessageOutcomeStatistics';
  accepted: JobSourcingPerformanceMessageOutcomeStatistic;
  declined: JobSourcingPerformanceMessageOutcomeStatistic;
  id: Scalars['ID']['output'];
  unanswered: JobSourcingPerformanceMessageOutcomeStatistic;
};

export type JobSourcingPerformanceStatistics = {
  __typename?: 'JobSourcingPerformanceStatistics';
  ethnicities: JobSourcingPerformanceSubsetStatistics;
  experienceLevels: JobSourcingPerformanceSubsetStatistics;
  genders: JobSourcingPerformanceSubsetStatistics;
  id: Scalars['ID']['output'];
  messageOutcomes: JobSourcingPerformanceMessageOutcomeStatistics;
  totals: JobSourcingPerformanceTotalStatistics;
};

export type JobSourcingPerformanceSubsetStatistic = {
  __typename?: 'JobSourcingPerformanceSubsetStatistic';
  id: Scalars['ID']['output'];
  percent: Scalars['Decimal']['output'];
  total: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type JobSourcingPerformanceSubsetStatistics = {
  __typename?: 'JobSourcingPerformanceSubsetStatistics';
  id: Scalars['ID']['output'];
  messaged: Array<JobSourcingPerformanceSubsetStatistic>;
  shortlisted: Array<JobSourcingPerformanceSubsetStatistic>;
  viewed: Array<JobSourcingPerformanceSubsetStatistic>;
};

export type JobSourcingPerformanceTotalStatistic = {
  __typename?: 'JobSourcingPerformanceTotalStatistic';
  conversion?: Maybe<Scalars['Decimal']['output']>;
  id: Scalars['ID']['output'];
  total: Scalars['Int']['output'];
};

export type JobSourcingPerformanceTotalStatistics = {
  __typename?: 'JobSourcingPerformanceTotalStatistics';
  id: Scalars['ID']['output'];
  messaged: JobSourcingPerformanceTotalStatistic;
  shortlisted: JobSourcingPerformanceTotalStatistic;
  viewed: JobSourcingPerformanceTotalStatistic;
};

export type JobSubFunction = {
  __typename?: 'JobSubFunction';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type JobSubFunctionPreference = {
  __typename?: 'JobSubFunctionPreference';
  subFunction: JobSubFunction;
  subFunctionId: Scalars['ID']['output'];
};

export type JobSubFunctionPreferenceInput = {
  subFunctionId: Scalars['ID']['input'];
};

export type JobTechnologyUsed = {
  __typename?: 'JobTechnologyUsed';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type JobTechnologyUsedInput = {
  id: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};

export type JobWithStatistics = {
  __typename?: 'JobWithStatistics';
  company?: Maybe<PublicCompany>;
  externalId: Scalars['String']['output'];
  function?: Maybe<JobFunction>;
  id: Scalars['ID']['output'];
  locationPreferences: Array<LocationPreference>;
  numberReactions: Scalars['Int']['output'];
  percentageApplied: Scalars['Int']['output'];
  percentageSaved: Scalars['Int']['output'];
  similarJobs: Array<JobWithStatistics>;
  subFunction?: Maybe<JobSubFunction>;
  subtitle?: Maybe<Scalars['String']['output']>;
  technologiesUsed: Array<JobTechnologyUsed>;
  title: Scalars['String']['output'];
};

/** The workflow status of a job */
export enum JobWorkflowStatus {
  /** A job which has been marked as no longer active, and therefore unavailable to view. */
  Archived = 'ARCHIVED',
  /** A job which has changed and requires review. */
  Changed = 'CHANGED',
  /** A completed job which can be shown on the platform. */
  Completed = 'COMPLETED',
  /** A job which has been added by a company who has paid for the self serve product and has not been reviewed by an operator yet. */
  CompletedByUser = 'COMPLETED_BY_USER',
  /** A job which was disapproved from being on the platform. */
  Disapproved = 'DISAPPROVED',
  /** A job marked as an exception externally, requiring internal review. */
  Exception = 'EXCEPTION',
  /** A job which has a last seen deemed too long ago, and has thus been automatically moved to this state */
  Inactive = 'INACTIVE',
  /**
   * A job which has either the qa_internal or changed status.
   * @deprecated Use qa_internal and changed_status instead
   */
  Pending = 'PENDING',
  /** A new job which has not been approved yet. */
  PreApproval = 'PRE_APPROVAL',
  /** A job which has been completed and requires external quality assurance. */
  QaExternal = 'QA_EXTERNAL',
  /** A job which has been completed and requires internal quality assurance. */
  QaInternal = 'QA_INTERNAL',
  /** A job which has been queued for completion externally. */
  QueuedExternal = 'QUEUED_EXTERNAL',
  /** A job which has been queued for completion internally. */
  QueuedInternal = 'QUEUED_INTERNAL',
  /** A job which has been created by a company recruiter but not yet submitted for approval. */
  RecruiterDraft = 'RECRUITER_DRAFT'
}

export type JobsWithSalaryStats = {
  __typename?: 'JobsWithSalaryStats';
  numberLiveJobs: Scalars['Int']['output'];
  numberLiveJobsWithPublicSalary: Scalars['Int']['output'];
  numberLiveJobsWithSalary: Scalars['Int']['output'];
};

export type LanguageRequirementPreference = {
  __typename?: 'LanguageRequirementPreference';
  /** @deprecated Use language requirement id instead */
  id: Scalars['ID']['output'];
  languageRequirement: JobLanguageRequirement;
  languageRequirementId: Scalars['ID']['output'];
};

export type LanguageRequirementPreferenceInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  languageRequirementId?: InputMaybe<Scalars['ID']['input']>;
};

/** The possible options for filtering by recent activity */
export enum LastActive {
  /** Active in the last 7 days */
  SevenDaysAgo = 'SEVEN_DAYS_AGO',
  /** Active in the last 6 weeks */
  SixWeeksAgo = 'SIX_WEEKS_AGO',
  /** Active in the last 12 weeks */
  TwelveWeeksAgo = 'TWELVE_WEEKS_AGO'
}

export type LeverAnswer = FileAnswer | MultiSelectAnswer | SingleSelectAnswer | TextAnswer | TextAreaAnswer | UrlAnswer;

export type LeverAnswerData = {
  __typename?: 'LeverAnswerData';
  answers: Array<LeverAnswer>;
};

export type LeverAnswerInput = {
  existingFileAnswer?: InputMaybe<ExistingFileAnswerInput>;
  fileAnswer?: InputMaybe<NewFileAnswerInput>;
  multiSelectAnswer?: InputMaybe<MultiSelectAnswerInput>;
  singleSelectAnswer?: InputMaybe<SingleSelectAnswerInput>;
  textAnswer?: InputMaybe<TextAnswerInput>;
  textAreaAnswer?: InputMaybe<TextAreaAnswerInput>;
  urlAnswer?: InputMaybe<UrlAnswerInput>;
};

export type LeverApplicationQuestion = FileQuestion | MultiSelectQuestion | SingleSelectQuestion | TextAreaQuestion | TextQuestion | UrlQuestion;

export type LeverDataInput = {
  answers: Array<LeverAnswerInput>;
};

export type LeverQuestionData = {
  __typename?: 'LeverQuestionData';
  questions: Array<LeverApplicationQuestion>;
};

/** Locations available as preferences */
export enum Location {
  Amsterdam = 'AMSTERDAM',
  AustinUs = 'AUSTIN_US',
  Barcelona = 'BARCELONA',
  Berlin = 'BERLIN',
  BostonUs = 'BOSTON_US',
  ChicagoUs = 'CHICAGO_US',
  DenverUs = 'DENVER_US',
  Dublin = 'DUBLIN',
  London = 'LONDON',
  LosAngelesUs = 'LOS_ANGELES_US',
  MiamiUs = 'MIAMI_US',
  NewYorkUs = 'NEW_YORK_US',
  Paris = 'PARIS',
  RemoteCanada = 'REMOTE_CANADA',
  RemoteEurope = 'REMOTE_EUROPE',
  RemoteFrance = 'REMOTE_FRANCE',
  RemoteGermany = 'REMOTE_GERMANY',
  RemoteIreland = 'REMOTE_IRELAND',
  RemoteNetherlands = 'REMOTE_NETHERLANDS',
  RemoteSpain = 'REMOTE_SPAIN',
  RemoteUk = 'REMOTE_UK',
  RemoteUs = 'REMOTE_US',
  SanFranciscoBayAreaUs = 'SAN_FRANCISCO_BAY_AREA_US',
  Toronto = 'TORONTO',
  Vancouver = 'VANCOUVER'
}

export type LocationPreference = {
  __typename?: 'LocationPreference';
  id: Scalars['ID']['output'];
  location: Location;
};

export type LocationPreferenceInput = {
  location: Location;
};

export type MapImageUrls = {
  __typename?: 'MapImageUrls';
  large: Scalars['String']['output'];
  medium: Scalars['String']['output'];
  small: Scalars['String']['output'];
};

export type MessageTemplate = {
  __typename?: 'MessageTemplate';
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  name: Scalars['String']['output'];
  showToAll: Scalars['Boolean']['output'];
  type: MessageTemplateTypeEnum;
};

/** The possible options for message template type */
export enum MessageTemplateTypeEnum {
  Rejection = 'REJECTION',
  Sourcing = 'SOURCING'
}

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Decimal']['output'];
  currency: Currency;
};

export type MoneyInput = {
  amount: Scalars['Decimal']['input'];
  currency: Currency;
};

export type MoneyRange = {
  __typename?: 'MoneyRange';
  currency: Currency;
  maxAmount?: Maybe<Scalars['Decimal']['output']>;
  minAmount?: Maybe<Scalars['Decimal']['output']>;
};

export type MoneyRangeInput = {
  currency: Currency;
  maxAmount?: InputMaybe<Scalars['Decimal']['input']>;
  minAmount?: InputMaybe<Scalars['Decimal']['input']>;
};

export type MultiSelectAnswer = {
  __typename?: 'MultiSelectAnswer';
  choices: Array<Choice>;
  questionData: AnswerQuestionData;
};

export type MultiSelectAnswerInput = {
  choices: Array<ChoiceInput>;
  questionData: QuestionDataInput;
};

export type MultiSelectQuestion = {
  __typename?: 'MultiSelectQuestion';
  atsId?: Maybe<Scalars['String']['output']>;
  choices: Array<Choice>;
  question: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
};

export type MultipleChoiceResponse = {
  __typename?: 'MultipleChoiceResponse';
  choices: Array<SingleChoiceResponse>;
};

export type NewFileAnswerInput = {
  file: Scalars['Upload']['input'];
  name: Scalars['String']['input'];
  questionData: QuestionDataInput;
};

export type NewUserInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  marketingConsent?: InputMaybe<Scalars['Boolean']['input']>;
  password: Scalars['String']['input'];
  referredByUserId?: InputMaybe<Scalars['ID']['input']>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
};

/** Available notifications */
export enum Notification {
  BatchEndResubscribe = 'BATCH_END_RESUBSCRIBE',
  CompleteProfile = 'COMPLETE_PROFILE',
  CreateProfile = 'CREATE_PROFILE',
  HiddenProfile = 'HIDDEN_PROFILE',
  UnhideProfile = 'UNHIDE_PROFILE'
}

/** Frequencies at which it is possible to receive notifications */
export enum NotificationFrequency {
  Daily = 'DAILY',
  Never = 'NEVER',
  Weekly = 'WEEKLY'
}

export type NumberAtsQuestion = {
  __typename?: 'NumberAtsQuestion';
  answer?: Maybe<Scalars['Decimal']['output']>;
  default?: Maybe<Scalars['Decimal']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  localId: Scalars['ID']['output'];
  maximumAnswers: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
};

/** The possible options for opt in status */
export enum OptInStatusEnum {
  OptedIn = 'OPTED_IN',
  OptedOut = 'OPTED_OUT'
}

export type OttaAnswer = TextAreaAnswer;

export type OttaAnswerData = {
  __typename?: 'OttaAnswerData';
  answers: Array<OttaAnswer>;
};

export type OttaAnswerInput = {
  textAreaAnswer?: InputMaybe<TextAreaAnswerInput>;
};

export type OttaApplicationQuestion = TextAreaQuestion;

export type OttaDataInput = {
  answers: Array<OttaAnswerInput>;
};

export type OttaQuestionData = {
  __typename?: 'OttaQuestionData';
  questions: Array<OttaApplicationQuestion>;
};

export type PeopleBreakdownInput = {
  ethnicities: Array<SubsetStatInput>;
  genders: Array<SubsetStatInput>;
  workingLocations: Array<InputMaybe<SubsetStatInput>>;
};

export type PeopleBreakdownStatistics = {
  __typename?: 'PeopleBreakdownStatistics';
  ethnicities?: Maybe<Array<EbSubsetStatistic>>;
  genders?: Maybe<Array<EbSubsetStatistic>>;
  workingLocations?: Maybe<Array<EbSubsetStatistic>>;
};

/** Available permissions */
export enum Permission {
  CompanyAdmin = 'COMPANY_ADMIN',
  DisapproveCompletedJobs = 'DISAPPROVE_COMPLETED_JOBS',
  ManageApprovedCompanies = 'MANAGE_APPROVED_COMPANIES',
  ManageChangedCompanies = 'MANAGE_CHANGED_COMPANIES',
  ManageCompanyEndorsements = 'MANAGE_COMPANY_ENDORSEMENTS',
  ManageCompletedByUserCompanies = 'MANAGE_COMPLETED_BY_USER_COMPANIES',
  ManageCompletedByUserJobs = 'MANAGE_COMPLETED_BY_USER_JOBS',
  ManageCompletedCompanies = 'MANAGE_COMPLETED_COMPANIES',
  ManageExceptionJobs = 'MANAGE_EXCEPTION_JOBS',
  ManageJobSalaries = 'MANAGE_JOB_SALARIES',
  ManagePreApprovalCompanies = 'MANAGE_PRE_APPROVAL_COMPANIES',
  ManagePreApprovalJobs = 'MANAGE_PRE_APPROVAL_JOBS',
  ManageQaExternalJobs = 'MANAGE_QA_EXTERNAL_JOBS',
  ManageQaInternalCompanies = 'MANAGE_QA_INTERNAL_COMPANIES',
  ManageQaInternalJobs = 'MANAGE_QA_INTERNAL_JOBS',
  SetExceptionJobs = 'SET_EXCEPTION_JOBS',
  ViewSalary = 'VIEW_SALARY'
}

export type PermissionObject = {
  __typename?: 'PermissionObject';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Permission;
  scope: PermissionScope;
};

export enum PermissionScope {
  Hire = 'HIRE',
  Search = 'SEARCH',
  Toolbox = 'TOOLBOX'
}

export type PersonalisedCandidateFilters = {
  completedProfile?: InputMaybe<Scalars['Boolean']['input']>;
  ethnicities?: InputMaybe<Array<EthnicityEnum>>;
  experienceRange?: InputMaybe<ExperienceRange>;
  genders?: InputMaybe<Array<Gender>>;
  jobFunctions?: InputMaybe<Array<Scalars['ID']['input']>>;
  jobSubFunctions?: InputMaybe<Array<Scalars['ID']['input']>>;
  jobTitles?: InputMaybe<Array<Scalars['String']['input']>>;
  lastActive?: InputMaybe<LastActive>;
  maxSalary?: InputMaybe<MoneyInput>;
  recentlyActive?: InputMaybe<Scalars['Boolean']['input']>;
  regions?: InputMaybe<Array<Scalars['String']['input']>>;
  savedCompany?: InputMaybe<Scalars['Boolean']['input']>;
  savedJob?: InputMaybe<Scalars['Boolean']['input']>;
  searchStages?: InputMaybe<Array<Scalars['String']['input']>>;
  subRegions?: InputMaybe<Array<Scalars['String']['input']>>;
  visaSponsorshipCountries?: InputMaybe<Array<VisaSponsorshipCountryInput>>;
  workExperienceCompanies?: InputMaybe<Array<Scalars['String']['input']>>;
  workExperienceTechnologies?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type PersonalisedCandidatesPreferences = {
  companyNames?: InputMaybe<Array<Scalars['String']['input']>>;
  completedProfile?: InputMaybe<Scalars['Boolean']['input']>;
  ethnicities?: InputMaybe<Array<EthnicityEnum>>;
  experienceRange?: InputMaybe<ExperienceRange>;
  genders?: InputMaybe<Array<Gender>>;
  jobSubFunctionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  keywords?: InputMaybe<Array<Scalars['String']['input']>>;
  lastActive?: InputMaybe<LastActive>;
  likeCandidates?: InputMaybe<Array<Scalars['String']['input']>>;
  maxSalary?: InputMaybe<MoneyInput>;
  mustIncludeAllKeywords?: InputMaybe<Scalars['Boolean']['input']>;
  queryString?: InputMaybe<Scalars['String']['input']>;
  recentlyActive?: InputMaybe<Scalars['Boolean']['input']>;
  regions?: InputMaybe<Array<Scalars['String']['input']>>;
  savedCompany?: InputMaybe<Scalars['Boolean']['input']>;
  savedJob?: InputMaybe<Scalars['Boolean']['input']>;
  subregions?: InputMaybe<Array<Scalars['String']['input']>>;
  visaSponsorshipRegions?: InputMaybe<Array<VisaRegion>>;
};

export type PersonalisedCandidatesResult = {
  __typename?: 'PersonalisedCandidatesResult';
  candidates: Array<Candidate>;
  total: Scalars['Int']['output'];
};

export type Photo = {
  __typename?: 'Photo';
  id: Scalars['ID']['output'];
  path: Scalars['String']['output'];
};


export type PhotoPathArgs = {
  prefix?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PhotoInput = {
  path: Scalars['String']['input'];
};

export type PipelineCandidate = {
  __typename?: 'PipelineCandidate';
  activated: Scalars['Boolean']['output'];
  atsProfileUrl?: Maybe<Scalars['String']['output']>;
  averageResponseTime?: Maybe<Scalars['Int']['output']>;
  companyQualitiesPreferences: Array<CompanyQualitiesPreference>;
  companySizePreferences: Array<CompanySizePreference>;
  companySubQualitiesPreferences: Array<CompanySubQualitiesPreference>;
  contextQuestionResponses: Array<CandidateContextQuestionResponse>;
  currentCompany?: Maybe<Company>;
  currentTitle?: Maybe<Scalars['String']['output']>;
  discardedJobs: Array<Job>;
  discardedSubFunctions: Array<JobSubFunction>;
  diverse: Scalars['Boolean']['output'];
  diversityMatch: Scalars['Boolean']['output'];
  educationExperiences: Array<UserEducationExperience>;
  email: Scalars['String']['output'];
  ethnicities: Array<Ethnicity>;
  externalId: Scalars['ID']['output'];
  firstName: Scalars['String']['output'];
  followedCompanies: Array<FollowedCompany>;
  genders: Array<Gender>;
  hasAccess: Array<AccessGrant>;
  hasConfirmedLocation?: Maybe<Scalars['Boolean']['output']>;
  hasSetPreferences?: Maybe<Scalars['Boolean']['output']>;
  hasSubmittedSalary: Scalars['Boolean']['output'];
  hiddenCompanies: Array<HiddenCompany>;
  id: Scalars['ID']['output'];
  insertedAt: Scalars['NaiveDateTime']['output'];
  jobApplications: Array<JobApplication>;
  jobEmailNotificationsFrequency: NotificationFrequency;
  jobExperiencePreference?: Maybe<JobExperiencePreference>;
  /** @deprecated Renamed to job_experience_preference */
  jobExperiencePreferences?: Maybe<UserJobExperiencePreferences>;
  jobFunctionPreferences: Array<JobFunctionPreference>;
  /** @deprecated Unused - see job reaction statistics */
  jobInteractionStatistics: JobInteractionStatistics;
  jobNotificationUnsubscription: Scalars['Boolean']['output'];
  jobPreferenceSuggestions?: Maybe<Array<JobPreferenceSuggestion>>;
  jobReactionStatistics: JobReactionStatistics;
  jobRecommendations: Array<JobRecommendation>;
  jobSubFunctionPreferences: Array<JobSubFunctionPreference>;
  jobsProcessed?: Maybe<Scalars['Int']['output']>;
  languageRequirementPreferences: Array<LanguageRequirementPreference>;
  lastActive: Scalars['NaiveDateTime']['output'];
  lastName: Scalars['String']['output'];
  lastUnconfirmedJobApplication?: Maybe<Scalars['String']['output']>;
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  locationPreferences: Array<LocationPreference>;
  marketingConsent: Scalars['Boolean']['output'];
  messageRequestsSentFromCompany: Array<CandidateMessageRequest>;
  /** @deprecated Use min_salary_requirement instead */
  minSalaryPreference?: Maybe<Scalars['Int']['output']>;
  minSalaryRequirement?: Maybe<Money>;
  mostRecentJobInterview?: Maybe<CandidateJobInterview>;
  numReferrals?: Maybe<Scalars['Int']['output']>;
  numUnreadShortlistedJobs: Scalars['Int']['output'];
  permissions: Array<PermissionObject>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profileCompletion: Array<ProfileCompletionComponent>;
  profileImagePath?: Maybe<Scalars['String']['output']>;
  profileInterests?: Maybe<Scalars['String']['output']>;
  projects: Array<UserProject>;
  pronoun?: Maybe<Pronoun>;
  receivedMessageRequests: Array<CandidateMessageRequest>;
  region?: Maybe<Scalars['String']['output']>;
  retained: Scalars['Boolean']['output'];
  role: RoleType;
  savedJobsAtCompany: Array<PublicJob>;
  savedSimilarCompanies: Array<PublicCompany>;
  searchStage?: Maybe<Scalars['String']['output']>;
  sectorTagPreferences: Array<SectorTagPreference>;
  shortlisted: Scalars['Boolean']['output'];
  shortlistedJobs: Array<Job>;
  showProfileToCompanies: Scalars['Boolean']['output'];
  showRequestAdvice?: Maybe<Scalars['Boolean']['output']>;
  signUpReasonResponses: Array<SignUpReasonResponse>;
  /** @deprecated Use region instead */
  signupRegion?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use subregion instead */
  signupSubregion?: Maybe<Scalars['String']['output']>;
  spotlightContent?: Maybe<SpotlightContent>;
  subregion?: Maybe<Scalars['String']['output']>;
  technologiesUsedPreferences: Array<TechnologyUsedPreference>;
  termsAndConditionsConsentedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  timezone?: Maybe<Timezone>;
  /** @deprecated Deprecated */
  topCandidate: Scalars['Boolean']['output'];
  totalDiscardedJobs?: Maybe<Scalars['Int']['output']>;
  totalJobApplicationClicks: Scalars['Int']['output'];
  totalJobApplicationConfirms: Scalars['Int']['output'];
  totalJobReactions: Scalars['Int']['output'];
  totalRemainingJobs: Scalars['Int']['output'];
  unreadMessages: Array<ConversationMessage>;
  verifiedEmail: Scalars['Boolean']['output'];
  visaRequirementCountries: Array<VisaRequirementCountry>;
  websiteLinks: Array<WebsiteLink>;
  workExperience: Array<UserWorkExperience>;
  workExperiences: Array<UserWorkExperience>;
};


export type PipelineCandidateAtsProfileUrlArgs = {
  jobId: Scalars['ID']['input'];
};


export type PipelineCandidateDiscardedJobsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PipelineCandidateDiversityMatchArgs = {
  filters?: InputMaybe<PersonalisedCandidateFilters>;
};


export type PipelineCandidateHasAccessArgs = {
  features: Array<Feature>;
};


export type PipelineCandidateJobApplicationsArgs = {
  applied?: InputMaybe<Scalars['Boolean']['input']>;
  appliedAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  appliedAtLt?: InputMaybe<Scalars['DateTime']['input']>;
  externalOrSentToAts?: InputMaybe<Scalars['Boolean']['input']>;
  externalResponded?: InputMaybe<Array<ExternalResponded>>;
  internal?: InputMaybe<Scalars['Boolean']['input']>;
  orderByLeastResponseRateDatapoints?: InputMaybe<Scalars['Boolean']['input']>;
  stillApplied?: InputMaybe<Scalars['Boolean']['input']>;
};


export type PipelineCandidateJobInteractionStatisticsArgs = {
  since: Scalars['NaiveDateTime']['input'];
};


export type PipelineCandidateJobRecommendationsArgs = {
  ignoreSubfunctions?: InputMaybe<Scalars['Boolean']['input']>;
  preferredSectorId?: InputMaybe<Scalars['ID']['input']>;
  themeId?: InputMaybe<ThemeId>;
};


export type PipelineCandidateJobsProcessedArgs = {
  since: Scalars['DateTime']['input'];
};


export type PipelineCandidateProfileImagePathArgs = {
  prefix?: InputMaybe<Scalars['Boolean']['input']>;
};


export type PipelineCandidateShortlistedArgs = {
  jobId: Scalars['ID']['input'];
};


export type PipelineCandidateSpotlightContentArgs = {
  companyIds: Array<Scalars['String']['input']>;
};


export type PipelineCandidateTopCandidateArgs = {
  jobId: Scalars['ID']['input'];
};


export type PipelineCandidateTotalRemainingJobsArgs = {
  ignoreSubfunctions?: InputMaybe<Scalars['Boolean']['input']>;
  sectorTagIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type PipelineCandidateUnreadMessagesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type PipelineCandidateWorkExperienceArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PotentialCoreCompany = {
  __typename?: 'PotentialCoreCompany';
  company: Company;
  hasFreeJobSlots: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  reason: Scalars['String']['output'];
};

export type Product = {
  __typename?: 'Product';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  variants: Array<Maybe<ProductVariant>>;
};

export enum ProductType {
  Basic = 'BASIC',
  Pro = 'PRO'
}

export type ProductVariant = {
  __typename?: 'ProductVariant';
  id: Scalars['ID']['output'];
  internalDisplayName: Scalars['String']['output'];
  jobFunction?: Maybe<JobFunction>;
  product: Product;
  region?: Maybe<Region>;
};

/** The components that contribute to profile completion */
export enum ProfileCompletionComponent {
  HasEducationExperience = 'HAS_EDUCATION_EXPERIENCE',
  HasInterests = 'HAS_INTERESTS',
  HasLinkedin = 'HAS_LINKEDIN',
  HasMoreAboutYou = 'HAS_MORE_ABOUT_YOU',
  HasSnippets = 'HAS_SNIPPETS',
  HasWorkExperience = 'HAS_WORK_EXPERIENCE',
  HasWorkExperienceDescriptions = 'HAS_WORK_EXPERIENCE_DESCRIPTIONS',
  HasWorkExperienceTechnologies = 'HAS_WORK_EXPERIENCE_TECHNOLOGIES'
}

/** The states of profile completion */
export enum ProfileState {
  /** Work experience (at least 1), 3 work experience descriptions (or less than 3 if does not have 3 work experiences), Technologies (Product/Data/Engineering/Design ONLY), (at least 1), Education (at least 1), linkedin URL */
  Deep = 'DEEP',
  /** Same as deep, plus Snippets (at least 1), Interests (at least 1), More about you (at least 1) */
  Full = 'FULL',
  /** No information has been added */
  NotCreated = 'NOT_CREATED',
  /** At least one field has been added */
  Partial = 'PARTIAL'
}

export type ProfileStatus = {
  __typename?: 'ProfileStatus';
  completedFields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** @deprecated Experiment has finished */
  mostRecentSourcingViews: Array<Scalars['String']['output']>;
  /** @deprecated Experiment has finished */
  numSourcingViews: Scalars['Int']['output'];
  percent?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<ProfileState>;
};

export enum ProfileType {
  Enhanced = 'ENHANCED',
  Standard = 'STANDARD'
}

export type ProjectTechnologyUsedInput = {
  id: Scalars['ID']['input'];
};

/** The possible options for user pronouns */
export enum Pronoun {
  HeHim = 'HE_HIM',
  PreferNotToSay = 'PREFER_NOT_TO_SAY',
  SheHer = 'SHE_HER',
  TheyThem = 'THEY_THEM'
}

export type PublicCompany = {
  __typename?: 'PublicCompany';
  domainName?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  faviconPath?: Maybe<Scalars['String']['output']>;
  glassdoorUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  logoPath?: Maybe<Scalars['String']['output']>;
  marketBullets: Array<CompanyMarketBullet>;
  name: Scalars['String']['output'];
  numberHistoricalJobs: Scalars['Int']['output'];
  numberLiveJobs: Scalars['Int']['output'];
  scraperUrl?: Maybe<Scalars['String']['output']>;
  trustpilotUrl?: Maybe<Scalars['String']['output']>;
  urlSafeName: Scalars['String']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};


export type PublicCompanyFaviconPathArgs = {
  prefix?: InputMaybe<Scalars['Boolean']['input']>;
};


export type PublicCompanyLogoPathArgs = {
  prefix?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PublicCompanyRecruiter = {
  __typename?: 'PublicCompanyRecruiter';
  currentTitle: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  profileImagePath?: Maybe<Scalars['String']['output']>;
};


export type PublicCompanyRecruiterProfileImagePathArgs = {
  prefix?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PublicJob = {
  __typename?: 'PublicJob';
  externalId: Scalars['String']['output'];
  function?: Maybe<JobFunction>;
  id: Scalars['ID']['output'];
  locationPreferences: Array<LocationPreference>;
  subFunction?: Maybe<JobSubFunction>;
  subtitle?: Maybe<Scalars['String']['output']>;
  technologiesUsed: Array<JobTechnologyUsed>;
  title: Scalars['String']['output'];
};

export type PublicJobApplication = {
  __typename?: 'PublicJobApplication';
  candidate: Candidate;
  data?: Maybe<JobApplicationData>;
  job: PublicJob;
  questionResponses: Array<JobApplicationQuestionResponse>;
};

export type PublicStatistics = {
  __typename?: 'PublicStatistics';
  numberLiveCandidateProfiles: Scalars['Int']['output'];
  numberLiveCompanies: Scalars['Int']['output'];
  numberLiveJobs: Scalars['Int']['output'];
  numberUsers: Scalars['Int']['output'];
};

export type QuestionDataInput = {
  atsId?: InputMaybe<Scalars['String']['input']>;
  question: Scalars['String']['input'];
};

export type QuestionResponseData = BooleanResponse | DateResponse | DecimalResponse | FileResponse | MultipleChoiceResponse | SingleChoiceResponse | StringResponse | UrlResponse;

export type Region = {
  __typename?: 'Region';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type RemapJobInput = {
  id: Scalars['ID']['input'];
  originalUrl?: InputMaybe<Scalars['String']['input']>;
};

export type RequestCompanyInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type Response = {
  __typename?: 'Response';
  message?: Maybe<Scalars['String']['output']>;
  status: ResponseStatus;
};

/** Response status indicating success (ok) or failure (error) */
export enum ResponseStatus {
  Error = 'ERROR',
  Ok = 'OK'
}

export type RocketListEntry = {
  __typename?: 'RocketListEntry';
  company: Company;
  id: Scalars['ID']['output'];
  listLocation: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

/** The possible options for the role of a user */
export enum RoleType {
  /** The user is an Otta employee */
  Admin = 'ADMIN',
  /** The user is a job-seeker */
  Candidate = 'CANDIDATE',
  /** The user is a recruiter for a company hiring on Otta */
  CompanyRecruiter = 'COMPANY_RECRUITER',
  /** The user is a researcher for data on companies */
  ExternalResearcher = 'EXTERNAL_RESEARCHER',
  /** The user is an Otta employee on the operations team responsible for processing jobs */
  InternalOperator = 'INTERNAL_OPERATOR'
}

export type RootMutationType = {
  __typename?: 'RootMutationType';
  acceptJobPreferenceSuggestion?: Maybe<JobPreferenceSuggestion>;
  /**
   * Add an acquisition_channel to a given company
   * @deprecated use update_company instead
   */
  addAcquisitionChannelToCompany: Company;
  /** Add a sale for a given company */
  addCompanySale?: Maybe<Company>;
  addJobSlot?: Maybe<Company>;
  addJobsToCompanySubscription?: Maybe<Company>;
  addUserPermission?: Maybe<User>;
  approveCompany: Company;
  /** Archive a job at the current recruiter's current company */
  archiveCompanyJob?: Maybe<Job>;
  /** Authorize access to a company's ATS */
  authorizeAtsIntegration?: Maybe<Company>;
  /** Auto populates a user's job preferences based on an existing job */
  autoPopulatePreferences?: Maybe<User>;
  /** Batch update multiple pipeline items' stages */
  batchUpdateCandidatePipelineItemStage: JobPipeline;
  bulkEnrolIntoJobSlots: Array<Company>;
  /** Create same message in multiple conversations, and update candidate item stage */
  bulkMessageCandidatesAndUpdateStage?: Maybe<Array<Maybe<ConversationMessage>>>;
  /** Change a user's password */
  changePassword?: Maybe<User>;
  /** Confirm that a job is still live */
  confirmJobLive?: Maybe<Job>;
  /** Update the current user's role into a candidate role */
  convertCurrentUserToCandidate?: Maybe<User>;
  /** Update the current user's role into a recruiter role */
  convertCurrentUserToRecruiter?: Maybe<User>;
  /** Create a candidate's response to a context question */
  createCandidateContextQuestionResponse?: Maybe<CandidateContextQuestionResponse>;
  /** Create a message within a conversation between a candidate and a company recruiter */
  createCandidateConversationMessage?: Maybe<CandidateConversationMessage>;
  /** Create and upload a candidate's CV PDF */
  createCandidateCvPdf?: Maybe<UrlObject>;
  /** Create a message request to a candidate for a specific job */
  createCandidateMessageRequest?: Maybe<Candidate>;
  /** Create a reaction about a candidate for a specific job */
  createCandidateReaction?: Maybe<User>;
  /** Create a company */
  createCompany?: Maybe<Company>;
  /** Create an additional info bullet for a given company */
  createCompanyAdditionalInfoBullet?: Maybe<CompanyAdditionalInfoBullet>;
  /** Create an article for a given company */
  createCompanyArticle?: Maybe<CompanyArticle>;
  /** Create a single brand asset */
  createCompanyBrandAsset?: Maybe<Company>;
  /** Create a link between a company brand asset and something */
  createCompanyBrandAssetLink?: Maybe<Company>;
  /** Creates a Stripe customer management link */
  createCompanyCustomerPortalSession?: Maybe<CompanyCustomerPortalSession>;
  /** Create a diversity initiative for a given company */
  createCompanyDiversityInitiative?: Maybe<CompanyDiversityInitiative>;
  /** Create a new employee survey */
  createCompanyEmployeeSurvey?: Maybe<CompanyEmployeeSurvey>;
  /** Create a founder for a given company */
  createCompanyFounder?: Maybe<CompanyFounder>;
  /** Create a funding round for a given company */
  createCompanyFundingRound?: Maybe<CompanyFundingRound>;
  /** Create a market bullet for a given company */
  createCompanyMarketBullet?: Maybe<CompanyMarketBullet>;
  /** Create a an office for a given company */
  createCompanyOffice?: Maybe<Company>;
  /** Create an other benefit bullet for a given company */
  createCompanyOtherBenefit?: Maybe<CompanyOtherBenefit>;
  /** Create a person progressing for a given company */
  createCompanyPersonProgressing?: Maybe<CompanyPersonProgressing>;
  /**
   * Creates a new company recruiter user
   * @deprecated Deprecated in favour of POST /auth/signup
   */
  createCompanyRecruiterUser?: Maybe<User>;
  createCompanySamlData: CompanySamlData;
  /** Creates a link to Stripe checkout */
  createCompanySubscriptionCheckoutSession?: Maybe<CompanySubscriptionCheckoutSession>;
  /** Create a team for a given company */
  createCompanyTeam?: Maybe<Company>;
  /** Create a value for a given company */
  createCompanyValue?: Maybe<CompanyValue>;
  /** Create a global user suppression */
  createGlobalUserSuppressions: Scalars['String']['output'];
  /** Create an imported candidate */
  createImportedCandidate: JobPipeline;
  /** Create an investor for a given company */
  createInvestor?: Maybe<Investor>;
  /** Create a job */
  createJob?: Maybe<Job>;
  /** Create an application process bullet for a given job */
  createJobApplicationProcessBullet?: Maybe<JobApplicationProcessBullet>;
  /** Create & send a broadcast for a job */
  createJobBroadcast: JobBroadcast;
  /** Create a hire as an admin */
  createJobHire?: Maybe<Job>;
  /** Create an involves bullet for a given job */
  createJobInvolvesBullet?: Maybe<JobInvolvesBullet>;
  /** Report a problem for a job */
  createJobProblem: JobProblem;
  /** Creates a new reaction to a job */
  createJobReaction?: Maybe<JobReaction>;
  /** Create a requirement for a given job */
  createJobRequirement?: Maybe<JobRequirement>;
  /** Creates a link to Stripe checkout for job slots */
  createJobSlotCheckoutSession?: Maybe<CompanySubscriptionCheckoutSession>;
  /** Create a language requirement for a given job */
  createLanguageRequirement?: Maybe<JobLanguageRequirement>;
  /** Create a message template */
  createMessageTemplate: MessageTemplate;
  /** Create a new pipeline stage */
  createPipelineStage: JobPipeline;
  /** Create a scrape request */
  createScrapeRequest: UserScrapeRequest;
  /** Create or update sector tag for a given company */
  createSectorTag?: Maybe<SectorTag>;
  /** Create a technology used for a given job */
  createTechnologyUsed?: Maybe<JobTechnologyUsed>;
  /**
   * Creates a new user
   * @deprecated Deprecated in favour of POST /auth/signup
   */
  createUser?: Maybe<User>;
  /** Create a user's education experience item */
  createUserEducationExperience?: Maybe<UserEducationExperience>;
  /** Create a user's project item */
  createUserProject?: Maybe<UserProject>;
  /** Create a user's suppression */
  createUserSuppression: Scalars['String']['output'];
  /** Create a user's work experience item */
  createUserWorkExperience?: Maybe<UserWorkExperience>;
  /** Create a suggestion for a work experience by work experience ID */
  createWorkExperienceSuggestion?: Maybe<UserWorkExperienceSuggestion>;
  /** Reset ATS API keys for a company's ATS */
  deleteAtsApiKeys?: Maybe<Company>;
  /** Delete a candidate's response to a context question */
  deleteCandidateContextQuestionResponse?: Maybe<CandidateContextQuestionResponse>;
  /** Delete a company */
  deleteCompany?: Maybe<Company>;
  /** Delete an additional info bullet for a given company */
  deleteCompanyAdditionalInfoBullet?: Maybe<CompanyAdditionalInfoBullet>;
  /** Delete an article for a given company */
  deleteCompanyArticle?: Maybe<CompanyArticle>;
  /** Delete a company ATS API key */
  deleteCompanyAtsApiKey: CompanyAtsApiKey;
  /** Delete a link between a company brand asset and something */
  deleteCompanyBrandAssetLink?: Maybe<Company>;
  /** Delete a diversity initiative for a given company */
  deleteCompanyDiversityInitiative?: Maybe<CompanyDiversityInitiative>;
  /** Delete a founder for a given company */
  deleteCompanyFounder?: Maybe<CompanyFounder>;
  /** Delete a funding round for a given company */
  deleteCompanyFundingRound?: Maybe<CompanyFundingRound>;
  /** Delete a market bullet for a given company */
  deleteCompanyMarketBullet?: Maybe<CompanyMarketBullet>;
  /** Delete an office for a given company */
  deleteCompanyOffice?: Maybe<Company>;
  /** Delete an other benefit bullet for a given company */
  deleteCompanyOtherBenefit?: Maybe<CompanyOtherBenefit>;
  /** Delete a person progressing for a given company */
  deleteCompanyPersonProgressing?: Maybe<CompanyPersonProgressing>;
  /** Delete a company photo */
  deleteCompanyPhoto?: Maybe<Company>;
  /** Delete a team for a given company */
  deleteCompanyTeam?: Maybe<Company>;
  /** Delete a value for a given company */
  deleteCompanyValue?: Maybe<CompanyValue>;
  /** Permanently delete the current user and all associated data */
  deleteCurrentUser?: Maybe<User>;
  /** Delete an application process bullet for a given job */
  deleteJobApplicationProcessBullet?: Maybe<JobApplicationProcessBullet>;
  /**
   * Delete a response to a job application question
   * @deprecated Use upsert_job_application_ats_question_answers
   */
  deleteJobApplicationQuestionResponse?: Maybe<JobApplicationQuestionResponse>;
  /** Delete an involves bullet for a given job */
  deleteJobInvolvesBullet?: Maybe<JobInvolvesBullet>;
  /** Delete all the job reactions for a user */
  deleteJobReactions: Array<JobReaction>;
  /**
   * Delete job recommendation for user
   * @deprecated No-op
   */
  deleteJobRecommendation?: Maybe<JobRecommendation>;
  /**
   * Delete job recommendations for a user
   * @deprecated No-op
   */
  deleteJobRecommendations: Array<JobRecommendation>;
  /** Delete a requirement for a given job */
  deleteJobRequirement?: Maybe<JobRequirement>;
  /** Delete a message template */
  deleteMessageTemplate?: Maybe<MessageTemplate>;
  /** Delete a pipeline item note */
  deletePipelineItemNote: CandidatePipelineItem;
  /** Delete a pipeline stage */
  deletePipelineStage: JobPipeline;
  /** Permanently delete a user and all associated data */
  deleteUser?: Maybe<User>;
  /** Delete a user's education experience item */
  deleteUserEducationExperience?: Maybe<UserEducationExperience>;
  /** Delete a user's project item */
  deleteUserProject?: Maybe<UserProject>;
  /** Delete a user's suppression */
  deleteUserSuppression: Scalars['String']['output'];
  /** Delete a user's work experience item */
  deleteUserWorkExperience?: Maybe<UserWorkExperience>;
  /** Deregister a user's device for mobile app push notifications */
  deregisterUserDevice: Scalars['String']['output'];
  disapproveCompany: Company;
  dismissJobPreferenceSuggestion?: Maybe<JobPreferenceSuggestion>;
  /** Marks a notification as dismissed for the current user */
  dismissNotification?: Maybe<UserNotification>;
  /** Enrols a recruiter into the job slots experiment */
  enrolIntoJobSlotExperiment: User;
  /** Follow company from user */
  followCompany?: Maybe<User>;
  /** Send a forgotten password email to a user */
  forgotPassword?: Maybe<User>;
  /** Hide company from user */
  hideCompany?: Maybe<PublicCompany>;
  /**
   * Logs a user in
   * @deprecated Deprecated in favour of POST /auth/login
   */
  login?: Maybe<Session>;
  /**
   * Signs a user out
   * @deprecated Deprecated in favour of DELETE /auth/logout
   */
  logout?: Maybe<User>;
  /** Enrols a company into the job slots experiment */
  manualEnrolIntoJobSlotExperiment: CompanyJobSlotData;
  /**
   * Creates a new application to a job as a signed out user
   * @deprecated Use update_job_application
   */
  publicCreateJobApplication?: Maybe<JobApplication>;
  pushCompanyToPro: Company;
  /** Update a conversation's read status */
  readMessage?: Maybe<ConversationMessage>;
  /** Refresh job application questions for a company */
  refreshJobApplicationQuestions: Scalars['Boolean']['output'];
  /** Refresh (delete and create) job recommendations for a user */
  refreshJobPerformanceStatistics: Scalars['Boolean']['output'];
  /**
   * Refresh (delete and create) job recommendations for a user
   * @deprecated No-op
   */
  refreshJobRecommendations: Array<JobRecommendation>;
  refreshLiveJobs: Scalars['Boolean']['output'];
  /** Refresh Otta Certfied for your company */
  refreshOttaCertifiedStatus: Scalars['Boolean']['output'];
  /** Register a user's device for mobile app push notifications */
  registerUserDevice: Scalars['String']['output'];
  /** Updates (remaps) a company's scraper_url and job's original_urls */
  remapCompanyJobs?: Maybe<Company>;
  /**
   * Remove the association between a acquitision_channel_type and a given company
   * @deprecated use update_company instead
   */
  removeAcquisitionChannelFromCompany: Company;
  /** Remove the association between an investor and a given company */
  removeInvestor?: Maybe<Company>;
  /** Remove the association between a language requirement and a given job */
  removeLanguageRequirement?: Maybe<Job>;
  /** Remove the association between a sector tag and a given company */
  removeSectorTag?: Maybe<Company>;
  /** Remove the association between a technology used and a given job */
  removeTechnologyUsed?: Maybe<Job>;
  removeUserPermission?: Maybe<User>;
  reportFakeProfile?: Maybe<Scalars['Boolean']['output']>;
  /** Request a company */
  requestCompany?: Maybe<Company>;
  /** Reset credentials for a company's ATS */
  resetAtsIntegration?: Maybe<Company>;
  /** Move sourced candidates back to talent pool */
  restoreCandidatesToPool?: Maybe<Job>;
  scrapeJobs: Scalars['Boolean']['output'];
  /** Send a candidate to a company's ATS for a specific job */
  sendCandidateToAts?: Maybe<Candidate>;
  /** Send an email verification email to a user */
  sendUserEmailVerification?: Maybe<User>;
  /** Set the technologies used for a given job */
  setTechnologiesUsed?: Maybe<Job>;
  /** Set user job offer accepted as an admin */
  setUserJobOfferAccepted?: Maybe<Job>;
  /** Stars a job */
  starJob?: Maybe<JobReaction>;
  /** Start integration with a company's ATS when the authentication is key based */
  startKeyAtsIntegration?: Maybe<Company>;
  /** Terminate a sale for a given company */
  terminateCompanySale?: Maybe<Company>;
  /** Triggers the double opt in email send */
  triggerOptIn: Scalars['Boolean']['output'];
  /** Unfollow company from user */
  unfollowCompany?: Maybe<User>;
  /** Unhide company from user */
  unhideCompany?: Maybe<PublicCompany>;
  /** Update a candidate's response to a context question */
  updateCandidateContextQuestionResponse?: Maybe<CandidateContextQuestionResponse>;
  /** Update a message request received by a candidate */
  updateCandidateMessageRequest?: Maybe<CandidateMessageRequest>;
  /** Update a message request by conversation id */
  updateCandidateMessageRequestByConversation?: Maybe<Conversation>;
  /** Update a pipeline item's stage */
  updateCandidatePipelineItemStage: JobPipeline;
  /** Update information about a company */
  updateCompany?: Maybe<Company>;
  /** Update an additional info bullet for a given company */
  updateCompanyAdditionalInfoBullet?: Maybe<CompanyAdditionalInfoBullet>;
  /** Update an article for a given company */
  updateCompanyArticle?: Maybe<CompanyArticle>;
  /** Update the metadata of a single brand asset */
  updateCompanyBrandAsset?: Maybe<CompanyBrandAsset>;
  /** Create a link between a company brand asset and something */
  updateCompanyBrandAssetLink?: Maybe<Company>;
  /** Update a candidate conversation as a company recruiter */
  updateCompanyConversation?: Maybe<CompanyConversation>;
  /** Update a diversity initiative for a given company */
  updateCompanyDiversityInitiative?: Maybe<CompanyDiversityInitiative>;
  /** Update a single employee survey's category response */
  updateCompanyEmployeeSurveyCategoryResponse?: Maybe<CompanyEmployeeSurveyCategoryResponse>;
  /** Update an employee survey's category responses */
  updateCompanyEmployeeSurveyCategoryResponses?: Maybe<CompanyEmployeeSurvey>;
  /** Approve or disapprove submitted employee survey */
  updateCompanyEmployeeSurveyIsApproved?: Maybe<CompanyEmployeeSurvey>;
  /** Update a employee survey that it has been submitted */
  updateCompanyEmployeeSurveySubmittedAt?: Maybe<CompanyEmployeeSurvey>;
  /** Update a founder for a given company */
  updateCompanyFounder?: Maybe<CompanyFounder>;
  /** Update a funding round for a given company */
  updateCompanyFundingRound?: Maybe<CompanyFundingRound>;
  /**
   * Update a job at the current recruiter's current company
   * @deprecated Use upsert_company_job
   */
  updateCompanyJob?: Maybe<Job>;
  /** Update a job application as a company recruiter */
  updateCompanyJobApplicationConversation?: Maybe<CompanyConversation>;
  /** Update a market bullet for a given company */
  updateCompanyMarketBullet?: Maybe<CompanyMarketBullet>;
  /** Update office for a given company */
  updateCompanyOffice?: Maybe<Company>;
  /** Update an other benefit bullet for a given company */
  updateCompanyOtherBenefit?: Maybe<CompanyOtherBenefit>;
  /** Update a person progressing for a given company */
  updateCompanyPersonProgressing?: Maybe<CompanyPersonProgressing>;
  /** Add or remove a product for a given company */
  updateCompanyProduct?: Maybe<Product>;
  /** Update a company recruiter's company role */
  updateCompanyRole?: Maybe<CompanyRecruiter>;
  updateCompanySamlData: CompanySamlData;
  /** Update a team for a given company */
  updateCompanyTeam?: Maybe<CompanyTeam>;
  /** Update a value for a given company */
  updateCompanyValue?: Maybe<CompanyValue>;
  /** Update the workflow status for a given company */
  updateCompanyWorkflowStatus?: Maybe<Company>;
  /** Update a conversation's read status */
  updateConversationMessageRead?: Maybe<CandidateConversationMessage>;
  /** Update the current candidate */
  updateCurrentCandidate?: Maybe<CurrentUser>;
  /** Update the current user */
  updateCurrentUser?: Maybe<User>;
  /** Update current users timezone by location */
  updateCurrentUserTimezone?: Maybe<User>;
  /** Update the current user via email */
  updateCurrentUserViaEmail?: Maybe<User>;
  /** Update an imported candidate */
  updateImportedCandidate: JobPipeline;
  /** Update a job */
  updateJob?: Maybe<Job>;
  /** Update the latest application of the user to a job */
  updateJobApplication?: Maybe<JobApplication>;
  /** Update whether a job application has been externally responded */
  updateJobApplicationExternalResponded?: Maybe<JobApplication>;
  /** Update an application process bullet for a given job */
  updateJobApplicationProcessBullet?: Maybe<JobApplicationProcessBullet>;
  /** Create or update the function for a given job */
  updateJobFunction?: Maybe<JobFunction>;
  /**
   * Update a candidate's rating feedback about a job interview
   * @deprecated replaced by check interview feedback received
   */
  updateJobInterviewCandidateRatingFeedback?: Maybe<JobInterviewCandidateFeedback>;
  /** Update job interview feedback received */
  updateJobInterviewFeedbackReceived?: Maybe<JobInterview>;
  /** Update an involves bullet for a given job */
  updateJobInvolvesBullet?: Maybe<JobInvolvesBullet>;
  /** Update a job offer as a company recruiter */
  updateJobOffer: JobOffer;
  /** Update a requirement for a given job */
  updateJobRequirement?: Maybe<JobRequirement>;
  /** Create or update the sub-function for a given job */
  updateJobSubFunction?: Maybe<JobSubFunction>;
  /** Update the workflow status for a given job */
  updateJobWorkflowStatus?: Maybe<Job>;
  /** Update the workflow status for a batch of jobs */
  updateJobWorkflowStatusBatch: Array<Job>;
  updateLastActive?: Maybe<User>;
  /** Update a message template */
  updateMessageTemplate: MessageTemplate;
  /** Updates a user's opt in preferences */
  updateOptInPreferences: Scalars['Boolean']['output'];
  /** Update people breakdown for eb_statistics */
  updatePeopleBreakdown?: Maybe<EbStatistics>;
  /** Update a pipeline item's stage */
  updatePipelineItemStage: JobPipeline;
  /** Update a pipeline stage */
  updatePipelineStage: JobPipeline;
  /**
   * Update a salary preference
   * @deprecated use upsert_salary_preference
   */
  updateSalaryPreference?: Maybe<SalaryPreference>;
  /** Hide or show the salary for a job */
  updateSalaryVisibility?: Maybe<Job>;
  /** Update a user's status for a given shortlist job */
  updateShortlistUserJobStatus: Job;
  /** Approve or disapprove submitted salaries */
  updateSubmittedSalary?: Maybe<SalaryPreference>;
  /** Update a user's activated status */
  updateUserActivatedStatus?: Maybe<User>;
  /** Update a user's current company */
  updateUserCurrentCompany?: Maybe<User>;
  /** Update a user's education experience by ID */
  updateUserEducationExperience?: Maybe<UserEducationExperience>;
  /** Update a user's work and education experiences */
  updateUserExperiences?: Maybe<User>;
  /** Update a user's job notification subscription status */
  updateUserJobNotificationUnsubscription: Scalars['Boolean']['output'];
  updateUserJobOfferStatus?: Maybe<User>;
  /**
   * Update a user's last active time
   * @deprecated automatically updated when fetching currentUser
   */
  updateUserLastActive?: Maybe<User>;
  /** Update a user's job email notification preferences */
  updateUserNotificationPreferences?: Maybe<User>;
  /** Update a user's project by ID */
  updateUserProject?: Maybe<UserProject>;
  /** Update a user's role */
  updateUserRole?: Maybe<User>;
  /** Update a user's work experience by ID */
  updateUserWorkExperience?: Maybe<UserWorkExperience>;
  /** Update permissions to view salary for a job */
  updateViewSalaryPermission?: Maybe<Job>;
  /** Update a suggestion's status for a user's work experience by suggestion ID */
  updateWorkExperienceSuggestionStatus?: Maybe<UserWorkExperienceSuggestion>;
  /** Upload a bunch of brand assets for a company */
  uploadCompanyBrandAssets?: Maybe<Company>;
  /** Upload an email-compatible logo for a given company */
  uploadCompanyEmailLogo?: Maybe<Company>;
  /** Upload an favicon for a given company */
  uploadCompanyFavicon?: Maybe<Company>;
  /** Upload a logo for a given company */
  uploadCompanyLogo?: Maybe<Company>;
  /** Upload a photo for a given company */
  uploadCompanyPhoto?: Maybe<Company>;
  uploadCv: User;
  /** Upload an image */
  uploadImage?: Maybe<File>;
  /** Upload a profile image for the current user */
  uploadUserProfileImage?: Maybe<User>;
  /** Upsert a company ATS API key */
  upsertCompanyAtsApiKey: CompanyAtsApiKey;
  /** Upsert a job at the current recruiter's current company */
  upsertCompanyJob?: Maybe<Job>;
  upsertJobApplicationAtsQuestionAnswers: AtsQuestionsV1;
  /**
   * Upsert job application data
   * @deprecated Use upsert_job_application_ats_question_answers
   */
  upsertJobApplicationData: JobApplicationData;
  /**
   * Upsert a response to a job application question
   * @deprecated Use upsert_job_application_ats_question_answers
   */
  upsertJobApplicationQuestionResponse?: Maybe<JobApplicationQuestionResponse>;
  upsertJobSlotChoices?: Maybe<Company>;
  /** Upsert a pipeline item note */
  upsertPipelineItemNote: CandidatePipelineItemNote;
  /** Upsert a salary preference */
  upsertSalaryPreference?: Maybe<SalaryPreference>;
  /** Profile viewed by a user */
  userProfileViewed?: Maybe<UserProfileView>;
  /** Verify a user's email with a token */
  verifyEmail?: Maybe<User>;
};


export type RootMutationTypeAcceptJobPreferenceSuggestionArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeAddAcquisitionChannelToCompanyArgs = {
  companyId: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};


export type RootMutationTypeAddCompanySaleArgs = {
  companyId: Scalars['ID']['input'];
  sale: CompanySaleInput;
};


export type RootMutationTypeAddJobSlotArgs = {
  quantity: Scalars['Int']['input'];
};


export type RootMutationTypeAddJobsToCompanySubscriptionArgs = {
  jobIds: Array<Scalars['ID']['input']>;
};


export type RootMutationTypeAddUserPermissionArgs = {
  permissionId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type RootMutationTypeApproveCompanyArgs = {
  companyId: Scalars['ID']['input'];
};


export type RootMutationTypeArchiveCompanyJobArgs = {
  autoDecline?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};


export type RootMutationTypeAuthorizeAtsIntegrationArgs = {
  ats: CompanyAts;
  code: Scalars['String']['input'];
};


export type RootMutationTypeAutoPopulatePreferencesArgs = {
  jobExternalId: Scalars['ID']['input'];
};


export type RootMutationTypeBatchUpdateCandidatePipelineItemStageArgs = {
  itemIds: Array<Scalars['ID']['input']>;
  jobId: Scalars['ID']['input'];
  newStageId: Scalars['ID']['input'];
};


export type RootMutationTypeBulkEnrolIntoJobSlotsArgs = {
  companyIds: Array<Scalars['ID']['input']>;
};


export type RootMutationTypeBulkMessageCandidatesAndUpdateStageArgs = {
  candidateIds: Array<Scalars['ID']['input']>;
  content: Scalars['String']['input'];
  itemIds: Array<Scalars['ID']['input']>;
  jobId: Scalars['ID']['input'];
  newStageId?: InputMaybe<Scalars['ID']['input']>;
};


export type RootMutationTypeChangePasswordArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type RootMutationTypeConfirmJobLiveArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeConvertCurrentUserToRecruiterArgs = {
  termsConditionsConsent?: InputMaybe<Scalars['Boolean']['input']>;
};


export type RootMutationTypeCreateCandidateContextQuestionResponseArgs = {
  featuredAnswer?: InputMaybe<Scalars['Boolean']['input']>;
  questionId: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};


export type RootMutationTypeCreateCandidateConversationMessageArgs = {
  content: Scalars['String']['input'];
  conversationId: Scalars['ID']['input'];
};


export type RootMutationTypeCreateCandidateCvPdfArgs = {
  candidateId?: InputMaybe<Scalars['ID']['input']>;
  jobId?: InputMaybe<Scalars['ID']['input']>;
};


export type RootMutationTypeCreateCandidateMessageRequestArgs = {
  candidateId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
  message: Scalars['String']['input'];
  messageTemplateId?: InputMaybe<Scalars['ID']['input']>;
};


export type RootMutationTypeCreateCandidateReactionArgs = {
  candidateId: Scalars['ID']['input'];
  direction: Scalars['Boolean']['input'];
  jobId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};


export type RootMutationTypeCreateCompanyArgs = {
  name: Scalars['String']['input'];
};


export type RootMutationTypeCreateCompanyAdditionalInfoBulletArgs = {
  companyId: Scalars['ID']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['String']['input'];
};


export type RootMutationTypeCreateCompanyArticleArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanyArticleInput;
};


export type RootMutationTypeCreateCompanyBrandAssetArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanyBrandAssetInput;
};


export type RootMutationTypeCreateCompanyBrandAssetLinkArgs = {
  brandAssetId: Scalars['ID']['input'];
  input: CompanyBrandAssetLinkInput;
};


export type RootMutationTypeCreateCompanyCustomerPortalSessionArgs = {
  action?: InputMaybe<CompanySubscriptionPortalAction>;
  returnPath: Scalars['String']['input'];
};


export type RootMutationTypeCreateCompanyDiversityInitiativeArgs = {
  companyId: Scalars['ID']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['String']['input'];
};


export type RootMutationTypeCreateCompanyEmployeeSurveyArgs = {
  companyId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  jobFunctionId: Scalars['ID']['input'];
};


export type RootMutationTypeCreateCompanyFounderArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanyFounderInput;
};


export type RootMutationTypeCreateCompanyFundingRoundArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanyFundingRoundInput;
};


export type RootMutationTypeCreateCompanyMarketBulletArgs = {
  companyId: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};


export type RootMutationTypeCreateCompanyOfficeArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanyOfficeInput;
};


export type RootMutationTypeCreateCompanyOtherBenefitArgs = {
  companyId: Scalars['ID']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['String']['input'];
};


export type RootMutationTypeCreateCompanyPersonProgressingArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanyPersonProgressingInput;
};


export type RootMutationTypeCreateCompanyRecruiterUserArgs = {
  input: NewUserInput;
};


export type RootMutationTypeCreateCompanySubscriptionCheckoutSessionArgs = {
  product: CatalogueProductId;
  quantity: Scalars['Int']['input'];
  returnPath: Scalars['String']['input'];
};


export type RootMutationTypeCreateCompanyTeamArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanyTeamInput;
};


export type RootMutationTypeCreateCompanyValueArgs = {
  companyId: Scalars['ID']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['String']['input'];
};


export type RootMutationTypeCreateGlobalUserSuppressionsArgs = {
  source: Scalars['String']['input'];
};


export type RootMutationTypeCreateImportedCandidateArgs = {
  input: ImportedCandidateInput;
};


export type RootMutationTypeCreateInvestorArgs = {
  companyId: Scalars['ID']['input'];
  topInvestor?: InputMaybe<Scalars['Boolean']['input']>;
  value: Scalars['String']['input'];
};


export type RootMutationTypeCreateJobArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  inToolbox?: InputMaybe<Scalars['Boolean']['input']>;
  input: JobInput;
};


export type RootMutationTypeCreateJobApplicationProcessBulletArgs = {
  jobId: Scalars['ID']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['String']['input'];
  workflowStatus?: InputMaybe<JobWorkflowStatus>;
};


export type RootMutationTypeCreateJobBroadcastArgs = {
  jobId: Scalars['ID']['input'];
  message: Scalars['String']['input'];
};


export type RootMutationTypeCreateJobHireArgs = {
  jobId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type RootMutationTypeCreateJobInvolvesBulletArgs = {
  jobId: Scalars['ID']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['String']['input'];
  workflowStatus?: InputMaybe<JobWorkflowStatus>;
};


export type RootMutationTypeCreateJobProblemArgs = {
  content: Scalars['String']['input'];
  jobId: Scalars['ID']['input'];
};


export type RootMutationTypeCreateJobReactionArgs = {
  direction: Scalars['Boolean']['input'];
  jobId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};


export type RootMutationTypeCreateJobRequirementArgs = {
  desirable?: InputMaybe<Scalars['Boolean']['input']>;
  jobId: Scalars['ID']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['String']['input'];
  workflowStatus?: InputMaybe<JobWorkflowStatus>;
};


export type RootMutationTypeCreateJobSlotCheckoutSessionArgs = {
  jobIds: Array<Scalars['ID']['input']>;
  product: CatalogueProductId;
  returnPath: Scalars['String']['input'];
};


export type RootMutationTypeCreateLanguageRequirementArgs = {
  jobId: Scalars['ID']['input'];
  value: Scalars['String']['input'];
  workflowStatus?: InputMaybe<JobWorkflowStatus>;
};


export type RootMutationTypeCreateMessageTemplateArgs = {
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type?: InputMaybe<MessageTemplateTypeEnum>;
};


export type RootMutationTypeCreatePipelineStageArgs = {
  insertAfterStageId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


export type RootMutationTypeCreateScrapeRequestArgs = {
  url: Scalars['String']['input'];
};


export type RootMutationTypeCreateSectorTagArgs = {
  companyId: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};


export type RootMutationTypeCreateTechnologyUsedArgs = {
  jobId: Scalars['ID']['input'];
  value: Scalars['String']['input'];
  workflowStatus?: InputMaybe<JobWorkflowStatus>;
};


export type RootMutationTypeCreateUserArgs = {
  input: NewUserInput;
};


export type RootMutationTypeCreateUserEducationExperienceArgs = {
  input: UserEducationExperienceInput;
};


export type RootMutationTypeCreateUserProjectArgs = {
  image?: InputMaybe<Scalars['Upload']['input']>;
  input: UserProjectInput;
};


export type RootMutationTypeCreateUserSuppressionArgs = {
  channelId: Scalars['ID']['input'];
  source: Scalars['String']['input'];
  unsubscribeGroupId: Scalars['ID']['input'];
};


export type RootMutationTypeCreateUserWorkExperienceArgs = {
  input: UserWorkExperienceInput;
};


export type RootMutationTypeCreateWorkExperienceSuggestionArgs = {
  workExperienceId: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteAtsApiKeysArgs = {
  companyId: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteCandidateContextQuestionResponseArgs = {
  responseId: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteCompanyAdditionalInfoBulletArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteCompanyArticleArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteCompanyAtsApiKeyArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteCompanyBrandAssetLinkArgs = {
  brandAssetLinkId: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteCompanyDiversityInitiativeArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteCompanyFounderArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteCompanyFundingRoundArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteCompanyMarketBulletArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteCompanyOfficeArgs = {
  officeId: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteCompanyOtherBenefitArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteCompanyPersonProgressingArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteCompanyPhotoArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteCompanyTeamArgs = {
  teamId: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteCompanyValueArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteJobApplicationProcessBulletArgs = {
  id: Scalars['ID']['input'];
  workflowStatus?: InputMaybe<JobWorkflowStatus>;
};


export type RootMutationTypeDeleteJobApplicationQuestionResponseArgs = {
  responseId: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteJobInvolvesBulletArgs = {
  id: Scalars['ID']['input'];
  workflowStatus?: InputMaybe<JobWorkflowStatus>;
};


export type RootMutationTypeDeleteJobReactionsArgs = {
  userId: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteJobRecommendationArgs = {
  jobId: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteJobRecommendationsArgs = {
  jobId?: InputMaybe<Scalars['ID']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};


export type RootMutationTypeDeleteJobRequirementArgs = {
  id: Scalars['ID']['input'];
  workflowStatus?: InputMaybe<JobWorkflowStatus>;
};


export type RootMutationTypeDeleteMessageTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeletePipelineItemNoteArgs = {
  itemId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
  noteId: Scalars['ID']['input'];
};


export type RootMutationTypeDeletePipelineStageArgs = {
  jobId: Scalars['ID']['input'];
  stageId: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteUserArgs = {
  userId: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteUserEducationExperienceArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteUserProjectArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteUserSuppressionArgs = {
  channelId: Scalars['ID']['input'];
  unsubscribeGroupId: Scalars['ID']['input'];
};


export type RootMutationTypeDeleteUserWorkExperienceArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDeregisterUserDeviceArgs = {
  devicePlatform: DevicePlatform;
  deviceToken: Scalars['String']['input'];
};


export type RootMutationTypeDisapproveCompanyArgs = {
  companyId: Scalars['ID']['input'];
};


export type RootMutationTypeDismissJobPreferenceSuggestionArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeDismissNotificationArgs = {
  notificationName: Scalars['String']['input'];
};


export type RootMutationTypeEnrolIntoJobSlotExperimentArgs = {
  userId: Scalars['ID']['input'];
};


export type RootMutationTypeFollowCompanyArgs = {
  companyId: Scalars['ID']['input'];
  jobId?: InputMaybe<Scalars['ID']['input']>;
};


export type RootMutationTypeForgotPasswordArgs = {
  email: Scalars['String']['input'];
};


export type RootMutationTypeHideCompanyArgs = {
  companyId: Scalars['ID']['input'];
};


export type RootMutationTypeLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type RootMutationTypeLogoutArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeManualEnrolIntoJobSlotExperimentArgs = {
  cohortName: Scalars['String']['input'];
  companyId: Scalars['ID']['input'];
  slots: Scalars['Int']['input'];
};


export type RootMutationTypePublicCreateJobApplicationArgs = {
  jobId: Scalars['ID']['input'];
  userExternalId: Scalars['ID']['input'];
};


export type RootMutationTypePushCompanyToProArgs = {
  companyId: Scalars['ID']['input'];
};


export type RootMutationTypeReadMessageArgs = {
  id: Scalars['ID']['input'];
};


export type RootMutationTypeRefreshJobApplicationQuestionsArgs = {
  companyId: Scalars['ID']['input'];
};


export type RootMutationTypeRefreshJobRecommendationsArgs = {
  ignoreSubfunctions?: InputMaybe<Scalars['Boolean']['input']>;
};


export type RootMutationTypeRefreshLiveJobsArgs = {
  companyId: Scalars['ID']['input'];
};


export type RootMutationTypeRegisterUserDeviceArgs = {
  devicePlatform: DevicePlatform;
  deviceToken: Scalars['String']['input'];
};


export type RootMutationTypeRemapCompanyJobsArgs = {
  id: Scalars['ID']['input'];
  jobs: Array<RemapJobInput>;
  moveToInternal: Scalars['Boolean']['input'];
  scraperUrl: Scalars['String']['input'];
};


export type RootMutationTypeRemoveAcquisitionChannelFromCompanyArgs = {
  acquisitionChannelId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
};


export type RootMutationTypeRemoveInvestorArgs = {
  companyId: Scalars['ID']['input'];
  investorId: Scalars['ID']['input'];
};


export type RootMutationTypeRemoveLanguageRequirementArgs = {
  jobId: Scalars['ID']['input'];
  languageRequirementId: Scalars['ID']['input'];
  workflowStatus?: InputMaybe<JobWorkflowStatus>;
};


export type RootMutationTypeRemoveSectorTagArgs = {
  companyId: Scalars['ID']['input'];
  sectorTagId: Scalars['ID']['input'];
};


export type RootMutationTypeRemoveTechnologyUsedArgs = {
  jobId: Scalars['ID']['input'];
  technologyUsedId: Scalars['ID']['input'];
  workflowStatus?: InputMaybe<JobWorkflowStatus>;
};


export type RootMutationTypeRemoveUserPermissionArgs = {
  permissionId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type RootMutationTypeReportFakeProfileArgs = {
  jobId?: InputMaybe<Scalars['ID']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  suspectEmail: Scalars['String']['input'];
};


export type RootMutationTypeRequestCompanyArgs = {
  input: RequestCompanyInput;
};


export type RootMutationTypeResetAtsIntegrationArgs = {
  companyId: Scalars['ID']['input'];
};


export type RootMutationTypeRestoreCandidatesToPoolArgs = {
  jobId: Scalars['ID']['input'];
};


export type RootMutationTypeScrapeJobsArgs = {
  companyId: Scalars['ID']['input'];
};


export type RootMutationTypeSendCandidateToAtsArgs = {
  candidateId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
};


export type RootMutationTypeSendUserEmailVerificationArgs = {
  email: Scalars['String']['input'];
};


export type RootMutationTypeSetTechnologiesUsedArgs = {
  jobId: Scalars['ID']['input'];
  values: Array<Scalars['String']['input']>;
  workflowStatus?: InputMaybe<JobWorkflowStatus>;
};


export type RootMutationTypeSetUserJobOfferAcceptedArgs = {
  jobId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type RootMutationTypeStarJobArgs = {
  jobId: Scalars['ID']['input'];
};


export type RootMutationTypeStartKeyAtsIntegrationArgs = {
  key: Scalars['String']['input'];
  type: CompanyAtsApiKeyTypeEnum;
};


export type RootMutationTypeTerminateCompanySaleArgs = {
  endAt?: InputMaybe<Scalars['NaiveDateTime']['input']>;
  saleId?: InputMaybe<Scalars['ID']['input']>;
  saleProductVariantId?: InputMaybe<Scalars['ID']['input']>;
  type: CompanySaleTerminationType;
};


export type RootMutationTypeUnfollowCompanyArgs = {
  companyId: Scalars['ID']['input'];
  jobId?: InputMaybe<Scalars['ID']['input']>;
};


export type RootMutationTypeUnhideCompanyArgs = {
  companyId: Scalars['ID']['input'];
};


export type RootMutationTypeUpdateCandidateContextQuestionResponseArgs = {
  featuredAnswer?: InputMaybe<Scalars['Boolean']['input']>;
  questionId?: InputMaybe<Scalars['ID']['input']>;
  responseId: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};


export type RootMutationTypeUpdateCandidateMessageRequestArgs = {
  accepted: Scalars['Boolean']['input'];
  candidateResponse: Scalars['String']['input'];
  candidateViewed?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};


export type RootMutationTypeUpdateCandidateMessageRequestByConversationArgs = {
  accepted: Scalars['Boolean']['input'];
  candidateResponse: Scalars['String']['input'];
  candidateViewed?: InputMaybe<Scalars['Boolean']['input']>;
  conversationId: Scalars['ID']['input'];
};


export type RootMutationTypeUpdateCandidatePipelineItemStageArgs = {
  itemId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
  stageId: Scalars['ID']['input'];
};


export type RootMutationTypeUpdateCompanyArgs = {
  id: Scalars['ID']['input'];
  input: CompanyInput;
};


export type RootMutationTypeUpdateCompanyAdditionalInfoBulletArgs = {
  id: Scalars['ID']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['String']['input'];
};


export type RootMutationTypeUpdateCompanyArticleArgs = {
  id: Scalars['ID']['input'];
  input: CompanyArticleInput;
};


export type RootMutationTypeUpdateCompanyBrandAssetArgs = {
  brandAssetId: Scalars['ID']['input'];
  input: CompanyBrandAssetInput;
};


export type RootMutationTypeUpdateCompanyBrandAssetLinkArgs = {
  brandAssetLinkId: Scalars['ID']['input'];
  input: CompanyBrandAssetLinkInput;
};


export type RootMutationTypeUpdateCompanyConversationArgs = {
  id: Scalars['ID']['input'];
  qualified?: InputMaybe<Scalars['Boolean']['input']>;
};


export type RootMutationTypeUpdateCompanyDiversityInitiativeArgs = {
  id: Scalars['ID']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['String']['input'];
};


export type RootMutationTypeUpdateCompanyEmployeeSurveyCategoryResponseArgs = {
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  surveyExternalId: Scalars['ID']['input'];
};


export type RootMutationTypeUpdateCompanyEmployeeSurveyCategoryResponsesArgs = {
  responses: Array<CompanyEmployeeSurveyCategoryResponseInput>;
  surveyExternalId: Scalars['ID']['input'];
};


export type RootMutationTypeUpdateCompanyEmployeeSurveyIsApprovedArgs = {
  id: Scalars['ID']['input'];
  isApproved: Scalars['Boolean']['input'];
};


export type RootMutationTypeUpdateCompanyEmployeeSurveySubmittedAtArgs = {
  externalId: Scalars['ID']['input'];
};


export type RootMutationTypeUpdateCompanyFounderArgs = {
  id: Scalars['ID']['input'];
  input: CompanyFounderInput;
};


export type RootMutationTypeUpdateCompanyFundingRoundArgs = {
  id: Scalars['ID']['input'];
  input: CompanyFundingRoundInput;
};


export type RootMutationTypeUpdateCompanyJobArgs = {
  id: Scalars['ID']['input'];
  input: CompanyJobInput;
  isPreview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type RootMutationTypeUpdateCompanyJobApplicationConversationArgs = {
  id: Scalars['ID']['input'];
  input: CompanyJobApplicationInput;
};


export type RootMutationTypeUpdateCompanyMarketBulletArgs = {
  id: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};


export type RootMutationTypeUpdateCompanyOfficeArgs = {
  input: CompanyOfficeInput;
  officeId: Scalars['ID']['input'];
};


export type RootMutationTypeUpdateCompanyOtherBenefitArgs = {
  id: Scalars['ID']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};


export type RootMutationTypeUpdateCompanyPersonProgressingArgs = {
  id: Scalars['ID']['input'];
  input: CompanyPersonProgressingInput;
};


export type RootMutationTypeUpdateCompanyProductArgs = {
  active: Scalars['Boolean']['input'];
  companyId: Scalars['ID']['input'];
  productId: Scalars['ID']['input'];
};


export type RootMutationTypeUpdateCompanyRoleArgs = {
  newRole: CompanyRoleType;
  userId: Scalars['ID']['input'];
};


export type RootMutationTypeUpdateCompanySamlDataArgs = {
  input: CompanySamlDataInput;
};


export type RootMutationTypeUpdateCompanyTeamArgs = {
  input: CompanyTeamInput;
  teamId: Scalars['ID']['input'];
};


export type RootMutationTypeUpdateCompanyValueArgs = {
  id: Scalars['ID']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['String']['input'];
};


export type RootMutationTypeUpdateCompanyWorkflowStatusArgs = {
  companyId: Scalars['ID']['input'];
  workflowStatus: CompanyWorkflowStatus;
};


export type RootMutationTypeUpdateConversationMessageReadArgs = {
  messageId: Scalars['ID']['input'];
  read: Scalars['Boolean']['input'];
};


export type RootMutationTypeUpdateCurrentCandidateArgs = {
  input: UserInput;
};


export type RootMutationTypeUpdateCurrentUserArgs = {
  input: UserInput;
};


export type RootMutationTypeUpdateCurrentUserTimezoneArgs = {
  location?: InputMaybe<Scalars['String']['input']>;
};


export type RootMutationTypeUpdateCurrentUserViaEmailArgs = {
  hash: Scalars['String']['input'];
  input: UserInputViaEmail;
  userExternalId: Scalars['ID']['input'];
};


export type RootMutationTypeUpdateImportedCandidateArgs = {
  input: ImportedCandidateInput;
};


export type RootMutationTypeUpdateJobArgs = {
  id: Scalars['ID']['input'];
  input: JobInput;
  workflowStatus?: InputMaybe<JobWorkflowStatus>;
};


export type RootMutationTypeUpdateJobApplicationArgs = {
  input: JobApplicationInput;
  jobId: Scalars['ID']['input'];
};


export type RootMutationTypeUpdateJobApplicationExternalRespondedArgs = {
  externalId: Scalars['ID']['input'];
  externalResponded?: InputMaybe<Scalars['Boolean']['input']>;
};


export type RootMutationTypeUpdateJobApplicationProcessBulletArgs = {
  id: Scalars['ID']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['String']['input'];
  workflowStatus?: InputMaybe<JobWorkflowStatus>;
};


export type RootMutationTypeUpdateJobFunctionArgs = {
  jobId: Scalars['ID']['input'];
  value: Scalars['String']['input'];
  workflowStatus?: InputMaybe<JobWorkflowStatus>;
};


export type RootMutationTypeUpdateJobInterviewCandidateRatingFeedbackArgs = {
  input: JobInterviewCandidateFeedbackInput;
  jobExternalId: Scalars['ID']['input'];
  userExternalId: Scalars['ID']['input'];
};


export type RootMutationTypeUpdateJobInterviewFeedbackReceivedArgs = {
  feedbackReceived: InterviewFeedbackReceived;
  id: Scalars['ID']['input'];
};


export type RootMutationTypeUpdateJobInvolvesBulletArgs = {
  id: Scalars['ID']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['String']['input'];
  workflowStatus?: InputMaybe<JobWorkflowStatus>;
};


export type RootMutationTypeUpdateJobOfferArgs = {
  input?: InputMaybe<JobOfferInput>;
  jobId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type RootMutationTypeUpdateJobRequirementArgs = {
  desirable?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  workflowStatus?: InputMaybe<JobWorkflowStatus>;
};


export type RootMutationTypeUpdateJobSubFunctionArgs = {
  jobId: Scalars['ID']['input'];
  value: Scalars['String']['input'];
  workflowStatus?: InputMaybe<JobWorkflowStatus>;
};


export type RootMutationTypeUpdateJobWorkflowStatusArgs = {
  jobId: Scalars['ID']['input'];
  workflowStatus: JobWorkflowStatus;
};


export type RootMutationTypeUpdateJobWorkflowStatusBatchArgs = {
  jobIds: Array<Scalars['ID']['input']>;
  workflowStatus: JobWorkflowStatus;
};


export type RootMutationTypeUpdateMessageTemplateArgs = {
  id: Scalars['ID']['input'];
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type?: InputMaybe<MessageTemplateTypeEnum>;
};


export type RootMutationTypeUpdateOptInPreferencesArgs = {
  optInStatus: OptInStatusEnum;
};


export type RootMutationTypeUpdatePeopleBreakdownArgs = {
  companyId: Scalars['ID']['input'];
  peopleBreakdown: PeopleBreakdownInput;
};


export type RootMutationTypeUpdatePipelineItemStageArgs = {
  candidateId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
  stageId: Scalars['ID']['input'];
};


export type RootMutationTypeUpdatePipelineStageArgs = {
  jobId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  stageId: Scalars['ID']['input'];
};


export type RootMutationTypeUpdateSalaryPreferenceArgs = {
  input: SalaryPreferenceInput;
};


export type RootMutationTypeUpdateSalaryVisibilityArgs = {
  hideSalary: Scalars['Boolean']['input'];
  jobId: Scalars['ID']['input'];
};


export type RootMutationTypeUpdateShortlistUserJobStatusArgs = {
  jobId: Scalars['ID']['input'];
  status: UserJobStatus;
};


export type RootMutationTypeUpdateSubmittedSalaryArgs = {
  id: Scalars['ID']['input'];
  input: SubmittedSalaryInput;
};


export type RootMutationTypeUpdateUserActivatedStatusArgs = {
  activated: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};


export type RootMutationTypeUpdateUserCurrentCompanyArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};


export type RootMutationTypeUpdateUserEducationExperienceArgs = {
  id: Scalars['ID']['input'];
  input: UserEducationExperienceInput;
};


export type RootMutationTypeUpdateUserExperiencesArgs = {
  educationExperiences: Array<UserEducationExperienceInput>;
  id: Scalars['ID']['input'];
  workExperiences: Array<UserWorkExperienceInput>;
};


export type RootMutationTypeUpdateUserJobNotificationUnsubscriptionArgs = {
  jobId: Scalars['ID']['input'];
  unsubscribe?: InputMaybe<Scalars['Boolean']['input']>;
};


export type RootMutationTypeUpdateUserJobOfferStatusArgs = {
  jobId: Scalars['ID']['input'];
  status: JobOfferStatus;
  userId: Scalars['ID']['input'];
};


export type RootMutationTypeUpdateUserLastActiveArgs = {
  externalId: Scalars['ID']['input'];
};


export type RootMutationTypeUpdateUserNotificationPreferencesArgs = {
  externalId?: InputMaybe<Scalars['ID']['input']>;
  frequency: NotificationFrequency;
};


export type RootMutationTypeUpdateUserProjectArgs = {
  id: Scalars['ID']['input'];
  image?: InputMaybe<Scalars['Upload']['input']>;
  input?: InputMaybe<UserProjectInput>;
};


export type RootMutationTypeUpdateUserRoleArgs = {
  id: Scalars['ID']['input'];
  role: RoleType;
};


export type RootMutationTypeUpdateUserWorkExperienceArgs = {
  id: Scalars['ID']['input'];
  input: UserWorkExperienceInput;
};


export type RootMutationTypeUpdateViewSalaryPermissionArgs = {
  jobId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};


export type RootMutationTypeUpdateWorkExperienceSuggestionStatusArgs = {
  status: WorkExperienceSuggestionStatus;
  suggestionId: Scalars['ID']['input'];
};


export type RootMutationTypeUploadCompanyBrandAssetsArgs = {
  assets: Array<Scalars['Upload']['input']>;
  companyId: Scalars['ID']['input'];
};


export type RootMutationTypeUploadCompanyEmailLogoArgs = {
  companyId: Scalars['ID']['input'];
  logo: Scalars['Upload']['input'];
};


export type RootMutationTypeUploadCompanyFaviconArgs = {
  companyId: Scalars['ID']['input'];
  favicon: Scalars['Upload']['input'];
};


export type RootMutationTypeUploadCompanyLogoArgs = {
  companyId: Scalars['ID']['input'];
  logo: Scalars['Upload']['input'];
  prfix?: InputMaybe<Scalars['Boolean']['input']>;
};


export type RootMutationTypeUploadCompanyPhotoArgs = {
  companyId: Scalars['ID']['input'];
  image: Scalars['Upload']['input'];
};


export type RootMutationTypeUploadCvArgs = {
  file: Scalars['Upload']['input'];
};


export type RootMutationTypeUploadImageArgs = {
  category?: InputMaybe<ImageCategory>;
  image: Scalars['Upload']['input'];
  prefix?: InputMaybe<Scalars['Boolean']['input']>;
};


export type RootMutationTypeUploadUserProfileImageArgs = {
  image: Scalars['Upload']['input'];
};


export type RootMutationTypeUpsertCompanyAtsApiKeyArgs = {
  companyId: Scalars['ID']['input'];
  key: Scalars['String']['input'];
  type: CompanyAtsApiKeyTypeEnum;
};


export type RootMutationTypeUpsertCompanyJobArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  input: CompanyJobInput;
  isPreview?: InputMaybe<Scalars['Boolean']['input']>;
};


export type RootMutationTypeUpsertJobApplicationAtsQuestionAnswersArgs = {
  answers: Array<AtsQuestionAnswerInput>;
  atsQuestionDataExternalId: Scalars['ID']['input'];
  jobExternalId: Scalars['ID']['input'];
};


export type RootMutationTypeUpsertJobApplicationDataArgs = {
  data: JobApplicationDataInput;
  jobApplicationId: Scalars['ID']['input'];
};


export type RootMutationTypeUpsertJobApplicationQuestionResponseArgs = {
  atsQuestionId?: InputMaybe<Scalars['String']['input']>;
  input?: InputMaybe<ApplicationQuestionResponseInput>;
  jobId: Scalars['ID']['input'];
  question: Scalars['String']['input'];
};


export type RootMutationTypeUpsertJobSlotChoicesArgs = {
  jobIds: Array<Scalars['ID']['input']>;
};


export type RootMutationTypeUpsertPipelineItemNoteArgs = {
  content: Scalars['String']['input'];
  itemId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
};


export type RootMutationTypeUpsertSalaryPreferenceArgs = {
  input: SalaryPreferenceInput;
};


export type RootMutationTypeUserProfileViewedArgs = {
  jobApplicationId: Scalars['ID']['input'];
};


export type RootMutationTypeVerifyEmailArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  allBrandAssetPlacements: Array<BrandAssetPlacement>;
  /** Get a list of all the products available */
  allBundles?: Maybe<Array<Bundle>>;
  /** Get a list of all the products available */
  allProducts?: Maybe<Array<Product>>;
  /** Get a specific candidate by external ID */
  candidate?: Maybe<Candidate>;
  /** Get all of the candidate context questions */
  candidateContextQuestions?: Maybe<Array<CandidateContextQuestion>>;
  /** Get a received message request by it's job ID */
  candidateReceivedMessageRequest?: Maybe<CandidateMessageRequest>;
  /** Get the shortlist of candidates for a specific job */
  candidateShortlist?: Maybe<Array<Candidate>>;
  catalogue: Catalogue;
  /** Get a list of all companies */
  companies: Array<PublicCompany>;
  /** Get a list of all companies by external id */
  companiesByExternalId: Array<Company>;
  /** Get information about a specific company */
  company?: Maybe<Company>;
  /** Get all acquisition_channels */
  companyAcquisitionChannels: Array<AcquisitionChannel>;
  /** Get boost values */
  companyBoost: Boost;
  /** Get information about a specific company by name */
  companyByName?: Maybe<Company>;
  /**
   * Get a company conversation by its ID
   * @deprecated Use company_recruiter_conversation instead
   */
  companyConversation: CompanyConversation;
  /** Get a single employee survey */
  companyEmployeeSurvey?: Maybe<CompanyEmployeeSurvey>;
  /** Get a list of all employee surveys pending for approval */
  companyEmployeeSurveysPendingApproval: Array<CompanyEmployeeSurvey>;
  /** Get a specific job by external ID */
  companyJob?: Maybe<Job>;
  /** Get a list of all company qualities */
  companyQualities: Array<CompanyQuality>;
  /** Get a conversation by job_id and candidate_id */
  companyRecruiterConversation?: Maybe<CompanyConversation>;
  /**
   * Gets company recruiter users with specific workflow statuses
   * @deprecated Use users with role arg instead
   */
  companyRecruiters: Array<User>;
  /** Get all of the unique company sizes */
  companySizes: Array<CompanySize>;
  /** Get a list of all company sub qualities */
  companySubQualities: Array<CompanySubQuality>;
  /** Get a candidate conversation by its ID */
  conversation: Conversation;
  /** Gets the currently logged in user */
  currentUser?: Maybe<User>;
  /** Get the discarded list of candidates for a specific job */
  discardedCandidates?: Maybe<Array<Candidate>>;
  /** Get all feature toggle definitions */
  featureFlags: Array<FeatureFlag>;
  /** Find imported candidate by email */
  findImportedCandidate?: Maybe<ImportedCandidate>;
  /** Get all of the sub-functions under a job function */
  functionSubFunctions: Array<JobSubFunction>;
  /** Get a job pipeline */
  getJobPipeline?: Maybe<JobPipeline>;
  /** Get job pipeline item */
  getJobPipelineItem?: Maybe<CandidatePipelineItem>;
  /** Get job pipeline stages */
  getJobPipelineStages?: Maybe<Array<CandidatePipelineStagePartial>>;
  /** Get all subscriptions for a single user */
  getUserSubscriptions?: Maybe<Array<UserSubscription>>;
  /** Check if current user can access the given features */
  hasAccess: Array<AccessGrant>;
  /** Get a list of companies for processing */
  internalCompanies: Array<Company>;
  /** Get all of the unique investors */
  investors: Array<Investor>;
  /** Get information about a specific job */
  job?: Maybe<Job>;
  /** Get the a specific job application by ID */
  jobApplication?: Maybe<JobApplication>;
  /** Get a conversation id by its job application id */
  jobApplicationConversationId?: Maybe<Scalars['ID']['output']>;
  /** Get information about a specific job function */
  jobFunction?: Maybe<JobFunction>;
  /** Get all of the unique job functions */
  jobFunctions: Array<JobFunction>;
  /** Get a job interview */
  jobInterview: JobInterview;
  /** Get a specific job offer */
  jobOffer?: Maybe<JobOffer>;
  /** Get information about a specific job sub function */
  jobSubFunction?: Maybe<JobSubFunction>;
  /** Get all of the unique job sub-functions */
  jobSubFunctions: Array<JobSubFunction>;
  /** Get all of the jobs */
  jobs: Array<Job>;
  /** Get all of the unique language requirements */
  languageRequirements: Array<JobLanguageRequirement>;
  /** Get the current user's latest job application for a given job */
  latestJobApplication?: Maybe<JobApplication>;
  /** get all job recs when user is logged out */
  loggedOutJobRecommendations?: Maybe<Array<JobRecommendation>>;
  /** Gets the currently logged in user */
  me?: Maybe<CurrentUser>;
  /** Get a conversation id by its message request id */
  messageRequestConversationId?: Maybe<Scalars['ID']['output']>;
  /** Get the recruiter's list of message templates */
  messageTemplates: Array<MessageTemplate>;
  /** Get next company that is ready for processing */
  nextCompanyToProcess?: Maybe<Company>;
  /** Get all of the jobs */
  nextJobToProcess?: Maybe<Job>;
  /** Get a the next user's profile to scrape */
  nextUserToScrape?: Maybe<User>;
  permissions: Array<PermissionObject>;
  /** Get a list of personalised companies */
  personalisedCompanies: Array<Company>;
  /** Get popular technologies used by jobs of certain sub functions */
  popularTechnologiesUsed: Array<JobTechnologyUsed>;
  potentialCandidates: Scalars['Int']['output'];
  /** Companies that could potentially be moved to Core */
  potentialCoreCompanies: Array<PotentialCoreCompany>;
  /** Get information about a specific job by external ID */
  publicJob?: Maybe<Job>;
  /**
   * Get a candidate's public job application details
   * @deprecated Use latest_job_application
   */
  publicJobApplication?: Maybe<PublicJobApplication>;
  /** Get a set of public statistics about the platform */
  publicStatistics: PublicStatistics;
  /** Get a list of all possible regions */
  regions: Array<Region>;
  /** Get a list of rocket list companies */
  rocketListEntries: Array<RocketListEntry>;
  /** Get the current user's salary preference */
  salaryPreference?: Maybe<SalaryPreference>;
  /** Get a review on a Salary Range for a job */
  salaryQualityReview: SalaryQualityRating;
  /** Get a set of salary statistics */
  salaryStatistics: SalaryStatistics;
  /** Get a list of scrape requests */
  scrapeRequests: Array<UserScrapeRequest>;
  /** Get all of the unique sector tags */
  sectorTags: Array<SectorTag>;
  /** Get a set of statistics about the platform */
  statistics: Statistics;
  /** Get a list of salaries submitted by users at most 6 months ago */
  submittedSalaries?: Maybe<Array<SalaryPreference>>;
  /** Get all of the unique technologies used */
  technologiesUsed: Array<JobTechnologyUsed>;
  /** Get a list of all of the available timezones */
  timezones: Array<Timezone>;
  /** Get all of the top investors */
  topInvestors: Array<Investor>;
  /** Get all of the top sector tags */
  topSectorTags: Array<TopSectorTag>;
  /** Get the total number of jobs */
  totalJobs?: Maybe<Scalars['Int']['output']>;
  /** Get a user by id */
  user?: Maybe<User>;
  /** Get information about a specific education experience */
  userEducationExperience?: Maybe<UserEducationExperience>;
  /** Get information about a specific education experience description */
  userEducationExperienceDescription?: Maybe<EducationExperienceDescription>;
  /** Get a list of the descriptions of a specific education experience */
  userEducationExperienceDescriptions: Array<EducationExperienceDescription>;
  /**
   * Get whether the user is subscribed to application notifications for a given job
   * @deprecated Use subscribed on Job
   */
  userJobNotificationUnsubscription: Scalars['Boolean']['output'];
  /** Fetch notification by page type */
  userNotification?: Maybe<Notification>;
  /** Get information about a specific project */
  userProject?: Maybe<UserProject>;
  /** Get information about a specific website link */
  userWebsiteLink?: Maybe<WebsiteLink>;
  /** Get information about a specific work experience */
  userWorkExperience?: Maybe<UserWorkExperience>;
  /** Get information about a specific work experience description */
  userWorkExperienceDescription?: Maybe<WorkExperienceDescription>;
  /** Get a list of the descriptions of a specific work experience */
  userWorkExperienceDescriptions: Array<WorkExperienceDescription>;
  /** Get a list of all users */
  users?: Maybe<Array<User>>;
  /** Validates an API key for an ATS */
  validateAtsApiKey: Response;
  /** Get a list of the top companies at which people work */
  workExperienceCompanies: Array<Scalars['String']['output']>;
  /** Get a work experience suggestion by work experience ID */
  workExperienceSuggestion?: Maybe<UserWorkExperienceSuggestion>;
};


export type RootQueryTypeCandidateArgs = {
  externalId: Scalars['ID']['input'];
  jobId?: InputMaybe<Scalars['ID']['input']>;
};


export type RootQueryTypeCandidateReceivedMessageRequestArgs = {
  jobId: Scalars['ID']['input'];
};


export type RootQueryTypeCandidateShortlistArgs = {
  jobId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeCompaniesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  previouslyScrapedJobs?: InputMaybe<Scalars['Boolean']['input']>;
  scraperType?: InputMaybe<ScraperType>;
  urlSafeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  workflowStatus?: InputMaybe<CompanyWorkflowStatus>;
  workflowStatuses?: InputMaybe<Array<CompanyWorkflowStatus>>;
};


export type RootQueryTypeCompaniesByExternalIdArgs = {
  externalIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type RootQueryTypeCompanyArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  urlSafeName?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeCompanyBoostArgs = {
  jobId: Scalars['ID']['input'];
};


export type RootQueryTypeCompanyByNameArgs = {
  name: Scalars['String']['input'];
};


export type RootQueryTypeCompanyConversationArgs = {
  id: Scalars['ID']['input'];
  newApplication: Scalars['Boolean']['input'];
};


export type RootQueryTypeCompanyEmployeeSurveyArgs = {
  externalId: Scalars['ID']['input'];
};


export type RootQueryTypeCompanyEmployeeSurveysPendingApprovalArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type RootQueryTypeCompanyJobArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeCompanyRecruiterConversationArgs = {
  candidateId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
};


export type RootQueryTypeCompanyRecruitersArgs = {
  workflowStatuses?: InputMaybe<Array<CompanyWorkflowStatus>>;
};


export type RootQueryTypeConversationArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeDiscardedCandidatesArgs = {
  jobId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeFindImportedCandidateArgs = {
  emailAddress: Scalars['String']['input'];
};


export type RootQueryTypeFunctionSubFunctionsArgs = {
  functionId?: InputMaybe<Scalars['ID']['input']>;
};


export type RootQueryTypeGetJobPipelineArgs = {
  jobId: Scalars['ID']['input'];
};


export type RootQueryTypeGetJobPipelineItemArgs = {
  candidateId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
};


export type RootQueryTypeGetJobPipelineStagesArgs = {
  candidateId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
};


export type RootQueryTypeHasAccessArgs = {
  features: Array<Feature>;
};


export type RootQueryTypeInternalCompaniesArgs = {
  workflowStatus: CompanyWorkflowStatus;
};


export type RootQueryTypeInvestorsArgs = {
  onlyTop?: InputMaybe<Scalars['Boolean']['input']>;
};


export type RootQueryTypeJobArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeJobApplicationArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeJobApplicationConversationIdArgs = {
  jobApplicationId: Scalars['ID']['input'];
};


export type RootQueryTypeJobFunctionArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeJobInterviewArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeJobOfferArgs = {
  jobId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type RootQueryTypeJobSubFunctionArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeJobSubFunctionsArgs = {
  functionId?: InputMaybe<Scalars['ID']['input']>;
  jobId?: InputMaybe<Scalars['ID']['input']>;
};


export type RootQueryTypeJobsArgs = {
  acquisitionChannel?: InputMaybe<Scalars['ID']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  isPro?: InputMaybe<Scalars['Boolean']['input']>;
  lastChangedByRoles?: InputMaybe<Array<RoleType>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locations?: InputMaybe<Array<Location>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  scrapedLocation?: InputMaybe<Scalars['String']['input']>;
  subFunctionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  workflowStatus?: InputMaybe<JobWorkflowStatus>;
};


export type RootQueryTypeLatestJobApplicationArgs = {
  jobId: Scalars['ID']['input'];
};


export type RootQueryTypeLoggedOutJobRecommendationsArgs = {
  preferredSectorId?: InputMaybe<Scalars['ID']['input']>;
  sectorTagIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  themeId?: InputMaybe<ThemeId>;
};


export type RootQueryTypeMessageRequestConversationIdArgs = {
  messageRequestId: Scalars['ID']['input'];
};


export type RootQueryTypeMessageTemplatesArgs = {
  messageTemplateType?: InputMaybe<MessageTemplateTypeEnum>;
};


export type RootQueryTypeNextCompanyToProcessArgs = {
  workflowStatus: CompanyWorkflowStatus;
};


export type RootQueryTypeNextJobToProcessArgs = {
  locations?: InputMaybe<Array<Location>>;
  subFunctionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  workflowStatus: JobWorkflowStatus;
};


export type RootQueryTypePermissionsArgs = {
  scopes?: InputMaybe<Array<PermissionScope>>;
};


export type RootQueryTypePersonalisedCompaniesArgs = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  companySizes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  investorPreferences?: InputMaybe<Array<InputMaybe<InvestorPreferenceInput>>>;
  limit: Scalars['Int']['input'];
  locationPreferences?: InputMaybe<Array<InputMaybe<Location>>>;
  offset: Scalars['Int']['input'];
  sectorTagPreferences?: InputMaybe<Array<InputMaybe<SectorTagPreferenceInput>>>;
};


export type RootQueryTypePopularTechnologiesUsedArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  subFunctions: Array<Scalars['ID']['input']>;
};


export type RootQueryTypePotentialCandidatesArgs = {
  languageIds: Array<Scalars['ID']['input']>;
  locationIds: Array<Location>;
  maximumExperience: Scalars['Int']['input'];
  minimumExperience: Scalars['Int']['input'];
  subFunctionId: Scalars['ID']['input'];
};


export type RootQueryTypePublicJobArgs = {
  externalId: Scalars['ID']['input'];
};


export type RootQueryTypePublicJobApplicationArgs = {
  externalId: Scalars['ID']['input'];
};


export type RootQueryTypeRocketListEntriesArgs = {
  companySizes?: InputMaybe<Array<Scalars['ID']['input']>>;
  hiringInLocations?: InputMaybe<Array<Location>>;
  location: Scalars['String']['input'];
  sectorCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  sectors?: InputMaybe<Array<Scalars['ID']['input']>>;
  year: Scalars['Int']['input'];
};


export type RootQueryTypeSalaryQualityReviewArgs = {
  jobId: Scalars['ID']['input'];
  max: Scalars['Int']['input'];
  min: Scalars['Int']['input'];
};


export type RootQueryTypeScrapeRequestsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeSubmittedSalariesArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type RootQueryTypeTopInvestorsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeTopSectorTagsArgs = {
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeTotalJobsArgs = {
  acquisitionChannel?: InputMaybe<Scalars['ID']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  isPro?: InputMaybe<Scalars['Boolean']['input']>;
  lastChangedByRoles?: InputMaybe<Array<RoleType>>;
  locations?: InputMaybe<Array<Location>>;
  scrapedLocation?: InputMaybe<Scalars['String']['input']>;
  subFunctionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  workflowStatus?: InputMaybe<JobWorkflowStatus>;
};


export type RootQueryTypeUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type RootQueryTypeUserEducationExperienceArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeUserEducationExperienceDescriptionArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeUserEducationExperienceDescriptionsArgs = {
  educationExperienceId: Scalars['ID']['input'];
};


export type RootQueryTypeUserJobNotificationUnsubscriptionArgs = {
  jobId: Scalars['ID']['input'];
};


export type RootQueryTypeUserNotificationArgs = {
  pageType: Scalars['String']['input'];
};


export type RootQueryTypeUserProjectArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeUserWebsiteLinkArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeUserWorkExperienceArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeUserWorkExperienceDescriptionArgs = {
  id: Scalars['ID']['input'];
};


export type RootQueryTypeUserWorkExperienceDescriptionsArgs = {
  workExperienceId: Scalars['ID']['input'];
};


export type RootQueryTypeUsersArgs = {
  activated?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  companyWorkflowStatus?: InputMaybe<Array<CompanyWorkflowStatus>>;
  email?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<RoleType>;
};


export type RootQueryTypeValidateAtsApiKeyArgs = {
  ats: CompanyAts;
  atsApiKey: Scalars['String']['input'];
};


export type RootQueryTypeWorkExperienceSuggestionArgs = {
  workExperienceId: Scalars['ID']['input'];
};

export type SalaryPreference = {
  __typename?: 'SalaryPreference';
  companyOnOtta?: Maybe<Scalars['Boolean']['output']>;
  currentCompany?: Maybe<Scalars['String']['output']>;
  currentExperienceLevel?: Maybe<ExperienceLevel>;
  currentFunction?: Maybe<JobFunction>;
  currentJobSalary?: Maybe<Money>;
  currentJobTitle?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use current_job_salary instead */
  currentSalary?: Maybe<Scalars['Int']['output']>;
  currentSubFunction?: Maybe<JobSubFunction>;
  email?: Maybe<Scalars['String']['output']>;
  experienceLevel?: Maybe<ExperienceLevel>;
  externalId: Scalars['ID']['output'];
  genders: Array<Gender>;
  hasAssociatedUser?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Location>;
};

export type SalaryPreferenceInput = {
  currentCompany?: InputMaybe<Scalars['String']['input']>;
  currentExperienceLevel?: InputMaybe<ExperienceLevel>;
  currentFunctionId?: InputMaybe<Scalars['ID']['input']>;
  currentJobSalary?: InputMaybe<MoneyInput>;
  currentJobTitle?: InputMaybe<Scalars['String']['input']>;
  currentSalary?: InputMaybe<Scalars['Int']['input']>;
  currentSubFunctionId?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  experienceLevel?: InputMaybe<ExperienceLevel>;
  genders?: InputMaybe<Array<Gender>>;
  location?: InputMaybe<Location>;
};

/** The options for salary quality rating from the Salary Wizard */
export enum SalaryQualityRating {
  Broad = 'BROAD',
  Excellent = 'EXCELLENT',
  Good = 'GOOD',
  Low = 'LOW',
  NotEnoughMatchingCandidates = 'NOT_ENOUGH_MATCHING_CANDIDATES'
}

export type SalaryStatistics = {
  __typename?: 'SalaryStatistics';
  /** Examples of jobs with salaries for this sub function and location */
  exampleJobs: Array<Job>;
  salaryTrajectory: SalaryTrajectoryData;
};


export type SalaryStatisticsExampleJobsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  location: Location;
  subFunctionId: Scalars['ID']['input'];
};


export type SalaryStatisticsSalaryTrajectoryArgs = {
  experienceLevels?: InputMaybe<Array<ExperienceLevel>>;
  location: Location;
  percentile: Scalars['Float']['input'];
  subFunctionId: Scalars['ID']['input'];
};

export type SalaryTrajectoryData = {
  __typename?: 'SalaryTrajectoryData';
  currency: Currency;
  datapoints: Array<SalaryTrajectoryDatapoint>;
  numRawDatapoints: Scalars['Int']['output'];
};

export type SalaryTrajectoryDatapoint = {
  __typename?: 'SalaryTrajectoryDatapoint';
  label: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export enum ScrapeRequestStatus {
  Completed = 'COMPLETED',
  Pending = 'PENDING'
}

/** The specific scraper type (normally a job board) */
export enum ScraperType {
  /** The Amazon scraper */
  Amazon = 'AMAZON',
  /** The Apple scraper */
  Apple = 'APPLE',
  /** The BreezyHR scraper */
  Breezy = 'BREEZY',
  /** The generic scraper designed to scrape companies who publish JobPosting schemas */
  GenericSchema = 'GENERIC_SCHEMA',
  /** The Greenhouse.io scraper */
  Greenhouse = 'GREENHOUSE',
  /** The iCIMS scraper */
  Icims = 'ICIMS',
  /** The Jobvite scraper */
  Jobvite = 'JOBVITE',
  /** The Lever.co scraper */
  Lever = 'LEVER',
  /** The Recruitee scraper */
  Recruitee = 'RECRUITEE',
  /** The SmartRecruiters scraper */
  SmartRecruiters = 'SMART_RECRUITERS',
  /** The Teamtailor scraper */
  Teamtailor = 'TEAMTAILOR',
  /** The TikTok scraper */
  Tiktok = 'TIKTOK',
  /** The Twitter scraper */
  Twitter = 'TWITTER',
  /** The Workable.com scraper */
  Workable = 'WORKABLE',
  /** The Workday scraper */
  Workday = 'WORKDAY'
}

export type SectorCategory = {
  __typename?: 'SectorCategory';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type SectorTag = {
  __typename?: 'SectorTag';
  category?: Maybe<SectorCategory>;
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type SectorTagInput = {
  id: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};

export type SectorTagPreference = {
  __typename?: 'SectorTagPreference';
  preference: Scalars['Boolean']['output'];
  sectorTagId: Scalars['ID']['output'];
};

export type SectorTagPreferenceInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  preference: Scalars['Boolean']['input'];
  sectorTagId?: InputMaybe<Scalars['ID']['input']>;
};

export type SelectAtsQuestion = {
  __typename?: 'SelectAtsQuestion';
  answers?: Maybe<Array<SelectAtsQuestionAnswer>>;
  default?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  localId: Scalars['ID']['output'];
  options: Array<SelectAtsQuestionOption>;
  required: Scalars['Boolean']['output'];
};

export type SelectAtsQuestionAnswer = {
  __typename?: 'SelectAtsQuestionAnswer';
  id: Scalars['ID']['output'];
  idValue: Scalars['ID']['output'];
  stringValue?: Maybe<Scalars['String']['output']>;
};

export type SelectAtsQuestionOption = {
  __typename?: 'SelectAtsQuestionOption';
  allowMultiple: Scalars['Boolean']['output'];
  freeText: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  localId: Scalars['ID']['output'];
};

export type Session = {
  __typename?: 'Session';
  token: Scalars['String']['output'];
};

/** The possible options for sexual orientation */
export enum SexualOrientation {
  Heterosexual = 'HETEROSEXUAL',
  Lgbqa = 'LGBQA',
  PreferNotToSay = 'PREFER_NOT_TO_SAY'
}

export type SignUpReasonResponse = {
  __typename?: 'SignUpReasonResponse';
  source: Scalars['String']['output'];
};

export type SignUpReasonResponseInput = {
  source: Scalars['String']['input'];
};

export type SingleChoiceResponse = {
  __typename?: 'SingleChoiceResponse';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SingleChoiceResponseInput = {
  label: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type SingleSelectAnswer = {
  __typename?: 'SingleSelectAnswer';
  choice: Choice;
  questionData: AnswerQuestionData;
};

export type SingleSelectAnswerInput = {
  choice: ChoiceInput;
  questionData: QuestionDataInput;
};

export type SingleSelectQuestion = {
  __typename?: 'SingleSelectQuestion';
  atsId?: Maybe<Scalars['String']['output']>;
  choices: Array<Choice>;
  question: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
};

export type SpotlightContent = {
  __typename?: 'SpotlightContent';
  company: PublicCompany;
  companyId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  link: CompanyBrandAssetLink;
  reason?: Maybe<SpotlightContentReason>;
  value?: Maybe<Scalars['String']['output']>;
};

export enum SpotlightContentReason {
  Function = 'FUNCTION',
  Quality = 'QUALITY'
}

/** The possible options for a pipeline stage type */
export enum StageType {
  Applied = 'APPLIED',
  Archived = 'ARCHIVED',
  Custom = 'CUSTOM',
  Hired = 'HIRED',
  OfferSent = 'OFFER_SENT',
  Sourced = 'SOURCED'
}

export type Statistics = {
  __typename?: 'Statistics';
  dailyAverageNumberJobsSaved: Scalars['Decimal']['output'];
  fourteenDayActiveUsers: Scalars['Int']['output'];
  liveJobsWithInternalApplications: Scalars['Int']['output'];
  monthlyActiveUsers: Scalars['Int']['output'];
  monthlyCandidatesApplying: Scalars['Int']['output'];
  monthlyNumberCandidatesReceivingMessageRequests: Scalars['Int']['output'];
  numberLiveCandidateProfiles: Scalars['Int']['output'];
  numberLiveCompanies: Scalars['Int']['output'];
  numberLiveJobs: Scalars['Int']['output'];
  numberUsers: Scalars['Int']['output'];
  percentageJobApplicationsRespondedTo: Scalars['Int']['output'];
  percentageMessageRequestsRespondedTo: Scalars['Int']['output'];
  percentageMessageRequestsViewed: Scalars['Int']['output'];
  percentageRetention: Scalars['Int']['output'];
  percentageSavedInFirstJobs: Scalars['Int']['output'];
  sevenDayActiveCompanies: Scalars['Int']['output'];
  sevenDayActiveUsers: Scalars['Int']['output'];
  sevenDayApplyConfirms: Scalars['Int']['output'];
  sevenDayCandidateReactions: Scalars['Int']['output'];
  sevenDayInternalApplications: Scalars['Int']['output'];
  sevenDayInterviews: Scalars['Int']['output'];
  sevenDayMessageRequestsAccepted: Scalars['Int']['output'];
  sevenDayMessageRequestsSent: Scalars['Int']['output'];
  sevenDayNewUsers: Scalars['Int']['output'];
  sevenDayOffersAccepted: Scalars['Int']['output'];
  sevenDayOffersReceived: Scalars['Int']['output'];
  sevenDaySavedJobs: Scalars['Int']['output'];
  totalApplyClicks: Scalars['Int']['output'];
  totalApplyConfirms: Scalars['Int']['output'];
  totalApplyOpens: Scalars['Int']['output'];
  totalUsersWithLinkedinUrl: Scalars['Int']['output'];
  weekNewUsers: Scalars['Int']['output'];
  weekSavedJobs: Scalars['Int']['output'];
};


export type StatisticsDailyAverageNumberJobsSavedArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsFourteenDayActiveUsersArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsMonthlyActiveUsersArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsMonthlyCandidatesApplyingArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsMonthlyNumberCandidatesReceivingMessageRequestsArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsNumberLiveCandidateProfilesArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsNumberUsersArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsPercentageJobApplicationsRespondedToArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsPercentageMessageRequestsRespondedToArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsPercentageMessageRequestsViewedArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsPercentageRetentionArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsPercentageSavedInFirstJobsArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsSevenDayActiveCompaniesArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsSevenDayActiveUsersArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsSevenDayApplyConfirmsArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsSevenDayCandidateReactionsArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsSevenDayInternalApplicationsArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsSevenDayInterviewsArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsSevenDayMessageRequestsAcceptedArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsSevenDayMessageRequestsSentArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsSevenDayNewUsersArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsSevenDayOffersAcceptedArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsSevenDayOffersReceivedArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsSevenDaySavedJobsArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsTotalApplyClicksArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsTotalApplyConfirmsArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsTotalApplyOpensArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsTotalUsersWithLinkedinUrlArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsWeekNewUsersArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};


export type StatisticsWeekSavedJobsArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};

export type StringListValue = {
  __typename?: 'StringListValue';
  values: Array<Scalars['String']['output']>;
};

export type StringResponse = {
  __typename?: 'StringResponse';
  value: Scalars['String']['output'];
};

export type StringValue = {
  __typename?: 'StringValue';
  value: Scalars['String']['output'];
};

export type SubmittedSalaryInput = {
  approved?: InputMaybe<Scalars['Boolean']['input']>;
  currentExperienceLevel?: InputMaybe<ExperienceLevel>;
  currentSubFunctionId?: InputMaybe<Scalars['ID']['input']>;
};

export type SubscriptionChannel = {
  __typename?: 'SubscriptionChannel';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  value: Scalars['Boolean']['output'];
};

export type SubsetStatInput = {
  percent: Scalars['Decimal']['input'];
  total: Scalars['Int']['input'];
  value: Scalars['String']['input'];
};

export enum TeamMemberRole {
  Manager = 'MANAGER',
  Report = 'REPORT'
}

/** The possible options for technology preferences */
export enum TechnologyPreference {
  /** Must not have */
  Negative = 'NEGATIVE',
  /** Nice to have */
  Positive = 'POSITIVE',
  /** Great to have */
  VeryPositive = 'VERY_POSITIVE'
}

export type TechnologyUsedPreference = {
  __typename?: 'TechnologyUsedPreference';
  technologyPreference: TechnologyPreference;
  technologyUsed: JobTechnologyUsed;
  technologyUsedId: Scalars['ID']['output'];
};

export type TechnologyUsedPreferenceInput = {
  technologyPreference: TechnologyPreference;
  technologyUsedId: Scalars['ID']['input'];
};

export type TextAnswer = {
  __typename?: 'TextAnswer';
  questionData: AnswerQuestionData;
  value: Scalars['String']['output'];
};

export type TextAnswerInput = {
  questionData: QuestionDataInput;
  value: Scalars['String']['input'];
};

export type TextAreaAnswer = {
  __typename?: 'TextAreaAnswer';
  questionData: AnswerQuestionData;
  value: Scalars['String']['output'];
};

export type TextAreaAnswerInput = {
  questionData: QuestionDataInput;
  value: Scalars['String']['input'];
};

export type TextAreaQuestion = {
  __typename?: 'TextAreaQuestion';
  atsId?: Maybe<Scalars['String']['output']>;
  question: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
};

export type TextAtsQuestion = {
  __typename?: 'TextAtsQuestion';
  answer?: Maybe<Scalars['String']['output']>;
  default?: Maybe<Scalars['String']['output']>;
  format: AtsTextFormat;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  localId: Scalars['ID']['output'];
  maximumAnswers: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
};

export type TextQuestion = {
  __typename?: 'TextQuestion';
  atsId?: Maybe<Scalars['String']['output']>;
  question: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
};

export enum ThemeId {
  ApplyViaOtta = 'APPLY_VIA_OTTA',
  FavouriteTechnologies = 'FAVOURITE_TECHNOLOGIES',
  FemaleFounders = 'FEMALE_FOUNDERS',
  FullyRemote = 'FULLY_REMOTE',
  HasSalaries = 'HAS_SALARIES',
  NewlyAdded = 'NEWLY_ADDED',
  PreferredSector = 'PREFERRED_SECTOR',
  RecentlyFunded = 'RECENTLY_FUNDED',
  TakeAnotherLook = 'TAKE_ANOTHER_LOOK',
  TechForGood = 'TECH_FOR_GOOD'
}

export type Timezone = {
  __typename?: 'Timezone';
  id: Scalars['ID']['output'];
  location: Scalars['String']['output'];
  utcOffset: Scalars['Int']['output'];
};

export type TopSectorTag = {
  __typename?: 'TopSectorTag';
  category?: Maybe<SectorCategory>;
  id: Scalars['ID']['output'];
  numberCompanies: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type UrlAnswer = {
  __typename?: 'UrlAnswer';
  questionData: AnswerQuestionData;
  value: Scalars['String']['output'];
};

export type UrlAnswerInput = {
  questionData: QuestionDataInput;
  value: Scalars['String']['input'];
};

export type UrlObject = {
  __typename?: 'UrlObject';
  url: Scalars['String']['output'];
};

export type UrlQuestion = {
  __typename?: 'UrlQuestion';
  atsId?: Maybe<Scalars['String']['output']>;
  question: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
};

export type UrlResponse = {
  __typename?: 'UrlResponse';
  url: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  activated: Scalars['Boolean']['output'];
  companyQualitiesPreferences: Array<CompanyQualitiesPreference>;
  companySizePreferences: Array<CompanySizePreference>;
  companySubQualitiesPreferences: Array<CompanySubQualitiesPreference>;
  contextQuestionResponses: Array<CandidateContextQuestionResponse>;
  currentCompany?: Maybe<Company>;
  currentTitle?: Maybe<Scalars['String']['output']>;
  discardedJobs: Array<Job>;
  discardedSubFunctions: Array<JobSubFunction>;
  educationExperiences: Array<UserEducationExperience>;
  email: Scalars['String']['output'];
  ethnicities: Array<Ethnicity>;
  externalId: Scalars['ID']['output'];
  firstName: Scalars['String']['output'];
  followedCompanies: Array<FollowedCompany>;
  genders: Array<Gender>;
  hasAccess: Array<AccessGrant>;
  hasConfirmedLocation?: Maybe<Scalars['Boolean']['output']>;
  hasSetPreferences?: Maybe<Scalars['Boolean']['output']>;
  hasSubmittedSalary: Scalars['Boolean']['output'];
  hiddenCompanies: Array<HiddenCompany>;
  id: Scalars['ID']['output'];
  insertedAt: Scalars['NaiveDateTime']['output'];
  jobApplications: Array<JobApplication>;
  jobEmailNotificationsFrequency: NotificationFrequency;
  jobExperiencePreference?: Maybe<JobExperiencePreference>;
  /** @deprecated Renamed to job_experience_preference */
  jobExperiencePreferences?: Maybe<UserJobExperiencePreferences>;
  jobFunctionPreferences: Array<JobFunctionPreference>;
  /** @deprecated Unused - see job reaction statistics */
  jobInteractionStatistics: JobInteractionStatistics;
  jobNotificationUnsubscription: Scalars['Boolean']['output'];
  jobPreferenceSuggestions?: Maybe<Array<JobPreferenceSuggestion>>;
  jobReactionStatistics: JobReactionStatistics;
  jobRecommendations: Array<JobRecommendation>;
  jobSubFunctionPreferences: Array<JobSubFunctionPreference>;
  jobsProcessed?: Maybe<Scalars['Int']['output']>;
  languageRequirementPreferences: Array<LanguageRequirementPreference>;
  lastName: Scalars['String']['output'];
  lastUnconfirmedJobApplication?: Maybe<Scalars['String']['output']>;
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  locationPreferences: Array<LocationPreference>;
  marketingConsent: Scalars['Boolean']['output'];
  /** @deprecated Use min_salary_requirement instead */
  minSalaryPreference?: Maybe<Scalars['Int']['output']>;
  minSalaryRequirement?: Maybe<Money>;
  numReferrals?: Maybe<Scalars['Int']['output']>;
  numUnreadShortlistedJobs: Scalars['Int']['output'];
  permissions: Array<PermissionObject>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profileCompletion: Array<ProfileCompletionComponent>;
  profileImagePath?: Maybe<Scalars['String']['output']>;
  profileInterests?: Maybe<Scalars['String']['output']>;
  projects: Array<UserProject>;
  pronoun?: Maybe<Pronoun>;
  receivedMessageRequests: Array<CandidateMessageRequest>;
  region?: Maybe<Scalars['String']['output']>;
  retained: Scalars['Boolean']['output'];
  role: RoleType;
  searchStage?: Maybe<Scalars['String']['output']>;
  sectorTagPreferences: Array<SectorTagPreference>;
  shortlistedJobs: Array<Job>;
  showProfileToCompanies: Scalars['Boolean']['output'];
  showRequestAdvice?: Maybe<Scalars['Boolean']['output']>;
  signUpReasonResponses: Array<SignUpReasonResponse>;
  /** @deprecated Use region instead */
  signupRegion?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use subregion instead */
  signupSubregion?: Maybe<Scalars['String']['output']>;
  spotlightContent?: Maybe<SpotlightContent>;
  subregion?: Maybe<Scalars['String']['output']>;
  technologiesUsedPreferences: Array<TechnologyUsedPreference>;
  termsAndConditionsConsentedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  timezone?: Maybe<Timezone>;
  totalDiscardedJobs?: Maybe<Scalars['Int']['output']>;
  totalJobApplicationClicks: Scalars['Int']['output'];
  totalJobApplicationConfirms: Scalars['Int']['output'];
  totalJobReactions: Scalars['Int']['output'];
  totalRemainingJobs: Scalars['Int']['output'];
  verifiedEmail: Scalars['Boolean']['output'];
  visaRequirementCountries: Array<VisaRequirementCountry>;
  websiteLinks: Array<WebsiteLink>;
  workExperiences: Array<UserWorkExperience>;
};


export type UserDiscardedJobsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserHasAccessArgs = {
  features: Array<Feature>;
};


export type UserJobApplicationsArgs = {
  applied?: InputMaybe<Scalars['Boolean']['input']>;
  appliedAtGt?: InputMaybe<Scalars['DateTime']['input']>;
  appliedAtLt?: InputMaybe<Scalars['DateTime']['input']>;
  externalOrSentToAts?: InputMaybe<Scalars['Boolean']['input']>;
  externalResponded?: InputMaybe<Array<ExternalResponded>>;
  internal?: InputMaybe<Scalars['Boolean']['input']>;
  orderByLeastResponseRateDatapoints?: InputMaybe<Scalars['Boolean']['input']>;
  stillApplied?: InputMaybe<Scalars['Boolean']['input']>;
};


export type UserJobInteractionStatisticsArgs = {
  since: Scalars['NaiveDateTime']['input'];
};


export type UserJobRecommendationsArgs = {
  ignoreSubfunctions?: InputMaybe<Scalars['Boolean']['input']>;
  preferredSectorId?: InputMaybe<Scalars['ID']['input']>;
  themeId?: InputMaybe<ThemeId>;
};


export type UserJobsProcessedArgs = {
  since: Scalars['DateTime']['input'];
};


export type UserProfileImagePathArgs = {
  prefix?: InputMaybe<Scalars['Boolean']['input']>;
};


export type UserSpotlightContentArgs = {
  companyIds: Array<Scalars['String']['input']>;
};


export type UserTotalRemainingJobsArgs = {
  ignoreSubfunctions?: InputMaybe<Scalars['Boolean']['input']>;
  sectorTagIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UserEducationExperience = {
  __typename?: 'UserEducationExperience';
  description?: Maybe<Scalars['String']['output']>;
  descriptions: Array<EducationExperienceDescription>;
  educationType?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  fieldOfStudy?: Maybe<Scalars['String']['output']>;
  grade?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  institutionLinkedinUrl?: Maybe<Scalars['String']['output']>;
  institutionName: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
};

export type UserEducationExperienceInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  descriptions?: InputMaybe<Array<EducationExperienceDescriptionInput>>;
  educationType?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  fieldOfStudy?: InputMaybe<Scalars['String']['input']>;
  grade?: InputMaybe<Scalars['String']['input']>;
  institutionLinkedinUrl?: InputMaybe<Scalars['String']['input']>;
  institutionName: Scalars['String']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

/** The fields a user is allowed to update about themselves */
export type UserInput = {
  companyQualitiesPreferences?: InputMaybe<Array<CompanyQualitiesPreferenceInput>>;
  companySizePreferences?: InputMaybe<Array<CompanySizePreferenceInput>>;
  currentCompanyId?: InputMaybe<Scalars['ID']['input']>;
  currentTitle?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  ethnicities?: InputMaybe<Array<EthnicityInput>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  followedCompanies?: InputMaybe<Array<FollowedCompanyInput>>;
  genders?: InputMaybe<Array<Gender>>;
  hiddenCompanies?: InputMaybe<Array<HiddenCompanyInput>>;
  jobEmailNotificationsFrequency?: InputMaybe<NotificationFrequency>;
  jobExperiencePreference?: InputMaybe<JobExperiencePreferenceInput>;
  jobFunctionPreferences?: InputMaybe<Array<JobFunctionPreferenceInput>>;
  jobSubFunctionPreferences?: InputMaybe<Array<JobSubFunctionPreferenceInput>>;
  languageRequirementPreferences?: InputMaybe<Array<LanguageRequirementPreferenceInput>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  locationPreferences?: InputMaybe<Array<LocationPreferenceInput>>;
  minSalaryPreference?: InputMaybe<Scalars['Int']['input']>;
  minSalaryRequirement?: InputMaybe<MoneyInput>;
  numUnreadShortlistedJobs?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  profileInterests?: InputMaybe<Scalars['String']['input']>;
  pronoun?: InputMaybe<Pronoun>;
  region?: InputMaybe<Scalars['String']['input']>;
  searchStage?: InputMaybe<Scalars['String']['input']>;
  sectorTagPreferences?: InputMaybe<Array<SectorTagPreferenceInput>>;
  sexualOrientation?: InputMaybe<SexualOrientation>;
  showProfileToCompanies?: InputMaybe<Scalars['Boolean']['input']>;
  signUpReasonResponses?: InputMaybe<Array<SignUpReasonResponseInput>>;
  subregion?: InputMaybe<Scalars['String']['input']>;
  technologiesUsedPreferences?: InputMaybe<Array<TechnologyUsedPreferenceInput>>;
  timezoneId?: InputMaybe<Scalars['ID']['input']>;
  visaRequirementCountries?: InputMaybe<Array<VisaRequirementCountryInput>>;
  websiteLinks?: InputMaybe<Array<WebsiteLinkInput>>;
};

/** The fields a user is allowed to update about themselves via email */
export type UserInputViaEmail = {
  showProfileToCompanies?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserJobExperiencePreferences = {
  __typename?: 'UserJobExperiencePreferences';
  entryLevel: Scalars['Boolean']['output'];
  fiveToEightYears: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  internship: Scalars['Boolean']['output'];
  ninePlusYears: Scalars['Boolean']['output'];
  oneToTwoYears: Scalars['Boolean']['output'];
  showManagementRoles: Scalars['Boolean']['output'];
  threeToFourYears: Scalars['Boolean']['output'];
};

export enum UserJobStatus {
  ApplicationStarted = 'APPLICATION_STARTED',
  Applied = 'APPLIED',
  Interviewing = 'INTERVIEWING',
  NotSaved = 'NOT_SAVED',
  OfferAccepted = 'OFFER_ACCEPTED',
  OfferReceived = 'OFFER_RECEIVED',
  Saved = 'SAVED'
}

export type UserJobStatusInfo = {
  __typename?: 'UserJobStatusInfo';
  status: UserJobStatus;
  subStatus?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserNotification = {
  __typename?: 'UserNotification';
  dismissedAt?: Maybe<Scalars['NaiveDateTime']['output']>;
  id: Scalars['ID']['output'];
  notification: Notification;
};

export type UserProfileView = {
  __typename?: 'UserProfileView';
  externalId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  sessionLength: Scalars['Int']['output'];
};

export type UserProject = {
  __typename?: 'UserProject';
  description: Scalars['String']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  imagePath?: Maybe<Scalars['String']['output']>;
  projectUrl?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
  technologiesUsed: Array<JobTechnologyUsed>;
  title: Scalars['String']['output'];
};


export type UserProjectImagePathArgs = {
  prefix?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserProjectInput = {
  description: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  projectUrl?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  technologiesUsed?: InputMaybe<Array<ProjectTechnologyUsedInput>>;
  title: Scalars['String']['input'];
};

export type UserScrapeRequest = {
  __typename?: 'UserScrapeRequest';
  id: Scalars['ID']['output'];
  insertedAt: Scalars['NaiveDateTime']['output'];
  scrapedItems: Array<UserScrapeRequestScrapedItem>;
  status: ScrapeRequestStatus;
  url: Scalars['String']['output'];
};

export type UserScrapeRequestScrapedItem = {
  __typename?: 'UserScrapeRequestScrapedItem';
  data: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  insertedAt: Scalars['NaiveDateTime']['output'];
  url: Scalars['String']['output'];
};

export type UserSubscription = {
  __typename?: 'UserSubscription';
  channels?: Maybe<Array<SubscriptionChannel>>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type UserWorkExperience = {
  __typename?: 'UserWorkExperience';
  companyDescription?: Maybe<Scalars['String']['output']>;
  companyLogoPath?: Maybe<Scalars['String']['output']>;
  companyName: Scalars['String']['output'];
  companyOnOtta: Scalars['Boolean']['output'];
  companyUrl?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptions: Array<WorkExperienceDescription>;
  endDate?: Maybe<Scalars['Date']['output']>;
  hasProcessingDescriptions: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  location?: Maybe<Scalars['String']['output']>;
  sectorTags: Array<SectorTag>;
  startDate: Scalars['Date']['output'];
  technologiesUsed: Array<JobTechnologyUsed>;
  title: Scalars['String']['output'];
};

export type UserWorkExperienceInput = {
  companyDescription?: InputMaybe<Scalars['String']['input']>;
  companyLogoPath?: InputMaybe<Scalars['String']['input']>;
  companyName: Scalars['String']['input'];
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  descriptions?: InputMaybe<Array<WorkExperienceDescriptionInput>>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  sectorTags?: InputMaybe<Array<WorkExperienceSectorTagInput>>;
  startDate: Scalars['Date']['input'];
  technologiesUsed?: InputMaybe<Array<WorkExperienceTechnologyUsedInput>>;
  title: Scalars['String']['input'];
};

export type UserWorkExperienceSuggestion = {
  __typename?: 'UserWorkExperienceSuggestion';
  id: Scalars['ID']['output'];
  status?: Maybe<WorkExperienceSuggestionStatus>;
  value: Array<Scalars['String']['output']>;
};

export enum VisaRegion {
  Ca = 'CA',
  Eu = 'EU',
  Uk = 'UK',
  Us = 'US'
}

export type VisaRequirementCountry = {
  __typename?: 'VisaRequirementCountry';
  id: Scalars['ID']['output'];
  location: Scalars['String']['output'];
};

export type VisaRequirementCountryInput = {
  location: Scalars['String']['input'];
};

export type VisaSponsorshipCountry = {
  __typename?: 'VisaSponsorshipCountry';
  id: Scalars['ID']['output'];
  /** @deprecated Use region_id instead */
  location: Scalars['String']['output'];
  offersVisa: Scalars['Boolean']['output'];
  region: Region;
};

export type VisaSponsorshipCountryInput = {
  location?: InputMaybe<Scalars['String']['input']>;
  offersVisa: Scalars['Boolean']['input'];
  regionId: Scalars['ID']['input'];
};

export type WebsiteLink = {
  __typename?: 'WebsiteLink';
  id: Scalars['ID']['output'];
  linkType?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type WebsiteLinkInput = {
  linkType?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type WorkExperienceDescription = {
  __typename?: 'WorkExperienceDescription';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type WorkExperienceDescriptionInput = {
  value: Scalars['String']['input'];
};

export type WorkExperienceSectorTagInput = {
  id: Scalars['ID']['input'];
};

export enum WorkExperienceSuggestionStatus {
  /** The candidate has accepted the suggestion */
  Accepted = 'ACCEPTED',
  /** The candidate has not yet taken action on the suggestion */
  Active = 'ACTIVE',
  /** The candidate has ignored the suggestion */
  Dismissed = 'DISMISSED',
  /** The suggestion is pending processing by GPT */
  Pending = 'PENDING'
}

export type WorkExperienceTechnologyUsedInput = {
  id: Scalars['ID']['input'];
};

export type WorkableAnswer = BooleanAnswer | DateAnswer | DecimalAnswer | FileAnswer | MultiSelectAnswer | SingleSelectAnswer | TextAnswer | TextAreaAnswer;

export type WorkableAnswerData = {
  __typename?: 'WorkableAnswerData';
  answers: Array<WorkableAnswer>;
};

export type WorkableAnswerInput = {
  booleanAnswer?: InputMaybe<BooleanAnswerInput>;
  dateAnswer?: InputMaybe<DateAnswerInput>;
  decimalAnswer?: InputMaybe<DecimalAnswerInput>;
  existingFileAnswer?: InputMaybe<ExistingFileAnswerInput>;
  fileAnswer?: InputMaybe<NewFileAnswerInput>;
  multiSelectAnswer?: InputMaybe<MultiSelectAnswerInput>;
  singleSelectAnswer?: InputMaybe<SingleSelectAnswerInput>;
  textAnswer?: InputMaybe<TextAnswerInput>;
  textAreaAnswer?: InputMaybe<TextAreaAnswerInput>;
};

export type WorkableApplicationQuestion = BooleanQuestion | DateQuestion | DecimalQuestion | FileQuestion | MultiSelectQuestion | SingleSelectQuestion | TextAreaQuestion | TextQuestion;

export type WorkableDataInput = {
  answers: Array<WorkableAnswerInput>;
};

export type WorkableQuestionData = {
  __typename?: 'WorkableQuestionData';
  questions: Array<WorkableApplicationQuestion>;
};

export type CurrentUserExperimentsQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserExperimentsQuery = { __typename?: 'RootQueryType', me?: { __typename?: 'CurrentAdmin', id: string, currentCompany?: { __typename?: 'Company', id: string, scraperUrl?: string | null } | null } | { __typename?: 'CurrentCandidate', id: string } | { __typename?: 'CurrentCompanyRecruiter', id: string, currentCompany?: { __typename?: 'Company', id: string, scraperUrl?: string | null } | null } | { __typename?: 'CurrentExternalResearcher', id: string } | { __typename?: 'CurrentInternalOperator', id: string } | null };

export type AllFlagsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllFlagsQuery = { __typename?: 'RootQueryType', featureFlags: Array<{ __typename?: 'FeatureFlag', name: string, variants: Array<string> }> };


export const CurrentUserExperimentsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CurrentUserExperiments"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CurrentAdmin"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currentCompany"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"scraperUrl"}}]}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"CurrentCompanyRecruiter"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currentCompany"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"scraperUrl"}}]}}]}}]}}]}}]} as unknown as DocumentNode<CurrentUserExperimentsQuery, CurrentUserExperimentsQueryVariables>;
export const AllFlagsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AllFlags"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"featureFlags"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"variants"}}]}}]}}]} as unknown as DocumentNode<AllFlagsQuery, AllFlagsQueryVariables>;