import { x } from "@xstyled/styled-components";

import {
  UpsellSourcingCTA,
  UpsellSourcingStatistics,
} from "../JobPage/Analytics/SourcingStatistics";

import { palette } from "@otta/design-tokens";

export const SourcingUpsell = () => {
  return (
    <x.div h="100%" background={palette.brand.white}>
      <x.div
        display="flex"
        flexDirection="column"
        gap="xl"
        padding="xl"
        maxW={1400}
      >
        <UpsellSourcingCTA displayTitle />
        <UpsellSourcingStatistics />
      </x.div>
    </x.div>
  );
};
