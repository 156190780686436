import styled, { css, up, x } from "@xstyled/styled-components";
import { useMemo, useState } from "react";

import { Statistic } from "./components/CardStatistics";
import { SourcingPerformance } from "./types";
import { BarGraph } from "./components/BarGraph";
import { MessageOutcome } from "./components/MessageOutcome";
import { FilterToggle, UpsellFilterToggle } from "./components/FilterToggle";
import { FilterOptions } from "./components/graph-helpers";
import { StackedBarGraph } from "./components/StackedBarGraph";
import { MissingDataPlaceholder } from "./components/MissingDataPlaceholder";
import { SourcingNudge } from "./components/SourcingNudge";

import { Button, Card, Spacing, Text } from "@otta/design";
import { palette } from "@otta/design-tokens";
import { JobSourcingPerformanceSubsetStatistics } from "@hire/schema";
import { ANALYTICS_DESKTOP_BREAKPOINT } from "@hire/components/layout/constants";
import { RectangleTag } from "@hire/components/tags/RectangleTag";
import { Icon } from "@otta/icons";

const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Container = styled.div`
  display: grid;
  column-gap: 40;
  row-gap: lg;
  grid-template-columns: repeat(3, minmax(0, 1fr));
`;

const CTA = styled(Card)`
  border: 1px solid;
  border-color: gray-200;
  padding: xl;
  width: 100%;

  ${up(
    "tablet",
    css`
      padding: md;
    `
  )}
`;

const BarGraphWrapper = styled.div`
  grid-column: span 3;

  @media (min-width: ${ANALYTICS_DESKTOP_BREAKPOINT}px) {
    grid-column: span 2;
  }
`;

const DonutGraphWrapper = styled.div`
  display: grid;
  grid-column: span 3;

  @media (min-width: ${ANALYTICS_DESKTOP_BREAKPOINT}px) {
    grid-column: span 1;
  }
`;

const UPSELL_GRAPH_DETAILS = [
  {
    title: "Recruiter viewed",
    value: 100,
  },
  {
    title: "Recruiter shortlisted",
    value: 50,
  },
  {
    title: "Recruiter messaged",
    value: 30,
  },
];

const UPSELL_MESSAGE_OUTCOME = [
  {
    value: 100,
    percent: "33",
    color: palette.brand.grey,
    name: "Accepted",
  },
  {
    value: 100,
    percent: "33",
    color: palette.brand.grey,
    name: "Declined",
  },
  {
    value: 100,
    percent: "33",
    color: palette.brand.grey,
    name: "Unanswered",
  },
];

export function UpsellSourcingStatistics() {
  return (
    <Spacing size={2}>
      <TitleSection>
        <x.div display="flex" gap="sm" alignItems="center">
          <Text bold size={2}>
            Sourcing performance
          </Text>
          <RectangleTag
            icon={"locked"}
            color={palette.brand.yellow}
            iconColor={palette.brand.black}
            textColor={palette.brand.black}
            content={"Upgrade"}
          />
        </x.div>
        <UpsellFilterToggle />
      </TitleSection>

      <Container>
        <Statistic
          title="Recruiter viewed"
          conversion={"33"}
          isProCompany={false}
        />
        <Statistic
          title="Recruiter shortlisted"
          conversion={"33"}
          isProCompany={false}
        />
        <Statistic
          title="Recruiter messaged"
          conversion={"33"}
          isProCompany={false}
          hasChevron={false}
        />

        <BarGraphWrapper>
          <BarGraph
            bars={UPSELL_GRAPH_DETAILS}
            graphTitle="Number of candidates"
            isProCompany={false}
          />
        </BarGraphWrapper>
        <DonutGraphWrapper>
          <MessageOutcome data={UPSELL_MESSAGE_OUTCOME} hasSourcing={false} />
        </DonutGraphWrapper>
      </Container>
    </Spacing>
  );
}

export function UpsellSourcingCTA({
  displayTitle = false,
}: {
  displayTitle?: boolean;
}) {
  return (
    <x.div display="flex" flexDirection="column" gap="xl">
      {displayTitle && (
        <TitleSection>
          <x.div display="flex" gap="sm" alignItems="center">
            <Text bold size={2}>
              Sourcing
            </Text>
            <RectangleTag
              icon={"locked"}
              color={palette.brand.yellow}
              iconColor={palette.brand.black}
              textColor={palette.brand.black}
              content={"Upgrade"}
            />
          </x.div>
        </TitleSection>
      )}
      <CTA>
        <x.div display="flex" gap="xl" alignItems="flex-start">
          <x.img
            display={{ _: "none", tablet: "block" }}
            w={399}
            maxW="33%"
            src="https://static.otta.com/hire/sourcing_upsell.webp"
          />
          <x.div
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start"
            gap="lg"
          >
            <x.div display="flex" flexDirection="column" gap="lg">
              <Text size={1} bold>
                Unlock sourcing & additional insights
              </Text>
              <x.ul
                display="flex"
                flexDirection="column"
                gap="lg"
                listStyleType="none"
              >
                <x.li display="flex" alignItems="center" gap="sm">
                  <Icon icon="checkmark" />
                  <Text>
                    Message top candidates who haven’t applied yet using unique
                    insights into what they’re looking for
                  </Text>
                </x.li>
                <x.li display="flex" alignItems="center" gap="sm">
                  <Icon icon="checkmark" />
                  <Text>
                    Easily filter by gender, ethnicity, and seniority for more
                    informed hiring decisions
                  </Text>
                </x.li>
              </x.ul>
            </x.div>
            <Button
              as="a"
              level="primary"
              href="https://meetings.hubspot.com/yoyin/qualification-45-mins-under-150-employees?utm_source=hire_app&utm_medium=individual_job&utm_campaign=upsell&utm_content=recruiter_access_book_demo_cta"
              target="_blank"
              rel="noopener noreferrer"
              data-analytics-id="sourcing-upsell-upgrade-plan"
            >
              Add sourcing to your plan
            </Button>
          </x.div>
        </x.div>
      </CTA>
    </x.div>
  );
}

export function SourcingStatistics({
  data,
  relevantCandidates,
  hasSourcing,
}: {
  data?: SourcingPerformance | null;
  relevantCandidates: number | null;
  hasSourcing: boolean;
}) {
  const [currentFilter, setCurrentFilter] = useState<FilterOptions>("all");

  const graphDetails = useMemo(() => {
    if (!data) return [];

    return [
      {
        title: "Recruiter viewed",
        value: data.totals.viewed.total,
      },
      {
        title: "Recruiter shortlisted",
        value: data.totals.shortlisted.total,
      },
      {
        title: "Recruiter messaged",
        value: data.totals.messaged.total,
      },
    ];
  }, [data]);

  const filteredGraphDetails = useMemo(() => {
    const filteredData =
      currentFilter === "all"
        ? null
        : (data?.[currentFilter] as JobSourcingPerformanceSubsetStatistics);

    return [
      {
        title: "Recruiter viewed",
        values: filteredData?.viewed ?? [],
      },
      {
        title: "Recruiter shortlisted",
        values: filteredData?.shortlisted ?? [],
      },
      {
        title: "Recruiter messaged",
        values: filteredData?.messaged ?? [],
      },
    ];
  }, [data, currentFilter]);

  const messageOutcome = useMemo(() => {
    if (!data) return [];

    return [
      {
        value: data.messageOutcomes.accepted.total,
        percent: data.messageOutcomes.accepted.percent,
        color: palette.brand.green,
        name: "Accepted",
      },
      {
        value: data.messageOutcomes.declined.total,
        percent: data.messageOutcomes.declined.percent,
        color: palette.extended.red.shade500,
        name: "Declined",
      },
      {
        value: data.messageOutcomes.unanswered.total,
        percent: data.messageOutcomes.unanswered.percent,
        color: palette.grayscale.shade400,
        name: "Unanswered",
      },
    ];
  }, [data]);

  const [hoveredItem, setHoveredItem] = useState<string>();

  const hasData = data && data.totals.viewed.total > 0;
  const hasRelevantCandidates = !!relevantCandidates && relevantCandidates > 0;

  if (!hasSourcing)
    return (
      <x.div display="flex" flexDirection="column" gap="xl">
        <UpsellSourcingStatistics />
        <UpsellSourcingCTA />
      </x.div>
    );

  return (
    <Spacing size={2}>
      <TitleSection>
        <Text bold size={2}>
          Sourcing performance
        </Text>
        {hasData && (
          <FilterToggle
            currentFilter={currentFilter}
            section="sourcing-performance"
            changeFilter={filter => setCurrentFilter(filter)}
          />
        )}
      </TitleSection>
      {hasData && (
        <Container>
          <Statistic
            title="Recruiter viewed"
            value={data.totals.viewed.total}
            filterData={filteredGraphDetails[0].values}
            hoveredItem={hoveredItem}
            setHoveredItem={setHoveredItem}
          />
          <Statistic
            title="Recruiter shortlisted"
            value={data.totals.shortlisted.total}
            filterData={filteredGraphDetails[1].values}
            conversion={data.totals.shortlisted.conversion ?? undefined}
            hoveredItem={hoveredItem}
            setHoveredItem={setHoveredItem}
          />
          <Statistic
            title="Recruiter messaged"
            value={data.totals.messaged.total}
            filterData={filteredGraphDetails[2].values}
            conversion={data.totals.messaged.conversion ?? undefined}
            hoveredItem={hoveredItem}
            setHoveredItem={setHoveredItem}
            hasChevron={false}
          />

          <BarGraphWrapper>
            {currentFilter === "all" && (
              <BarGraph bars={graphDetails} graphTitle="Number of candidates" />
            )}

            {currentFilter !== "all" && (
              <StackedBarGraph
                bars={filteredGraphDetails}
                graphTitle="Number of candidates"
                hoveredItem={hoveredItem}
                setHoveredItem={setHoveredItem}
              />
            )}
          </BarGraphWrapper>
          <DonutGraphWrapper>
            <MessageOutcome data={messageOutcome} hasSourcing={hasSourcing} />
          </DonutGraphWrapper>
        </Container>
      )}
      {!hasData && hasRelevantCandidates && (
        <SourcingNudge relevantCandidates={relevantCandidates} />
      )}
      {!hasData && !hasRelevantCandidates && <MissingDataPlaceholder />}
    </Spacing>
  );
}
